import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIdleModule } from '@ng-idle/core';
import { TraceService } from '@sentry/angular-ivy';

import { BrightsideUiModule, NgxMaskModule, ngxMaskOptions, MessageService } from '@brightside/brightside-ui';
import {
  DesktopDataAccessSharedModule,
  FirebaseAnalyticsService,
  FirebaseService,
  AmplitudeService,
  BrightsideTranslateService,
  CompanyService,
  StatusNotificationsService,
} from '@brightside-web/desktop/data-access/shared';
import { BsErrorHandler, SharedDesktopModule } from '@brightside-web/shared/desktop';
import { DesktopDataAccessEmailVerifyModule } from '@brightside-web/desktop/data-access/email-verify';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { MicroMessageBusModule, MessageBusIncomingService } from '@brightside-web/micro/core/message-bus';
import { Environment } from '@brightside-web/micro/core/environment';

import {API, Auth, Cache, Hub} from 'aws-amplify';
import { IntercomModule } from 'ng-intercom';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { VerifyComponent } from './verify/verify.component';
import { env, environment } from '../environments/environment';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { WaitlistService } from './waitlist.service';
import { RoutingService } from './routing.service';
import { SharedMobileModule } from '@brightside-web/shared/mobile';

import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";

export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http);
  // return new TranslateHttpLoader(
  //   http,
  //   `${env.cdnUrl}assets/i18n/`,
  //   '.json'
  // );
}

@NgModule({
  declarations: [AppComponent, VerifyComponent, WaitlistComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmplifyAngularModule,
    BrightsideUiModule,
    NgIdleModule.forRoot(),
    DesktopDataAccessSharedModule,
    DesktopDataAccessEmailVerifyModule,
    IntercomModule.forRoot({
      appId: env.intercomAppId,
    }),
    SharedDesktopModule,
    ReactiveFormsModule,
    BrightsideUiServicesModule,
    NgxMaskModule.forRoot(ngxMaskOptions),
    SharedMobileModule,
    MicroMessageBusModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AmplitudeService,
    { provide: Environment, useValue: environment },
    {
      provide: AmplifyService,
      useFactory: () =>
        AmplifyModules({
          Auth,
          Hub,
          API,
        }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: ErrorHandler, useClass: BsErrorHandler },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    { provide: TranslateService, useClass: BrightsideTranslateService },
    CurrencyPipe,
    TitleCasePipe,
    MessageService,
    WaitlistService,
    RoutingService,
    CompanyService,
    StatusNotificationsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private messageBusIncomingService: MessageBusIncomingService,
    private _ngZone: NgZone,
    private _traceService: TraceService
  ) {}

  /**
   *
   * @param rawNativeMessage: string - Example: 'auth <tokenId>'
   */
  publishMessage(rawNativeMessage: string): void {
    this._ngZone.run(() => this.messageBusIncomingService.handleIncoming(rawNativeMessage));
  }
}
