import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiResponse } from './api-contracts';
import { API, Cache } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';
import * as moment from "moment";
import { CompanyService } from "@brightside-web/desktop/data-access/shared";

export enum NotificationMessageTypeEnum {
  CHANGE_EMAIL = "account-change-password-notify"
}

export enum NotificationMessageChannelEnum {
  EMAIL = 'email'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http:HttpClient, private companyService:CompanyService) {  }

  sendMessage(messageType: NotificationMessageTypeEnum, channel: NotificationMessageChannelEnum) : void {
    API.post('api-mobile', '/notification', {body:{
     messageType,
     channel
    }});
  }

  generateDynamicLink(route:string,cardName:string):Observable<ApiResponse>{
    return from(API.post('api-mobile','/notification',{body:{
      messageType:'desktopCardToMobile',
        channel:'sms',
        dynamicLink:route,
        cardName
      }}));
  }

  sendGetTheAppMessage(phone_number:string, user_agent:string):Observable<ApiResponse>{
    if ( !Cache.getItem('referrer') ) {
      Cache.setItem('referrer', this.companyService.getCompany(), { expires: moment().add(6000, 'seconds').valueOf() });
    }
    if ( !Cache.getItem('referrerType') ) {
      Cache.setItem('referrerType', 'desktop', { expires: moment().add(6000, 'seconds').valueOf() });
    }

    return this.http.get<{ip:string}>('https://api.ipify.org/?format=json').pipe(
      concatMap((res)=> from(API.post('api-mobile-noauth','/landingpage',{body:{
          "referrer":Cache.getItem('referrer'),
          "referrerType":Cache.getItem('referrerType'),
          "company":'app',
          phone_number,
          user_agent,
          "ip_address":res.ip}})))
    )
  }
}

