import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { EligibleProduct, MobileStateService } from '@brightside-web/desktop/data-access/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoanEligibleGuard implements CanActivate, CanLoad {
  constructor(private mobileStateService: MobileStateService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkEligibility();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkEligibility();
  }

  checkEligibility(): Observable<boolean> {
    return this.mobileStateService.get().pipe(map(response => {
      if ((response.products || []).includes(EligibleProduct.loan)){
        return true;
      }
      this.router.navigate(['/home']);
      return false;
    }))
  }
}
