import { CACHE_KEY, DisplayMessageComponent } from '@brightside-web/shared/desktop';
import { AccountAddComponent } from '@brightside-web/shared/account';
import {
  DisplayAutosaveComponent,
  DisplayAutosavePieces,
  DisplayAutosaveProcessArgument,
  DisplayAutosaveProcessResponse,
  DisplayAutosaveTemplateKey,
  DisplayAutosaveFrequencyCopy,
  DisplayAutosavePayFrequency,
} from '@brightside-web/shared/autosave';
import { SharedConsentsComponent, ConsentManager, ConsentType } from '@brightside-web/shared/consents';
import { DisplayLoaderComponent } from '@brightside-web/shared/desktop';
import {
  SavingsScheduleCreateRequest,
  SavingsScheduleType,
  SavingsSource,
  SavingsSchedulePatchAction,
} from '@brightside-web/desktop/data-access/shared';
import { InitiatingSource } from '@brightside-web/desktop/data-access/savings';

import { Observable } from 'rxjs';
import { Cache } from 'aws-amplify';

import * as moment from 'moment';

import { DeepSavingsLinkedAccountGuard } from '../guard/deep-savings/deep-savings-linking.guard';
import { DeepSavingsScheduleGuard } from '../guard/deep-savings/deep-savings-schedule.guard';

const BREADCRUMB_HOME = { label: 'Home', routeInterceptor: () => '/home' };
const BREADCRUMB_LABEL = 'Savings';

const FB_CATEGORY = 'smartwallet';
const FB_PAGE = 'one-click-';

const ROUTES_PATH = 'home/deep/savings';

const PATH_CONFIG_INTRO = {
  fbPage: `${FB_PAGE}intro`,
  fbCategory: FB_CATEGORY,

  pageTitle: 'Get ready to save',
  pageSubTitle: `Your autosavings will come out of ${DisplayAutosaveTemplateKey.CADENCE} starting on ${DisplayAutosaveTemplateKey.DATE}.`,
  pageCtaLabel: 'Confirm amount',

  pagePieces: [DisplayAutosavePieces.SELECTOR, DisplayAutosavePieces.PROJECTIONS],
  pageOnForwardCtaPath: [ROUTES_PATH, 'after', 'no-account'],
  pageOnBackCtaPath: ['home'],

  templateValues: {
    [DisplayAutosaveTemplateKey.CADENCE]: (autosaveComponent: DisplayAutosaveComponent) =>
      DisplayAutosaveFrequencyCopy[autosaveComponent.config.cadence || DisplayAutosavePayFrequency.EVERY] ||
      DisplayAutosaveFrequencyCopy[DisplayAutosavePayFrequency.EVERY],
    [DisplayAutosaveTemplateKey.DATE]: (autosaveComponent: DisplayAutosaveComponent) =>
      !autosaveComponent.nextTransferDate
        ? ' the next valid paycycle'
        : moment(autosaveComponent.nextTransferDate).format('MMM Do YYYY'),
  },

  processUpdater: (autosaveComponent: DisplayAutosaveComponent, details: DisplayAutosaveProcessArgument) => {
    const observable = new Observable<DisplayAutosaveProcessResponse>((subscriber) => {
      const returnSuccess = () => {
        subscriber.next({
          status: 'success',
        });
        subscriber.complete();
      };
      const returnFailure = () => {
        subscriber.next({
          status: 'failure',
        });
        subscriber.complete();
      };

      //Clear cached values
      Cache.removeItem(CACHE_KEY.deepSavingsDetails);
      Cache.removeItem(CACHE_KEY.deepSavingsSource);

      //If we don't have an account, flow to the defaulted path
      if (!autosaveComponent.account) {
        Cache.setItem(CACHE_KEY.deepSavingsDetails, details);
        Cache.setItem(CACHE_KEY.deepSavingsSource, InitiatingSource.ONE_CLICK);

        returnSuccess();
        return;
      }

      const requestBody: SavingsScheduleCreateRequest = {
        schedule_type: details.schedule_type as SavingsScheduleType,
        next_transfer_date: details.next_transfer_date,
        amount: details.amount,
        source: autosaveComponent.account.source || SavingsSource.payroll,
      };

      //If we have a linked account already we can skip straight to savings home
      autosaveComponent.config.pageOnForwardCtaPath = autosaveComponent.hasTransferToSource
        ? ['home', 'savings']
        : [ROUTES_PATH, 'after', 'with-account'];

      //Since we know we already have an account, we need to create/update the autosave schedule before moving forward
      if (autosaveComponent.hasCurrentAutosaveSchedule) {
        autosaveComponent.savingsService
          .updateSchedule({ ...requestBody, action: SavingsSchedulePatchAction.UPDATE })
          .subscribe(returnSuccess, returnFailure);
        return;
      }

      autosaveComponent.savingsService.createSchedule(requestBody).subscribe(returnSuccess, returnFailure);
    });

    return observable;
  },
};

const PATH_CONFIG_INTRO_EDIT = {
  ...PATH_CONFIG_INTRO,
  fbPage: `${FB_PAGE}intro-intro`,
  pageTitle: 'Savings update',
  pageSubTitle: `Your new autosavings amount will come out of ${DisplayAutosaveTemplateKey.CADENCE} starting on ${DisplayAutosaveTemplateKey.DATE}.`,
};

const REDIRECT_ROUTES = [
  {
    path: 'create',
    redirectTo: '/home/savings/creation',
  },
  {
    path: 'after',
    children: [
      {
        path: 'no-account',
        redirectTo: `/${ROUTES_PATH}/consents`,
      },
      {
        path: 'with-account',
        redirectTo: `/${ROUTES_PATH}/link-account`,
        canLoad: [DeepSavingsLinkedAccountGuard],
      },
      {
        path: 'create',
        redirectTo: `/${ROUTES_PATH}/link-account`,
        canLoad: [DeepSavingsLinkedAccountGuard],
      },
    ],
  },
  {
    path: 'complete',
    redirectTo: `/${ROUTES_PATH}/link-account`,
    canLoad: [DeepSavingsLinkedAccountGuard],
  },
];
const ROUTES = [
  ...REDIRECT_ROUTES,
  {
    path: '',
    canActivate: [],
    component: DisplayMessageComponent,
    data: {
      breadcrumb: BREADCRUMB_HOME,
    },
  },
  {
    path: 'loader',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: BREADCRUMB_LABEL,
      pageConfig: {
        fbPage: `${FB_PAGE}loader`,
        fbCategory: FB_CATEGORY,
        pageOnForwardCtaPath: [ROUTES_PATH, 'intro'],
      },
    },
  },
  {
    path: 'intro',
    children: [
      {
        path: '',
        canActivate: [DeepSavingsScheduleGuard],
        component: DisplayAutosaveComponent,
        data: {
          breadcrumb: BREADCRUMB_LABEL,
          pageConfig: PATH_CONFIG_INTRO,
        },
      },
      {
        path: 'edit',
        component: DisplayAutosaveComponent,
        data: {
          breadcrumb: { skip: true },
          pageConfig: PATH_CONFIG_INTRO_EDIT,
        },
      },
    ],
  },
  {
    path: 'consents',
    canActivate: [],
    component: SharedConsentsComponent,
    data: {
      breadcrumb: BREADCRUMB_LABEL,
      pageConfig: {
        fbPage: `${FB_PAGE}consents`,
        fbCategory: FB_CATEGORY,

        pageTitle: 'Deduction consents',
        pageSubTitle: new ConsentManager().instructionalText,
        pageOnForwardCtaPath: [ROUTES_PATH, 'create'],

        consentList: [
          ConsentManager.ConsentDetails[ConsentType.CustodialAccount],
          ConsentManager.ConsentDetails[ConsentType.PayrollSavings],
          ConsentManager.ConsentDetails[ConsentType.Withdrawal],
        ],
      },
    },
  },
  {
    path: 'link-account',
    component: AccountAddComponent,
    canActivate: [],
    data: {
      breadcrumb: BREADCRUMB_LABEL,
      fbPage: `${FB_PAGE}account-linking`,
      fbCategory: FB_CATEGORY,

      pageHideBackButton: true,
      pageOnForwardCtaPath: ['home', 'savings'],
      pageSecondaryCtaLabel: 'Set up later',
    },
  },
];

export { BREADCRUMB_LABEL, ROUTES_PATH, ROUTES };
