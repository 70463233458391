<input
  #cb
  type="checkbox"
  [checked]="checked"
  [disabled]="disabled"
  [value]="value"
  [name]="name"
  [readOnly]="readonly"
  (change)="handleChange($event)"
  (focus)="onFocus()"
  (blur)="onBlur()"
/>

<div class="checkbox-wrapper flex-row" [ngClass]="{'mobile-space-center': centerAlign, 'disabled': disabled}">
  <span #placeholderLabel class="faux-checkbox" [id]="name+value" (click)="onClick($event, cb, true)" role="checkbox">
    <img *ngIf="checked" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD1SURBVHgB7ZRtFYIwFIbfcQigDYggDTSIoA00gZBAGwgJjIARbCANXALnZTCVw8eAob94zhnb3WXPDgMuMFEDe4884VN0otEMw0hhIcCZxVW5L+50dWAGR8zmKrC+Eg7MKT21hR9iKk/pYFeyH1meiyN2pX47plyJU2zEAgKXPnKObuIliRM0fL5VOUNMzUX9OX7E2X/RIm6SJ3Jx9UXdSmKGCBqq8ictWotdsYErpQPE9fJ89kiSA8m4lOZiLuc6ijPsxgyjOuEJkDSUcS4O0AO7Nas2UOOe2No7BkgVf6stHOak9XKGPRoKkAZRtAc5QkzoeAHrIk2PxBJn9wAAAABJRU5ErkJggg==" alt="checked checkbox">
    <img *ngIf="!checked" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgB7ZTBDYAgDEVr4wCMIGEwZ3AD3MAVHIzACEwAQoIGezFgTDz0JRBKm//bSwGYB4Y6sNbOIYQtPQX04RBRSyn38wPrbBLXL8QzU2nwYqQF+VJKDdCBMSYCaRDhY9iADdiADf5gQLepT0eUrdiLq4PbBDHGhRY04pPGCkwLB50PIr6oYSc4AAAAAElFTkSuQmCC" alt="unchecked checkbox">
  </span>
  <label class="body" (click)="onClick($event, cb, true)" *ngIf="label" [attr.for]="name+value">
    {{ label }}
  </label>
  <label [attr.for]="name+value" *ngIf="!label" class="body" (click)="onClick($event, cb, true)"><ng-content></ng-content></label>
</div>
