import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { MicroContestAppService, MicroContestsAppResponse } from '../../service/micro-contest-app.service';

type ReturnResolveType = MicroContestsAppResponse;

@Injectable()
export class MicroContestsResolver implements Resolve<ReturnResolveType> {
  constructor(private microContestAppService: MicroContestAppService) {}

  resolve() {
    return this.microContestAppService.getAll({});
  }
}
