import { Routes } from '@angular/router';

import { UiTemplateFlyerComponent } from '@micro-ui/template/page';

import TemplateData_MoreInfo from './more-info/promo-more-info.template.data';
import { MicroContestPromoMoreResolver } from './more-info/promo-more-info.template.resolver';

import { MicroContestRouteDataKey, MicroContestRouteKey } from '../../model/contest.enum';

export const BaseRoutes: Routes = [
  {
    path: MicroContestRouteKey.MORE_INFO,
    component: UiTemplateFlyerComponent,
    resolve: {
      [MicroContestRouteDataKey.TEMPLATE]: MicroContestPromoMoreResolver,
    },
    data: {
      [MicroContestRouteDataKey.TEMPLATE]: TemplateData_MoreInfo,
    },
  },
  {
    path: 'contest-legal',
    loadChildren: () => import('@micro-app/contest').then((m) => m.MicroContestLegalModule),
  },
  {
    path: 'redirect',
    children: [
      {
        path: 'rules',
        redirectTo: '/contest/intro/1/rules',
      },
    ],
  },
];
