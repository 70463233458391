import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { UiTemplateContentType } from '@micro-ui/template/content';
import { UiTemplateFlyerInterface, UiTemplateStateKey } from '@micro-ui/template/page';

import { MicroContestRouteDataKey, MicroContestTranslationKey } from '../../../model/contest.enum';

@Injectable()
export class MicroContestPromoMoreResolver implements Resolve<UiTemplateFlyerInterface> {
  constructor() {}

  private insertContent(templateData: UiTemplateFlyerInterface): UiTemplateFlyerInterface {
    templateData.inputs.content = [
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: 'lightbulb',
          title: MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_SUB_TITLE,
        },
      },
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: 'check_badge',
          title: MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_SUB_TITLE,
        },
      },
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: 'document',
          title: MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_SUB_TITLE,
        },
      },
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: 'calendar',
          title: MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_SUB_TITLE,
        },
      },
    ];

    return templateData;
  }

  private insertState(templateData: UiTemplateFlyerInterface, autosaving: boolean = false): UiTemplateFlyerInterface {
    templateData.state = {
      initial: {
        [UiTemplateStateKey.FORM]: {
          dirty: true,
          lastUpdate: '',
          touched: true,
          valid: true,
          values: { autosaving },
        },
      },
    };

    return templateData;
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const templateData: UiTemplateFlyerInterface = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
    const processedTemplateData = this.insertState(
      this.insertContent(templateData),
      routeSnapshot.queryParams['autosaving'] === 'true'
    );
    const returnTemplateData: UiTemplateFlyerInterface = {
      ...processedTemplateData,
    };

    if (processedTemplateData.state?.initial?.state_form?.values['autosaving']) {
      returnTemplateData.inputs.cta1Label = "Got it";
    }

    return returnTemplateData;
  }
}
