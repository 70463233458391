import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MicroRouteFindDataByKey, MicroUtilTranslationService } from '@micro-core/utility';
import { UiTemplateFlyerInterface } from '@micro-ui/template/page';

import {
  MicroContestAppResponse,
  MicroContestAppResultsResponse,
  MicroContestResult,
} from '../../service/micro-contest-app.service';

import { MicroContestRouteDataKey, MicroContestTranslationKey } from '../../model/contest.enum';

import TemplateData_ResultWon from '../result/won/result-won.template.data';
import TemplateData_ResultLost from '../result/lost/result-lost.template.data';

type DynamicTranslation = Partial<Record<MicroContestTranslationKey, string>>;

@Injectable()
export class MicroContestResultsRevealResolver implements Resolve<UiTemplateFlyerInterface> {
  private _contestDetails: MicroContestAppResponse | undefined;
  private _contestResults: MicroContestAppResultsResponse | undefined;

  private _templateDataMapping: Record<string, UiTemplateFlyerInterface> = {
    won: TemplateData_ResultWon,
    lost: TemplateData_ResultLost,
  };

  constructor(private currencyPipe: CurrencyPipe, private utilTranslationService: MicroUtilTranslationService) {}

  private reset() {
    this._contestDetails = undefined;
    this._contestResults = undefined;
  }

  private getDynamicTranslations(): DynamicTranslation {
    const messageValues = {
      prizeAmount: `${this.currencyPipe.transform(
        this._contestDetails?.data?.messageValues.prizeAmount || '',
        'USD',
        'symbol',
        '1.0'
      )}`,
      numberOfWinners: this._contestDetails?.data?.messageValues.numberOfWinners,
    };

    return {
      [MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE]: this.utilTranslationService.instant(
        MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE,
        messageValues
      ),
    };
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    /**
     * Setting _contestDetails/_contestResults first before anything else so we can pull
     * back the right templateData.
     */
    this._contestDetails = MicroRouteFindDataByKey<MicroContestAppResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_DETAIL
    );
    this._contestResults = MicroRouteFindDataByKey<MicroContestAppResultsResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_RESULTS
    );

    const templateData: UiTemplateFlyerInterface =
      this._templateDataMapping[this._contestResults?.data?.result || MicroContestResult.LOST] || {};
    const dynamicTranslatedValues = this.getDynamicTranslations();
    const returnTemplateData: UiTemplateFlyerInterface = {
      ...templateData,
      inputs: {
        ...this.utilTranslationService.deepCopyKeySwapForDynamicValue(templateData.inputs, dynamicTranslatedValues),
      },
    };

    this.reset();

    return returnTemplateData;
  }
}
