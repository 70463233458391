import { CurrencyPipe } from '@angular/common';

import { take } from 'rxjs/operators';

import { SavingsPayrollScheduleResponse, SavingsScheduleCreateRequest } from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService, SavingsPayrollService } from '@brightside-web/desktop/data-access/savings';
import { ToastService } from '@brightside/brightside-ui-services';

import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType, UiTemplateContentGroupCell } from '@micro-ui/template/content';
import {
  GetUiTemplateActionRoute,
  UiTemplateAction,
  UiTemplateActionUpdateStateFunctionType,
  UiTemplateFormFlyerDataBuilder,
  UiTemplateNavType,
  UiTemplateState,
} from '@micro-ui/template/page';

import {
  MicroContestAnalyticsEventName,
  MicroContestFormControlKey,
  MicroContestRouteModalKey,
  MicroContestTranslationKey,
} from '../../../model/contest.enum';
import { MicroContestRemoteConfig } from '../../../model/contest.interface';

export const AUTOSAVE_CONFIRM_MODAL_ID = 'confirmation';

const TemplateData = UiTemplateFormFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.NONE,
    header: {
      type: UiHeaderType.DEFAULT,
      title: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE,
      icon: '',
    },
    content: [
      {
        type: UiTemplateContentType.GROUP_CELL,
        data: {
          groupName: 'contest.autosave.confirm',
          options: [
            {
              label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE,
              value: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE,
            },
            {
              label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE,
              value: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE,
              valueResolver: ({ currencyPipe, dailySavingsAmount, scheduleSavingsAmount }) => {
                if (!currencyPipe || !dailySavingsAmount || !scheduleSavingsAmount) {
                  console.warn('Value Resolver was called but missing arguments it needs... please check');
                  return '';
                }

                return `${(currencyPipe as CurrencyPipe).transform(scheduleSavingsAmount as string, 'USD', 'symbol', '1.0')}`;
              },
            },
            {
              label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE,
              value: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE,
              valueResolver: ({ payrollSchedule }) =>
                new Date((payrollSchedule as SavingsPayrollScheduleResponse).schedule[0]).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }),
            },
          ],
        } as UiTemplateContentGroupCell,
      },
    ],
    infoText: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO,
    cta1Label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1,
    cta2Label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2,
    viewName: MicroContestAnalyticsEventName.ENTRY_AUTOSAVE_CONFIRM,
  },
  actions: {
    [UiTemplateAction.cta1]: (state?: UiTemplateState) => {
      const appRef = (window as any).app;
      const savingsAccountService: SavingsAccountService = appRef.injector.get(SavingsAccountService);

      if (!savingsAccountService) {
        //ToDo: need to throw error...
        console.warn('Dev you do not have a ref to SavingsAccountService');
        return;
      }

      if (state?.state_child_modal) {
        const createScheduleRequestBody = state.state_child_modal['createScheduleRequestBody'] as SavingsScheduleCreateRequest;

        //We don't need to block the action here because this is outside angular components
        savingsAccountService
          .createSchedule(createScheduleRequestBody)
          .pipe(take(1))
          .subscribe(
            () => console.log('Schedule did update'),
            () => {
              appRef.injector.get(ToastService).error('We could not update your Autosave schedule', {
                link: false,
                transient: true,
              });
            }
          );
      }

      return { route: ['../', 'complete'], options: { queryParams: { modal: null }, queryParamsHandling: 'merge' } };
    },
    [UiTemplateAction.cta2]: (state?: UiTemplateState, updateState?: UiTemplateActionUpdateStateFunctionType) => {
      const fromCustom =
        state?.state_child_modal &&
        state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM] &&
        Number(state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM]) > 0;

      if (state?.state_child_modal) {
        state.state_child_modal = undefined;

        if (updateState) {
          updateState(state);
        }
      }

      return fromCustom
        ? GetUiTemplateActionRoute.MODAL_OPEN(MicroContestRouteModalKey.AUTOSAVE_CUSTOM)
        : GetUiTemplateActionRoute.MODAL_CLOSE();
    },
  },
});

export default TemplateData;
