<bw-billboard-article>
  <div class="media-small-center" >
    <typography display class="mb-smd blue-80">
      {{ heading | translate }}
    </typography>
    <p text-medium-regular class="gray-50 mb-lg" [innerHTML]="bodyCopy | translate:{customerNameFormatted:company}"></p>
    <div class="media-small-hidden mb-lg">
      <typography title-medium>
        {{ iconGroupTitle | translate }}
      </typography>
    </div>
  </div>
  <div class="flex-row media-small-hidden mb-md" style="flex-flow: row wrap;">
    <div class="mr-md">
      <div class="flex-row mb-sm">
        <img src="../assets/money-doc.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_ONE' | translate"></p>
      </div>
      <div class="flex-row mb-sm">
        <img src="../assets/cash.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_TWO' | translate"></p>
      </div>
      <div class="flex-row mb-sm">
        <img src="../assets/life-preserver.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_THREE' | translate"></p>
      </div>
    </div>
    <div>
      <div class="flex-row mb-sm">
        <img src="../assets/credit-card.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_FOUR' | translate"></p>
      </div>
      <div class="flex-row mb-sm">
        <img src="../assets/dash-guage.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_FIVE' | translate"></p>
      </div>
      <div class="flex-row mb-sm">
        <img src="../assets/write-check.svg" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_SIX' | translate"></p>
      </div>
    </div>
  </div>
</bw-billboard-article>
