import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'bw-list-item-details',
  templateUrl: './list-item-details.component.html',
  styleUrls: ['./list-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemDetailsComponent implements OnInit {
  /** If the component has an avatar */
  hasAvatar: boolean;

  /** If the component has a title */
  hasTitle: boolean;
  
  /** If the component has detail text */
  hasDetail: boolean;

  /** If the component has an icon on the right */
  hasRightIcon: boolean;

  /** The name of the icon that will appear in the avatar. If a name is not provided, hasAvatar will be falsy and the avatar component will not show. */
  @Input() avatarIconName: string;

  /** The color of the icon in the avatar */
  @Input() avatarIconColor = 'var(--blue-70)';

  /** The title line of text. This line will wrap and expand the height of the component. If none is given, hasTitle will be falsy and the title component will not show. */
  @Input() listTitle: string;

  /** The detail line of text. This line will wrap and expand the height of the component. If a detail is not provided, hasDetail will be falsy and the detail element will not show. */
  @Input() listDetail: string;

  /** The right icon name. If a name is not provided, hasRightIcon will be falsy and the icon element will not show. */
  @Input() rightIconName: string;

  /** The right icon color. Will be ignored if a rightIconName is not provided. */
  @Input() rightIconColor: string;

  /** If the component should present as a block (with extra padding and background color). */
  @Input() blockStyle: boolean;

  /** The background color for the block style */
  @Input() bgColor: string;

  constructor() {
    this.hasAvatar = true;
    this.hasTitle = true;
    this.hasDetail = true;
    this.hasRightIcon = true;
    this.rightIconColor = 'black'
    this.blockStyle = false;
  }

  ngOnInit(): void {
    if (!this.avatarIconName) {
      this.hasAvatar = false;
    }
    if (!this.listTitle) {
      this.hasTitle = false;
    }
    if (!this.listDetail) {
      this.hasDetail = false;
    }
    if (!this.rightIconName) {
      this.hasRightIcon = false;
    }
  }

}
