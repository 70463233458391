import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BrightsideUiModule } from '@brightside/brightside-ui';

import { MicroCoreUtilityModule } from '@micro-core/utility';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';

import { UiContentDisplayComponent } from './components/display/display-content.component';
import { UiContentDisplayFormComponent } from './components/display-form/display-form-content.component';

import { UiTemplateContentCdnUrlComponent } from './components/cdn-url/cdn-url-content.component';
import { UiTemplateContentTitleTextComponent } from './components/title-text/title-text-content.component';

import { UiTemplateContentSelectAmountComponent } from './components/select/amount/select-amount-content.component';
import { UiTemplateContentGroupRadioComponent } from './components/group/radio/group-radio-content.component';

const ContentComponents = [
  UiContentDisplayComponent,
  UiContentDisplayFormComponent,
  UiTemplateContentCdnUrlComponent,
  UiTemplateContentGroupRadioComponent,
  UiTemplateContentSelectAmountComponent,
  UiTemplateContentTitleTextComponent,
];

@NgModule({
  imports: [BrightsideUiModule, CommonModule, MicroCoreUtilityModule, MicroUiMoleculeModule, TranslateModule],
  exports: [...ContentComponents],
  declarations: [...ContentComponents],
})
export class MicroUiTemplateContentModule {}
