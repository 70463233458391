<ui-page-transition [reverseExitAnimation]="shouldReverseExitAnimation">
  <bw-action-article page-template minHeight="0" [brightsideWebFb]="{ event_name: fbEventName + '_shown' }">
    <bw-back
      *ngIf="!pageHideBackButton"
      header-actions
      [brightsideWebFb]="{ event_name: fbEventName + '_dismissed', action: 'click' }"
      [emit]="true"
      (back)="handleBackCtaClick()"
    ></bw-back>

    <div pre-title>
      <bw-steps
        *ngIf="pageStepperActiveIndex >= 0"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>
    <div content-section>
      <p class="secondary-text mb-md">{{ pageSubTitle }}</p>
      <form [formGroup]="routingForm" autocomplete="off">
        <div class="form-grid">
          <div class="mb-xs">
            <!--TODO need a responsive select dropdown -->
            <label for="formAccountType" class="text-small-semibold mb-xs">Account type</label>
            <ngx-select-dropdown
              formControlName="accountType"
              [options]="accountTypes"
              [config]="selectConfig"
            ></ngx-select-dropdown>
          </div>
          <bw-input label="Routing number" formControlName="routingNumber"></bw-input>

          <bw-input label="Account number" formControlName="accountNumber"></bw-input>

          <bw-input label="Confirm account number" formControlName="accountNumberConfirm"></bw-input>
        </div>
      </form>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="pageCtaLabel"
        bw-button
        type="submit"
        [disabled]="routingForm.invalid || isCtaDisabled"
        [brightsideWebFb]="{ event_name: fbEventName + '_tapped', action: 'click' }"
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ pageCtaLabel }}
      </button>

      <button
        *ngIf="pageSecondaryCtaLabel"
        [brightsideWebFb]="{ event_name: fbEventName }"
        [secondaryCta]="true"
        bw-link-button
        class="bs-blue brand pr-xl"
        (click)="handleSecondaryCtaClick()"
      >
        {{ pageSecondaryCtaLabel }}
      </button>
    </div>
  </bw-action-article>
</ui-page-transition>

<bw-modal *ngIf="showMicroWarning" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">{{ microTitle }}</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--gray-90)">
        To finish linking your external account to Brightside, you’ll need to verify the account so that you can withdraw your funds.

        <ul style="margin: var(--bs-space-24) 0; padding-inline-start: var(--bs-space-24)">
          <li>We’ve deposited two small amounts in your external account</li>
          <li>{{ microLineWithDate }}</li>
          <li>Enter these amounts in the Brightside app to verify your account</li>
        </ul>

        If you do not see the deposits within 3 business days, please contact your Financial Assistant.
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        *ngIf="microPrimaryCta"
        bw-button
        [processing]="processing"
        (click)="handleMicroCtaClick()"
        [brightsideWebFb]="{ event_name: fbEventName + '_tapped', action: 'click' }"
      >
        {{ microPrimaryCta }}
      </button>
    </div>
  </footer>
</bw-modal>
