import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentComponent } from './consent/consent.component';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsentModalComponent } from './consent-modal/consent-modal.component';
import { SharedConsentsComponent } from './consents/consents.component';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        BrightsideUiModule,
        BrightsideUiServicesModule,
        FormsModule,
        SharedDesktopModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
  declarations: [ ConsentComponent, SharedConsentsComponent, ConsentModalComponent ],
  exports: [ ConsentComponent, SharedConsentsComponent, ConsentModalComponent ]

})
export class SharedConsentsModule {}
