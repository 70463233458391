import {Injectable, OnDestroy} from '@angular/core';
import {Cache, Hub} from "aws-amplify";
import {
  FirebaseService,
  GlobalModalInterface,
  MobileStateResponse,
  NotificationInterface, VerificationStatusService
} from "@brightside-web/desktop/data-access/shared";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "@brightside/brightside-ui-services";
import {Subscription} from "rxjs";
import {HubPayload} from "@aws-amplify/core/lib-esm/Hub";
import {Router} from "@angular/router";

export interface StatusMapInterface {
  failed: StatusMapItemInterface;
  pending: StatusMapItemInterface;
  verified: StatusMapItemInterface;
}

export interface StatusMapItemInterface {
  banner: StatusMapBannerInterface;
  stepper?: StatusMapStepperInterface;
}

export interface StatusMapBannerInterface {
  id: string;
  text: string;
  action: {type: string, redirect?: string};
}

export interface StatusMapStepperInterface {
  title: string;
  textBody: string;
  status?: string;
  steps?: stepsObjects[];
}

interface stepsObjects {
  label: string;
  details?: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusNotificationsService implements OnDestroy {

  sub = new Subscription();

  STATUSMAP: StatusMapInterface = {
    failed: {
      banner: {
        id: "verification_failed",
        text: "verification_failed",
        action: {
          "type": "navigate",
          "redirect": "/verification/status"
        }
      },
      stepper: {
        "title": "verification_status_title",
        "textBody": "verification_error_desc",
        "status": "failed",
        "steps": [
          {
            "label": "verification_reverify",
            "details": "verification_reverify_desc",
            "status": "failed"
          },
          {
            "label": "verification_validating",
            "details": "verification_validating_desc",
            "status": "incomplete"
          },
          {
            "label": "verification_complete",
            "details": "verification_complete_desc",
            "status": "incomplete"
          }
        ]
      }
    },
    pending: {
      banner: {
        "id": "verification_pending",
        "text": "verification_pending",
        "action": {
          "type": "navigate",
          "redirect": "/verification/status"
        }
      },
      stepper: {
        "title": "verification_status_title",
        "textBody": "verification_status_desc",
        "status": "pending",
        "steps": [
          {
            "label": "verification_submitted",
            "details": "verification_submitted_desc",
            "status": "complete"
          },
          {
            "label": "verification_validating",
            "details": "verification_validating_desc",
            "status": "current"
          },
          {
            "label": "verification_complete",
            "details": "verification_complete_desc",
            "status": "incomplete"
          }
        ]
      }
    },
    verified: {
      banner: {
        "id": "verification_successful",
        "text": "verification_successful",
        "action": {
          "type": "dismiss"
        }
      }
    }
  };
  verificationStatus: string;
  mobileState: MobileStateResponse;

  constructor(
    private translateService: TranslateService,
    private analytics: FirebaseService,
    private toastService: ToastService,
    private verificationStatusService: VerificationStatusService,
    private router: Router) {
    this.mobileState = Cache.getItem('/client/mobilestate') as MobileStateResponse;

    if (!this.mobileState.sf_record_type || this.mobileState.sf_record_type === 'employee') {
      this.sub.add(
        this.verificationStatusService.getEmploymentVerificationStatusFromAPI().subscribe(
          response => {
            if (response.implementation_type !== 'full' && response.employment_data_verification_status && response.employment_data_verification_status.status) {
              this.verificationStatus = response.employment_data_verification_status.status;
              if (Object.keys(this.STATUSMAP).indexOf(this.verificationStatus) >= 0) {
                if (
                  this.verificationStatus !== 'verified' ||
                  (this.verificationStatus === 'verified' && !window.localStorage.getItem('VerifiedBannerSeen'))
                ) {
                  this.handleVerificationToast();
                }
              }
            }
          }
        )
      )
    }


    Hub.listen('ToastChannel', (data) => {
      const { payload } = data;
      this.onToastEvent(payload);
    });
  }


  checkForNotifications() {
    const halfSheetNotifications: NotificationInterface[] = [];
    const desktopNotifications: NotificationInterface[] = [];

    if (this.mobileState && this.mobileState.notifications && this.mobileState.notifications.length > 0) {
      this.mobileState.notifications.forEach( notification => {
        const notificationItem = notification as NotificationInterface;
        if (notificationItem.halfsheet) halfSheetNotifications.push(notificationItem);
        if (notificationItem.desktopSupported) desktopNotifications.push(notificationItem);
      })
      if (halfSheetNotifications.length !== 0) {
        halfSheetNotifications.forEach( (banner, index) => {
          this.displayHalfSheetToast(banner);
        });
      }
      if (desktopNotifications.length !== 0) {
        desktopNotifications.forEach( (banner, index) => {
          this.displayPlainToast(banner);
        });
      }
    }
  }

  displayPlainToast(banner: NotificationInterface) {
    this.sub.add(
      this.translateService.get(banner.text).subscribe(
        () => {
          const bannerCopy = this.translateService.instant(banner.text);
          this.analytics.logEvent('banner shown', { id: banner.id, value: bannerCopy});
          this.toastService.infoBlue(bannerCopy, {transient: false, id: banner.id});
        }
      )
    );
  }

  displayHalfSheetToast(banner: NotificationInterface) {
    this.sub.add(
      this.translateService.get(banner.text).subscribe(
        () => {
          const modalObj : GlobalModalInterface = {
            titleKey: banner.halfsheet.title,
            bodyKey: banner.halfsheet.subText,
            displayMe: true,
            type: 'banner',
            id: banner.halfsheet.id,
            bannerId: banner.id
          };
          const bannerCopy = this.translateService.instant(banner.text);
          this.analytics.logEvent('banner shown', { id: banner.id, value: bannerCopy});
          this.toastService.warn(bannerCopy, {transient: false, id: banner.halfsheet.id,link: true, modal: modalObj});
        }
      )
    );
  }

  handleVerificationToast() {

    // @ts-ignore
    const activeBanner = this.STATUSMAP[this.verificationStatus];
    const bannerCopy = this.translateService.instant(activeBanner.banner.text.toUpperCase());
    this.analytics.logEvent('banner shown', { id: activeBanner.banner.id, value: bannerCopy});

    switch (this.verificationStatus) {
      case 'failed':
        this.toastService.warn(bannerCopy, {transient: false, id: activeBanner.banner.id, link: true});
        break;
      case 'pending':
        this.toastService.infoBlue(bannerCopy, {transient: false, id: activeBanner.banner.id, link: true});
        break;
      case 'verified':
        this.toastService.success(bannerCopy, {transient: false, id: activeBanner.banner.id});
        break;
    }
  }

  onToastEvent(payload: HubPayload) {
    let statusObj;
    if(!payload.data.closing) {
      Object.keys(this.STATUSMAP).forEach(status => {
        // @ts-ignore
        const statusItem = this.STATUSMAP[status];
        if (statusItem.banner.id === payload.data.id) {
          const bannerCopy = this.translateService.instant(statusItem.banner.text.toUpperCase());
          this.analytics.logEvent('banner tapped', {id: statusItem.banner.id, value: bannerCopy});
          statusObj = statusItem as StatusMapItemInterface;
          if (statusObj.banner.action.redirect) {
            this.router.navigate([statusObj.banner.action.redirect], {state: {stepperInfo: statusObj.stepper}});
          }
          if (status === 'verified') {
            window.localStorage.setItem('VerifiedBannerSeen', 'true');
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
