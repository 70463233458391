<ui-page-transition [reverseExitAnimation]="shouldReverseExitAnimation">
  <bw-action-article page-template [coreTracking]="fromDataTrackingDetail" [minHeight]="0" [edgeToEdge]="true">
    <div id="templateFlyerHeaderAction" header-actions class="flex-row pointer">
      <bw-icon
        *ngIf="data.inputs.navType === navType.CLOSE"
        color="var(--secondary-text)"
        iconName="close"
        [dismissedCta]="true"
        [coreTracking]="fromDataTrackingDetail"
        (click)="fromDataAction.executeAction(action.navLeftIcon)"
      ></bw-icon>
      <bw-back
        *ngIf="data.inputs.navType === navType.BACK"
        back-action-top
        [coreTracking]="fromDataTrackingDetail"
        [emit]="true"
        (back)="fromDataAction.executeAction(action.navLeftIcon)"
      ></bw-back>
    </div>

    <div *ngIf="fromDataMedia" id="templateFlyerHeroImage" pre-title>
      <ui-media [data]="fromDataMedia"></ui-media>
    </div>

    <ui-header-default
      header-title
      *ngIf="fromDataHeader && fromDataHeader.type === 'default'"
      id="templateFlyerHeaderTitle"
      [title]="fromDataHeader.title"
      [subTitle]="fromDataHeader.subTitle || ''"
    ></ui-header-default>

    <ui-header-eyebrow
      header-title
      *ngIf="fromDataHeader && fromDataHeader.type === 'eyeBrow'"
      id="templateFlyerHeaderTitle"
      [title]="fromDataHeader.title"
      [subTitle]="fromDataHeader.subTitle || ''"
    ></ui-header-eyebrow>

    <ui-content-display id="templateFlyerContentSection" content-section [data]="fromDataContent">
      <ng-content custom-content-section select="[custom-content-section]"></ng-content>
    </ui-content-display>

    <div *ngIf="fromDataFooter.inputs.link" id="templateFlyerFooterTop" footer-top>
      <a
        bw-anchor
        color="var(--secondary-text)"
        anchorStyle="text-medium-regular"
        [coreTracking]="{ event_name: fromDataTrackingDetail.event_name + '_footer_link' }"
        [anchorUnderline]="true"
        (click)="fromDataAction.executeAction(action.footerLink)"
      >
        {{ fromDataFooter.inputs.link | translate }}
      </a>
    </div>
    <ui-footer-default
      *ngIf="fromDataFooter"
      footer-actions
      id="templateFlyerFooterActions"
      class="flex-row row-reverse"
      [data]="fromDataFooter"
      [cta1State]="state.withKey$(stateKeys.CTA_1) | async"
      [cta2State]="state.withKey$(stateKeys.CTA_2) | async"
      (cta1ActionHandler)="fromDataAction.executeAction(action.cta1)"
      (cta2ActionHandler)="fromDataAction.executeAction(action.cta2)"
    ></ui-footer-default>
  </bw-action-article>
</ui-page-transition>

<ng-container *ngIf="fromDataActiveModal && fromDataActiveModal.data">
  <ng-container
    *ngTemplateOutlet="
      contentModal;
      context: { modalDetails: fromDataActiveModal, $implicit: logPrefix, $implicit: allowVerboseDebugMode }
    "
  ></ng-container>
</ng-container>

<ng-template #contentModal let-modalDetails="modalDetails" let-logPrefix let-allowVerboseDebugMode>
  <bw-modal class="flyer-modal">
    <section class="body">
      <ui-page-half-sheet
        [data]="modalDetails.data"
        [state]="state"
        [logPrefix]="'modal:' + modalDetails.modalId"
        [allowVerboseDebugMode]="allowVerboseDebugMode"
      ></ui-page-half-sheet>
    </section>
  </bw-modal>
</ng-template>
