import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TermsService,
  isTemplateFullScreen,
  BasicComponentConfig,
  FirebaseService,
  AnalyticsAttributesV4, AnalyticsActions
} from '@brightside-web/desktop/data-access/shared';
import { RoutingStateService } from '@brightside/brightside-ui-services';

import { Consent } from '../consent.manager';

import { Subscription } from 'rxjs';
import {CellSeparatorType, MenuItem} from "@brightside/brightside-ui";

/**
 * Class hierarchy is huge.
 */
export class ConsentsSettings extends BasicComponentConfig {
  constructor(config: any) {
    super(config);
    this.analytics = config.analytics || null;
    this.moveNavToBottom = config.moveNavToBottom || false;
    this.consentList = config.consentList || [];
    this.consentList.forEach((consent) => {
      consent.accepted = false;
    })
  }

  moveNavToBottom?: boolean;
  analytics?: AnalyticsActions;
  consentList: Consent[];
}

@Component({
  selector: 'brightside-web-consents',
  templateUrl: './consents.component.html',
  styleUrls: ['./consents.component.scss'],
})
export class SharedConsentsComponent implements OnInit {
  readonly CellSeparatorType = CellSeparatorType;
  sub = new Subscription();

  confirmDataForm: FormGroup = new FormGroup({
    confirmCheckbox: new FormControl('', Validators.requiredTrue)
  });

  displayedConsentKey: string;

  config: ConsentsSettings;

  steps: MenuItem[] = [{}, {}, {}, {}];
  consentsFormGroup: FormGroup = new FormGroup({});
  detailsVisible = false;
  data: any;

  processing: boolean;
  showStepper = false;
  shouldMoveNavToBottom = false;

  constructor(
    private termsSvc: TermsService,
    private router: Router,
    private routingState: RoutingStateService,
    protected analytics: FirebaseService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.checkForConfigViaData();
  }

  private checkForConfigViaData() {
    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new ConsentsSettings(this.activatedRoute.snapshot.data.pageConfig);
    } else {
      this.config = new ConsentsSettings({});
      console.log('No configs have been passed in the DATA of the route.');
    }

    console.log(`subtitle: ${this.config.pageSubTitle}`);
    this.showStepper = (this.config.pageStepperActiveIndex || -1) >= 0;
    this.shouldMoveNavToBottom = this.config.moveNavToBottom === true;

    if (isTemplateFullScreen(this.activatedRoute)) {
      this.shouldMoveNavToBottom = true;
    }
  }

  private startAcceptingTerms() {
    const handleSuccess = () => {
      this.processing = false;
      if ( this.config && this.config.analytics && this.config.analytics.tapped ) {
        this.analytics.logEvent(this.config.analytics?.tapped);
      }
      this.router.navigate(this.config.pageOnForwardCtaPath);
    };
    const handleFailure = () => {
      this.processing = false;
      this.analytics.logEvent('error_shown', {'error id': 'accept_terms'});
    };

    this.sub.add(this.termsSvc.acceptTermsFromConsentArray(this.config.consentList).subscribe(handleSuccess, handleFailure));
  }

  get allAccepted(): boolean {
    return this.config.consentList.every((consent) => consent.accepted);
  }

  handleRouteBackward() {
    if (!this.config.pageOnBackCtaPath) {
      return true;
    }

    this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath);
    return false;
  }

  accept() {
    this.processing = true;

    this.startAcceptingTerms();
  }

  fetchTerms(event: Event, key: string) {
    event.stopPropagation();
    this.detailsVisible = true;
    this.sub.add(
      this.termsSvc.fetchTerms(key).subscribe((data) => {
        this.data = data;
      })
    );
  }
}
