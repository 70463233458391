import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CurrencyMaskInputMode } from 'ngx-currency';
import { InputMaskOptions, ngxMaskOptions } from '../../brightside-ui.module';

import { Subscription } from 'rxjs';

@Component({
  selector: 'bw-currency-input',
  animations: [
    trigger('enterAnimation', [
      state(
        'show',
        style({
          minHeight: '16px',
          visibility: 'visible',
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          minHeight: '0px',
          visibility: 'hidden',
          opacity: 0,
        })
      ),
      transition('show => hide', [animate('0.05s')]),
      transition('hide => show', [animate('0.1s 0.2s')]),
    ]),
  ],
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyInputComponent implements OnInit, OnDestroy, AfterViewInit {
  private sub = new Subscription();

  currencyInput: FormGroup;
  error = false;
  hint = true;
  isFocused: boolean;

  @Input() fcName: string;

  /** The label string that will be renderend inside the label element */
  @Input() label: string;

  /** The native input element value */
  @Input() value: any = '';

  /** Whether the input is disabled */
  @Input() disabled: boolean;

  /** Whether the input element is required */
  @Input() required: boolean;

  /** Options to use for the currency mask from angular */
  @Input() currencyOptions: InputMaskOptions = {};

  /** Whether the input has an error in validation */
  @Input() inputError: boolean;

  /** Text that is shown below the input if there is a validation error */
  @Input() inputErrorText: string;

  /** Text that is shown below the input that serves as an input hint */
  @Input() inputHintText: string;

  @Input() verticalSpace = 'mb-xs';
  @Input() horizontalSpace = 'mr-null';

  @Input() autoFocus: boolean;

  @Input() onChange: Function;

  @Input() placeholder = '';

  /** Event that signals a change in the input model */
  @Output() inputModelChange = new EventEmitter<string>();

  @ViewChild('elementRef') el: ElementRef;

  ngOnInit() {
    const DefaultMaskOptions = {
      ...ngxMaskOptions,
      precision: 2,
      prefix: '$',
      allowNegative: false,
      align: 'left',
      nullable: true,
      inputMode: CurrencyMaskInputMode.NATURAL, //Use '.FINANCIAL' for cash register type entry
    };
    const defaultValue = !this.value ? null : this.value;

    this.currencyInput = new FormGroup({});
    this.currencyInput.addControl(this.fcName, new FormControl(defaultValue, [Validators.required]));
    this.currencyOptions = { ...DefaultMaskOptions, ...this.currencyOptions };

    if (this.onChange) this.registerOnChange(this.onChange);
  }

  onTouched(e: any) {
    // console.log('touch', e);
  }

  onFocus(e: any) {
    // console.log('focus', e);
    this.isFocused = true;
  }

  onBlur(e: any) {
    // console.log('blur', e);
    this.isFocused = false;
    this.onTouched(e)
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 1);
    }
  }

  clearValue() {
    this.currencyInput.reset();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: Function): void {
    this.sub.add(
      this.currencyInput.valueChanges.subscribe((valueChange: { [key: string]: number }) => fn(Object.values(valueChange)[0]))
    );
  }
}
