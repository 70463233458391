import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MicroRouteFindDataByKey } from '@micro-core/utility';

import {
  UiTemplateContentFormType,
  UiTemplateContentGroupRadioOption,
  UiTemplateFormContent,
  UiTemplateContentGroupRadio,
} from '@micro-ui/template/content';
import { UiTemplateFormFlyerDataBuilder, UiTemplateFormFlyerInterface } from '@micro-ui/template/page';

import { MicroContestEntryType, MicroContestRouteDataKey } from '../../../model/contest.enum';
import { MicroContestDetail } from '../../../model/contest.interface';

@Injectable({ providedIn: 'root' })
export class MicroContestEntryAutosaveResolver implements Resolve<UiTemplateFormFlyerInterface> {
  constructor() {}

  /**
   * This is kinda of a mess. cont...
   */
  filterDataWithOptions(formContent: UiTemplateFormContent[], contestDetails: Partial<MicroContestDetail>) {
    formContent.forEach((content: UiTemplateFormContent) => {
      if (content.type === UiTemplateContentFormType.GROUP_RADIO) {
        (content.data as UiTemplateContentGroupRadio).options = contestDetails.data
          ?.filter((value) => value && Object.keys(value).length > 0)
          .map(
            (remoteDataRow: UiTemplateContentGroupRadioOption) =>
              remoteDataRow /* Not needed right now but if we want any validation or whatever on incoming options */
          ) as UiTemplateContentGroupRadioOption[];
      }
    });
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const templateData: UiTemplateFormFlyerInterface = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
    const contestDetails: Partial<MicroContestDetail> =
      MicroRouteFindDataByKey<Partial<MicroContestDetail>>(routeSnapshot, MicroContestRouteDataKey.REMOTE_CONFIG_CONTEST) || {};

    /**
     * This is kinda of a mess.. we need to:
     * 1. check for autosave type
     * 2. check for data in the remote config
     * 3. check for formContent inside the templateData
     */
    if (contestDetails.type?.includes(MicroContestEntryType.AUTO_SAVE) && (contestDetails.data || []).length > 0) {
      if (templateData.inputs.formContent && templateData.inputs.formContent.length > 0) {
        this.filterDataWithOptions(templateData.inputs.formContent, contestDetails);
      }
    }

    return UiTemplateFormFlyerDataBuilder({ ...templateData });
  }
}
