import { MessageBusOutgoingEvent } from '../../models/message-bus.interface';
import { MessageBusNativeWindowReference } from '../../native-window/message-bus-native-window.reference';

import { MessageBusPlatformBaseMessageHandler, MessageBusPlatformNativeEventBody } from './platform-base.service';

/**
 * Window.nativeWindow.webkit.messageHandlers.buttonClicked.postMessage = For IOS and testing of injection into bus
 */
declare global {
  interface Window {
    nativeWindow: {
      webkit: {
        messageHandlers: {
          buttonClicked: { postMessage: Function };
        };
      };
    };
  }
}

const checkForUserAgentMatch = () =>
  !/safari/.test(window.navigator.userAgent.toLowerCase()) && /iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase());
const checkForBridgeListener = () => typeof (window as any).webkit?.messageHandlers?.buttonClicked !== 'undefined';

const IsRunningIOS = () => checkForUserAgentMatch() || checkForBridgeListener();

/**
 * @description
 * iOS specific service
 */
class MessageBusPlatformIosMessageHandler extends MessageBusPlatformBaseMessageHandler {
  constructor(private nativeWindowReference: MessageBusNativeWindowReference) {
    super();
  }

  private get webkit() {
    return this.nativeWindowReference?.nativeWindow?.webkit || {};
  }

  override get messageReceiver() {
    return (event: MessageBusOutgoingEvent) => {
      const generatedEventClass: MessageBusPlatformNativeEventBody = this.prepareNativeEvent(event, true);

      this.webkit.messageHandlers.buttonClicked.postMessage.bind(this.webkit.messageHandlers.buttonClicked)(generatedEventClass);
    };
  }
}

export { IsRunningIOS, MessageBusPlatformIosMessageHandler };
