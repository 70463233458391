<div class="content-layout" [brightsideWebFb]="{ event_name: fbEventName + '_shown' }">
  <bw-action-article>
    <bw-back header-actions [brightsideWebFb]="{ event_name: fbEventName + '_dismissed', action: 'click' }"></bw-back>
    <div pre-title>
      <bw-steps
        *ngIf="pageStepperActiveIndex >= 0"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>
    <div content-section>
      <p class="secondary-text mb-md">{{ pageSubTitle }}</p>

      <form [formGroup]="routingForm" autocomplete="off">
        <div class="form-grid">
             <bw-input
              [isCustomControl]="true"
              label="Amount 1"
              [inputError]="(amountOne?.invalid && amountOne?.touched) || showError"
              inputErrorText="Incorrect deposit amount"
            >
              <input type="text" class="body" formControlName="amountOne" currencyMask [options]="{ prefix: '$', align: 'left' }" />
            </bw-input>

            <bw-input
              [isCustomControl]="true"
              label="Amount 2"
              [inputError]="(amountTwo?.invalid && amountTwo?.touched) || showError"
              inputErrorText="Incorrect deposit amount"
            >
              <input type="text" class="body" formControlName="amountTwo" currencyMask [options]="{ prefix: '$', align: 'left' }" />
            </bw-input>
        </div>
       </form>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="pageCtaLabel"
        bw-button
        type="submit"
        [disabled]="routingForm.invalid"
        [processing]="processing"
        (click)="handleCtaClick()">
        {{ pageCtaLabel }}
      </button>

      <typography text-large-semibold>
        <button *ngIf="pageSecondaryCtaLabel" bw-link-button class="bs-blue brand pr-xl" (click)="handleSecondaryCtaClick()">{{
          pageSecondaryCtaLabel
        }}</button>
      </typography>
    </div>
  </bw-action-article>
</div>

<bw-modal *ngIf="showResend" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">Resend deposits</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">{{ resendLineWithDate }}</typography>
    </bw-paragraph>
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)"
        >If you do not see your two deposits in your bank account, request to resend them below. They will take up to 7 days to
        appear.</typography
      >
    </bw-paragraph>
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)"
        >You can initiate your deposits up to three times{{ resendTimesLeft }}</typography
      >
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        *ngIf="resendPrimaryCta"
        bw-button
        [processing]="processing"
        (click)="handleResendCtaClick()">
        {{ resendPrimaryCta }}
      </button>

      <button *ngIf="resendSecondaryCta" bw-link-button class="bs-blue pr-xl" (click)="showResend = false">{{ resendSecondaryCta }}</button>
    </div>
  </footer>
</bw-modal>

<bw-modal *ngIf="showTryAnotherWay" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">Let’s try this another way</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">
        You have hit your limit of resending your deposits. Chat with your Financial Assistant to move forward with verifying your account.
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [processing]="processing"
        (click)="handleTryAnotherCtaClick()">
        Message Financial Assistant
      </button>

      <button *ngIf="resendSecondaryCta" bw-link-button class="bs-blue pr-xl" (click)="handleTryAnotherSecondaryCtaClick()"
        >View your account</button
      >
    </div>
  </footer>
</bw-modal>
