import {Injectable, OnDestroy} from '@angular/core';
import { MobileStateService } from "@brightside-web/desktop/data-access/shared";
import { Subscription } from "rxjs";

import {
  CacheStorageRefService,
  CacheItemOptions,
  CacheInterface
} from "./cache-storage-ref.service";

@Injectable({
  providedIn: 'root'
})
export class CacheStorageService implements OnDestroy {
  private _cache: CacheInterface;
  private sub = new Subscription();
  private _guid = '';

  constructor(
    private mobileStateService: MobileStateService,
    private cacheStorageRefService: CacheStorageRefService
  ) {
    this._cache = this.cacheStorageRefService.cacheStorage;
    this.getGuid();
  }

  getItem(key: string, withoutGuid = false): string {
    if (withoutGuid) {
      return this._cache.getItem(key);
    }
    const result = this._cache.getItem(this.buildKey(key));
    if (!result) {
      return this.getItem(key, true);
    } else {
      return result;
    }
  }

  setItem(key: string, value: any, options?: CacheItemOptions) {
    this._cache.setItem(this.buildKey(key), value, options);
  }

  removeItem(key: string) {
    this._cache.removeItem(this.buildKey(key));
  }

  clear() {
    this._guid = '';
    this._cache.clear();
  }

  buildKey(key: string) {
    if (!this._guid) this.getGuid();
    const newKey = !this._guid ? key: `${key}_${this._guid}`;
    return newKey;
  }

  getGuid() {
    this._guid = this._cache.getItem('GUID') ?? '';
    if (!this._guid) {
      this.sub.add(
        this.mobileStateService.get(true).subscribe(
          ms => {
            if (ms.user_guid) {
              this._guid = ms.user_guid;
              this._cache.setItem('GUID', this._guid);
            }
          }
        )
      )
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
