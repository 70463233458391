import { Injectable } from '@angular/core';
import { ApiCacheService, SavingsPayrollScheduleResponse } from '@brightside-web/desktop/data-access/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavingsPayrollService {
  constructor(private apiCache: ApiCacheService) {}

  getSchedule(force: boolean = false): Observable<SavingsPayrollScheduleResponse> {
    if (force) {
      this.apiCache.refreshItem('/payroll/schedule');
    }

    return this.apiCache.get<SavingsPayrollScheduleResponse>('api-mobile', '/payroll/schedule');
  }
}
