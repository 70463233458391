import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType, UiTemplateContentGroupCell } from '@micro-ui/template/content';
import { UiTemplateFormFlyerDataBuilder, UiTemplateNavType } from '@micro-ui/template/page';

import { MicroContestAnalyticsEventName } from '../../model/contest.enum';

const TemplateData = UiTemplateFormFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.NONE,
    header: {
      type: UiHeaderType.EYE_BROW,
      title: 'PLS_LEGAL_TITLE',
      subTitle: 'PLS_LEGAL_SUB_TITLE',
    },
    content: [
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: '',
          title: 'PLS_LEGAL_CONTENT_TITLE',
          //Subtile is here because there seems to be an issue with how copy is loaded. At times it's not ready
          //for the resolver function
          subTitle: `To enter without autosaving, print out this entry form and mail to: Brightside Benefit Sweepstakes, 55 N Arizona Pl. Suite 200, Chandler AZ 85225. <br /><br /> Entries received more than five (5) business days from the last day of the month will be entered in the current month's Sweepstakes. Entries received within five (5) business days of the end of the month will be included in the next monthly Sweepstakes. <br /><br /> By printing and mailing this form, you acknowledge and agree to the Sweepstakes <a href="//www.gobrightside.com/terms-of-service" target="_blank">Terms of Service</a> and the Brightside <a href="//www.gobrightside.com/privacy-policy" target="_blank">Privacy Policy</a>.`,
        },
      },
      {
        type: UiTemplateContentType.GROUP_CELL,
        data: {
          groupName: 'contest.legal.amoe',
          options: [
            {
              label: 'PLS_LEGAL_CONTENT_FNAME_LABEL',
              value: 'fName',
            },
            {
              label: 'PLS_LEGAL_CONTENT_LNAME_LABEL',
              value: 'lName',
            },
            {
              label: 'PLS_LEGAL_CONTENT_PHONE_LABEL',
              value: 'phone',
            },
          ],
        } as UiTemplateContentGroupCell,
      },
    ],
    formContent: [],
    viewName: MicroContestAnalyticsEventName.ENTRY_COMPLETE,
  },
  actions: {},
});

export default TemplateData;
