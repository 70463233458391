import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { MobileStateService
} from './mobile-state.service';
import { map } from 'rxjs/operators';
import { Cache } from 'aws-amplify';
import { ApiResponse, AvailabilityHours, FullSsnRequest, RequiredVerification } from './api-contracts';

@Injectable({
  providedIn: 'root'
})
export class FullSsnService {
  constructor(private mobile:MobileStateService) { }

  submitFullSsn(payload:FullSsnRequest):Observable<ApiResponse>{
    return from(API.put('api-mobile','/client',{body: payload}));
  }

  createClientEvent() {
    API.post('api-mobile', '/client/event', { body: { type: 'collectSsnError' } });
  }

  getAvailability():Observable<AvailabilityHours | null > {
    return this.mobile.get().pipe(
      map(ms => {
        if (ms && ms.availability) {
          Cache.setItem('availability', ms.availability);
          return ms.availability;
        } else {
          return null
        }
      })
    )
  }

  getRequiredVerification():Observable<RequiredVerification | null> {
    return this.mobile.get().pipe(
      map(mobileState => {
          if (mobileState && mobileState.requiredVerification?.fullSsn?.last4) {
            Cache.setItem('last4ssn', mobileState.requiredVerification.fullSsn?.last4);
            return mobileState.requiredVerification;
          } else {
            return null
          }
        }
      )
    )
  }
}
