import { MessageBusEventUtil } from '@micro-core/message-bus';

import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentFormType, UiTemplateContentGroupRadioDisplayStye } from '@micro-ui/template/content';
import {
  GetUiTemplateActionRoute,
  UiTemplateAction,
  UiTemplateActionUpdateStateFunctionType,
  UiTemplateFormFlyerDataBuilder,
  UiTemplateNavType,
  UiTemplateState,
  UiTemplateStateKey,
} from '@micro-ui/template/page';

import {
  MicroContestAnalyticsEventName,
  MicroContestFormControlKey,
  MicroContestRouteModalKey,
  MicroContestTranslationKey,
} from '../../../model/contest.enum';

import TemplateData_EntryAutosaveConfirm from '../confirm-modal/entry-autosave-confirm.template';
import TemplateData_EntryAutosaveCustom from '../custom-modal/entry-autosave-custom.template';

const TemplateData = UiTemplateFormFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.BACK,
    header: {
      type: UiHeaderType.DEFAULT,
      title: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_SUB_TITLE,
      icon: '',
    },
    formContent: [
      {
        type: UiTemplateContentFormType.GROUP_RADIO,
        data: {
          displayStyle: UiTemplateContentGroupRadioDisplayStye.BLOCK,
          groupName: MicroContestFormControlKey.AUTOSAVE_AMOUNT,
          options: [
            { label: { pre: '⭐️ Easiest way to start! ⭐️', main: '$3', post: 'per day' }, value: 3 },
            { label: { main: '$4', post: 'per day' }, value: 4 },
            { label: { main: '$5', post: 'per day' }, value: 5 },
            { label: { main: 'Choose your own amount' }, value: 0 },
          ],
        },
      },
    ],
    cta1Label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CTA1,
    viewName: MicroContestAnalyticsEventName.ENTRY_AUTOSAVE,
  },
  modals: {
    [MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION]: {
      modalId: MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION,
      data: TemplateData_EntryAutosaveConfirm,
    },
    [MicroContestRouteModalKey.AUTOSAVE_CUSTOM]: {
      modalId: MicroContestRouteModalKey.AUTOSAVE_CUSTOM,
      data: TemplateData_EntryAutosaveCustom,
    },
  },
  actions: {
    [UiTemplateAction.navLeftIcon]: { route: ['../'] },
    [UiTemplateAction.cta1]: (state?: UiTemplateState, updateState?: UiTemplateActionUpdateStateFunctionType) => {
      if (state?.state_cta1) {
        state.state_cta1.processing = true;

        if (updateState) {
          updateState(state);
        }

        //Remove processing ... need better answer
        setTimeout(() => {
          if (state?.state_cta1) {
            state.state_cta1.processing = false;

            if (updateState) {
              updateState(state);
            }
          }
        }, 1500);
      }

      if (!state?.state_form?.values) {
        //ToDo: show/handle error if form info was not selected.. maybe default to autosave picker
        console.warn('No amount has been selected');
        return;
      }

      const autoSaveAmount = state.state_form.values[MicroContestFormControlKey.AUTOSAVE_AMOUNT];

      if (autoSaveAmount === 0) {
        return GetUiTemplateActionRoute.MODAL_OPEN(MicroContestRouteModalKey.AUTOSAVE_CUSTOM);
      }

      return GetUiTemplateActionRoute.MODAL_OPEN(MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION);
    },
  },
  state: {
    initial: {
      [UiTemplateStateKey.CTA_1]: {
        disabled: true,
        processing: false,
      },
    },
  },
  stateConditions: [
    {
      triggeringKeys: [UiTemplateStateKey.FORM],
      manualConditionRunner: (pendingState: UiTemplateState) => {
        //Check if form is valid and change state of disabled
        if (pendingState.state_form?.valid && pendingState.state_cta1?.disabled) {
          pendingState.state_cta1.disabled = false;
        }

        return pendingState;
      },
    },
  ],
});

export default TemplateData;
