import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {EligibleProduct, MobileStateService} from '@brightside-web/desktop/data-access/shared';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PayrollEligibleGuard implements CanActivate, CanLoad {
  constructor(private mobileSvc:MobileStateService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isEligible();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isEligible();
  }

  isEligible():Observable<boolean>{
    return this.mobileSvc.getProductOnboardingEligibility(EligibleProduct.payrollSavings).pipe(map(response => {
      if (response){
        return true;
      }
      this.router.navigate(['/home']);
      return false;
    }));
  }

}
