<ui-page-transition [reverseExitAnimation]="shouldReverseExitAnimation">
  <bw-action-article page-template minHeight="0" [brightsideWebFb]="{ event_name: config.fbEventName }">
    <div id="displayFormHeaderAction" header-actions class="flex-row pointer">
      <bw-back
        *ngIf="!config.pageHideBackButton && !pageIsFullScreen"
        back-action-top
        [brightsideWebFb]="{ event_name: config.fbEventName }"
        [emit]="!!config.pageOnBackCtaPath"
        (back)="handleBackClick()"
      ></bw-back>
      <bw-back
        *ngIf="!config.pageHideBackButton && pageIsFullScreen"
        back-action-bottom
        [brightsideWebFb]="{ event_name: config.fbEventName }"
        [emit]="!!config.pageOnBackCtaPath"
        (back)="handleBackClick()"
      ></bw-back>
    </div>

    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>

    <div content-section>
      <p text-large-regular class="mb-md" color="var(--secondary-text)">
        {{ pageSubTitle }}
      </p>

      <div class="flex-column flex-start flex-wrap mb-sm">
        <div *ngFor="let pieceKey of config.pagePieces" class="mr-sm mb-sm">
          <ng-container [ngSwitch]="pieceKey">
            <brightside-web-autosave-selector
              *ngSwitchCase="displayTypes.SELECTOR"
              [formGroup]="displayForm"
            ></brightside-web-autosave-selector>
            <brightside-web-autosave-projections
              *ngSwitchCase="displayTypes.PROJECTIONS"
              [amountPerCycle]="selectedAmount?.value"
              [payFrequency]="payFrequency"
            ></brightside-web-autosave-projections>
            <brightside-web-autosave-frequency
              *ngSwitchCase="displayTypes.FREQUENCY"
              [formGroup]="displayForm"
            ></brightside-web-autosave-frequency>
            <brightside-web-autosave-pause
              *ngSwitchCase="displayTypes.PAUSE"
              (pauseStatusChanged)="handlePauseStatusChange($event)"
            ></brightside-web-autosave-pause>
          </ng-container>
        </div>
      </div>
    </div>

    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="config.pageCtaLabel"
        bw-button
        [brightsideWebFb]="{ event_name: config.fbEventName }"
        [disabled]="!displayForm.valid"
        [processing]="processing"
        (click)="checkForConfirm()"
      >
        {{ config.pageCtaLabel }}
      </button>

      <!-- [disabled]="!displayForm.touched || !displayForm.valid" -->

      <typography text-large-semibold>
        <button
          *ngIf="config.pageSecondaryCtaLabel"
          [brightsideWebFb]="{ event_name: config.fbEventName }"
          [secondaryCta]="true"
          bw-link-button
          class="bs-blue mr-sm"
          (click)="handleSecondaryCtaClick()"
        >
          {{ config.pageSecondaryCtaLabel }}
        </button>
      </typography>
    </div>
  </bw-action-article>
</ui-page-transition>

<bw-modal
  *ngIf="showProcessError"
  (closeModal)="showProcessError = false"
  [brightsideWebFb]="{ event_name: 'error_shown', custom: { 'error id': config.fbEventName } }"
  [error_view]="true"
>
  <typography title-medium color="var(--gray-70)">{{ processErrorMessageTitle }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">{{ processErrorMessage }}</bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="showProcessError = false" [brightsideWebFb]="{ event_name: config.fbEventName + 'error' }">
        Ok
      </button>
    </div>
  </footer>
</bw-modal>
<bw-modal *ngIf="showConfirm" modalWidth="medium" (closeModal)="showConfirm = false">
  <typography title-medium>{{ 'VERIFY_AUTOSAVE_TITLE' | translate }}</typography>
  <ng-container class="body">
    <div>
      <typography
        text-large-regular
        color="var(--secondary-text)"
        [innerHTML]="'VERIFY_AUTOSAVE_DESC' | translate: { autosaveAmount: selectedAmount.value }"
      >
      </typography>
    </div>
  </ng-container>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="showConfirm = false; handleCtaClick()">
        <typography text-large-semibold>
          {{ 'VERIFY_AUTOSAVE_PRI_CTA' | translate }}
        </typography>
      </button>
      <button bw-link-button class="bs-blue pr-xl" (click)="showConfirm = false">
        {{ 'VERIFY_AUTOSAVE_SEC_CTA' | translate }}
      </button>
    </div>
  </div>
</bw-modal>
