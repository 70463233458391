import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  Self,
  Optional,
  ElementRef, ViewChild, AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { trigger, style, animate, transition, state } from '@angular/animations';

@Component({
  selector: 'bw-input',
  animations: [
    trigger(
      'enterAnimation', [
        state('show', style({
          minHeight: '16px',
          visibility: 'visible',
          opacity: 1
        })),
        state('hide', style({
          minHeight: '0px',
          visibility: 'hidden',
          opacity: 0
        })),
        transition('show => hide', [
          animate('0.05s')
        ]),
        transition('hide => show', [
          animate('0.1s 0.2s')
        ]),
      ]
    )
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @Input() isCustomControl = false;

  /** The native input element type */
  @Input() type: 'text' | 'email' | 'password' | 'phone' = 'text';

  /** Whether the input is disabled */
  @Input() disabled: boolean;

  /** Whether the input element is required */
  @Input() required: boolean;

  /** The label string that will be renderend inside the label element */
  @Input() label: string;

  /** The native input element placehoder text */
  @Input() placeholder = '';

  /** Toggles the visibiolity of the password hide/show icon. */
  @Input() togglePassword: boolean;

  /** Whether the input has an error in validation */
  @Input() inputError: boolean;

  /** Pattern is passed to the native input element for pattern validation. */
  @Input() pattern: string;

  /** Minimum length allowed in the input field */
  @Input() minlength: string | number;

  /** Minimum length allowed in the input field */
  @Input() maxlength: string | number;

  /** Text that is shown below the input if there is a validation error */
  @Input() inputErrorText: string;

  /** Text that is shown below the input that serves as an input hint */
  @Input() inputHintText: string;

  @Input() prefixSymbol: string;

  @Input() suffixIconName = 'view';
  @Input() showSuffixIcon = false;

  /**
   * NGX-Mask input to format and restrict input values to masked patterns. https://jsdaddy.github.io/ngx-mask-page/main
   *
   * NOTE: Mask is intended for use on with inputs of type text. If mask is used for inputs of any other type, the mask attribute will be ignored.
   */
  @Input() inputMask: string;

  /** Event that signals a change in the input model */
  @Output() inputModelChange = new EventEmitter<string>();

  /** The native input element value */
  @Input() value: any = '';

  @Input() verticalSpace = 'mb-xs';
  @Input() horizontalSpace = 'mr-null';

  @Input() autoFocus:boolean;
  @ViewChild('elementRef') el:ElementRef;

  error = false;
  hint = true;
  hidePass: boolean;
  isPassword: boolean;
  @Input() isFocused: boolean;

  @Input() hasInfo: boolean;
  @Output() emitInfoClicked = new EventEmitter<boolean>();
  @Output() hasFocus = new EventEmitter<boolean>();

  constructor(
    // Retrieve the dependency only from the local injector,
    // not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form,
    // so we mark the dependency as optional.
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.togglePassword) {
      this.isPassword = true;
      this.type = 'password';
    }
  }

  ngAfterViewInit(): void {
    if (this.autoFocus){
      //If you dont use a timeout, you get expressionchangedafterithasbeenchecked error
      setTimeout(()=>{
        this.el.nativeElement.focus();
      }, 1)
    }
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange(e: string) {
    this.inputModelChange.emit(e);
  }

  onTouched(e: any) {
    // console.log('touch', e);
  }

  onFocus(e: any) {
    // console.log('focus', e);
    this.hasFocus.emit(true);
    this.isFocused = true;
  }

  onBlur(e: any) {
    // console.log('blur', e);
    this.hasFocus.emit(false);
    this.isFocused = false;
    this.onTouched(e)
  }

  showHidePassword() {
    if (this.type === 'password') {
      this.type = 'text';
      this.hidePass = true;
    } else {
      this.type = 'password';

      this.hidePass = false;
    }
  }

  infoClicked() {
    this.emitInfoClicked.emit(true);
  }
}
