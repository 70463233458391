import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { MicroUiTemplatePageModule } from '@micro-ui/template/page';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';

import { NgxCurrencyModule } from 'ngx-currency';

import { AccountAddComponent } from './add/add.component';
import { AccountVerifyComponent } from './verify/verify.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    FormsModule,
    MicroUiTemplatePageModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    SharedDesktopModule,
  ],
  declarations: [AccountAddComponent, AccountVerifyComponent],
  exports: [AccountAddComponent, AccountVerifyComponent],
})
export class SharedAccountModule {}
