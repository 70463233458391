import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType } from '@micro-ui/template/content';
import { UiTemplateFlyerDataBuilder, UiTemplateNavType } from '@micro-ui/template/page';

import { MessageBusEventUtil, UseThisForStandaloneOrThat } from '@micro-core/message-bus';

import { MicroContentMediaFor, MicroContestAnalyticsEventName, MicroContestTranslationKey } from '../../../model/contest.enum';

const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UseThisForStandaloneOrThat(UiTemplateNavType.CLOSE, UiTemplateNavType.NONE),
    header: {
      type: UiHeaderType.EYE_BROW,
      title: MicroContestTranslationKey.CONTEST_WON_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_WON_SUB_TITLE,
      icon: '',
    },
    content: [
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          title: MicroContestTranslationKey.CONTEST_WON_EXTRA_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE,
        },
      },
    ],
    media: MicroContentMediaFor(MicroContestAnalyticsEventName.RESULT_WON),
    cta1Label: MicroContestTranslationKey.CONTEST_WON_CTA1,
    viewName: MicroContestAnalyticsEventName.RESULT_WON,
  },
  actions: {
    navLeftIcon: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeature),
    cta1: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeatureToSavingsHome),
  },
});

export default TemplateData;
