import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { CellSeparatorType } from '@brightside/brightside-ui';

import { UiTemplateContentType } from '../../shared/model/content-template.enum';
import { UiTemplateContent } from '../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-display',
  templateUrl: './display-content.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiContentDisplayComponent {
  @Input() data: UiTemplateContent[];

  //Make sure the content types can be used in template
  contentType = UiTemplateContentType;

  separatorType = CellSeparatorType;
}
