<form [formGroup]="createPassword">
  <bw-input
    [autoFocus]="autoFocus"
    [label]="label"
    [placeholder]="placeholder"
    [formControlName]="fcName"
    [togglePassword]="true"
    minlength="8"
    maxlength="24"
    (hasFocus)="shouldDisplayRules($event);"
  >
  </bw-input>
  <ng-container *ngIf="displayRules">
    <div
      class="password-requirement flex-row"
      [ngClass]="{
      satisfied: !createPassword.get(fcName)?.errors?.characterAmountNoSpacesUnsatisfied
    }"
    >
      <bw-icon iconName="icon-circle-check" size="16"></bw-icon> <bw-caption> 8 - 24 characters. No spaces.</bw-caption>
    </div>
    <div
      class="password-requirement flex-row"
      [ngClass]="{
      satisfied: !createPassword.get(fcName)?.errors?.capitalLetterUnsatisfied
    }"
    >
      <bw-icon iconName="icon-circle-check" size="16"></bw-icon> <bw-caption> At least one capital letter</bw-caption>
    </div>
    <div
      class="password-requirement flex-row"
      [ngClass]="{
      satisfied: !createPassword.get(fcName)?.errors?.atLeastOneNumberUnsatisfied
    }"
    >
      <bw-icon iconName="icon-circle-check" size="16"></bw-icon> <bw-caption> At least one number</bw-caption>
    </div>
  </ng-container>

</form>
