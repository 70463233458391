import {Injectable, OnDestroy} from '@angular/core';

import {
  FirebaseService,
  MobileStateResponse,
  MappingDetail,
  RouteInfoInterface, MobileStateService
} from "@brightside-web/desktop/data-access/shared";
import {Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CampaignRoutingService implements OnDestroy {
  nativeToDesktopMapping: any;
  mobileState: MobileStateResponse;
  currentlyWorking = false;
  sub = new Subscription();

  constructor(
    protected analytics: FirebaseService,
    private mobileStateService: MobileStateService
  ) {
    this.sub.add(
      this.mobileStateService.mobileState.subscribe(
        ms => {
          if (ms) {
            this.mobileState = JSON.parse(ms) as MobileStateResponse;
          }
        }
      )
    )
  }


  campaignInMobileState(campaignKey: string) : MappingDetail | null {
    if (this.mobileState.campaigns && campaignKey in this.mobileState.campaigns) {
      const ctaPath = this.mobileState.campaigns[campaignKey].path || '';
      return { path: ctaPath };
    }
    return null;
  }

  campaignInRemoteConfig(routeToString: string) : MappingDetail | null {
    this.nativeToDesktopMapping = this.nativeToDesktopMapping ?? this.analytics.remoteConfigMapping();
    if (routeToString in this.nativeToDesktopMapping) {
      return this.nativeToDesktopMapping[routeToString] || {path: ''};
    }
    return null;
  }

  navigateToCampaign(campaignLink: string, errorEventName?: string) : RouteInfoInterface | undefined {

    if (campaignLink && !this.currentlyWorking) {
      this.currentlyWorking = true;
      setTimeout(() => {this.currentlyWorking = false;}, 1000);
      const breakRoute = campaignLink.replace('gobrightside://', '').split('?');
      const msCampaignPath = this.campaignInMobileState(breakRoute[0]);

      if (msCampaignPath) {
        return {mappingDetail: msCampaignPath, queryString: breakRoute[1]};
      } else {
        const rcCampaignPath = this.campaignInRemoteConfig(breakRoute[0]);
        if (rcCampaignPath) {

          if(rcCampaignPath.path === 'loans') {
            rcCampaignPath.path = 'loans/intro';
          }

          return {mappingDetail: rcCampaignPath, queryString: breakRoute[1]};
        } else {
          if (errorEventName) this.analytics.logEvent(errorEventName);
          return undefined;
        }
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
