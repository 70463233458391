import { Injectable } from '@angular/core';
import {API, Cache, Hub} from 'aws-amplify';
import { from, Observable } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {
  AnalyticsActions,
  Campaign,
  FirebaseService, Question,
  QuestionsSettings
} from '@brightside-web/desktop/data-access/shared';
import {Router} from "@angular/router";

export interface OnboardingFlowStep {
  type: OnboardingFlowStepType;
  details?: FlowStep;
  apiResponse?: OnboardingFlowResponse;
}

export interface OnboardingFlowResponse {
  nextStep: { type: string };
  lastStep: boolean;
  template: OnboardingFlowTemplate;
  apiCall?: OnboardingApiCall;
  survey?: OnboardingSurvey;
  campaign?: OnboardingCampaign;
  faIntro?: OnboardingFaIntro;
  redirect?: OnboardingRedirect;
  isNavigation?: boolean;
  postOnboarding?: { endpoint?: string };
}

export const getFlowStep = (flow:OnboardingFlowResponse, phase: string): FlowStep | null => {
  if ( phase === "template" ) {
    return flow.template || null;
  } else if ( phase === "campaign" ) {
    return flow.campaign || null;
  } else if ( phase === "survey" ) {
    return flow.survey || null;
  } else if ( phase === "redirect" ) {
    return flow.redirect || null;
  } else if ( phase === "apiCall" ) {
    return flow.apiCall || null;
  } else if ( phase === "faIntro" ) {
    return flow.faIntro || null;
  }


  return null;
}




// tslint:disable-next-line:no-empty-interface
export interface FlowStep {
  analytics: AnalyticsActions;
}

export interface OnboardingFlowTemplate extends FlowStep {
  icon: string;
  title: string;
  textBody: string;
  cta: string;
  ctaPath: string;
  analyticsPage: string;
}

export interface OnboardingApiCall extends FlowStep {
  endpoint?: string;
}

export interface OnboardingSurvey extends FlowStep {
  type: any;
  canSkipQuestions?: boolean;
  questions: Question[];
}

export class OnboardingSurveyStep implements FlowStep {
  type: any;
  canSkipQuestions?: boolean;
  questions: Question[];
  analytics: AnalyticsActions;
  questionSettings: QuestionsSettings;

  constructor(object:OnboardingSurvey, settings: QuestionsSettings) {
    this.type = object.type;
    this.analytics = object.analytics;
    this.canSkipQuestions = object.canSkipQuestions;
    this.questions = object.questions;
    this.questionSettings = settings;
  }

}

type OnboardingSurveySettings = OnboardingSurvey & QuestionsSettings;

/**
 * all of these interfaces are FlowSteps these will check and cast the object when they pass.
 *
 * @param object
 */
export const isAnOnboardingCampaign = (object: FlowStep): object is OnboardingCampaign => 'key' in object && 'ctaPath' in object && 'title' in object;
export const isAnOnboardingFaIntro  = (object: FlowStep): object is OnboardingFaIntro =>  'analytics' in object && 'endpoint' in object;
export const isAnOnboardingSurvey   = (object: FlowStep): object is OnboardingSurvey => 'questions' in object && 'type' in object;
export const isAnOnboardingTemplate = (object: FlowStep): object is OnboardingFlowTemplate => 'title' in object && 'textBody' in object && !('key' in object);
export const isAnOnboardingRedirect = (object: FlowStep): object is OnboardingRedirect => 'path' in object;
export const isAnOnboardingApiCall  = (object: FlowStep): object is OnboardingApiCall => 'endpoint' in object;

export interface OnboardingCampaign extends FlowStep, Campaign {
  closeable?:boolean;
}

export interface OnboardingFaIntro extends FlowStep, Campaign {
  endpoint?:string;
}


export interface OnboardingRedirect extends FlowStep {
  path: string;
  toast?: string;
}

export const mapFlowStepFromString = (step: string) : OnboardingFlowStepType => {
  if ( step === "apiCall" ) {
    return OnboardingFlowStepType.API_CALL;
  }
  else if ( step === "complete" ) {
    return OnboardingFlowStepType.COMPLETE;
  }
  else if ( step === "campaign" ) {
    return OnboardingFlowStepType.CAMPAIGN;
  }
  else if ( step === "redirect" ) {
    return OnboardingFlowStepType.REDIRECT;
  }
  else if ( step === "survey" ) {
    return OnboardingFlowStepType.SURVEY;
  }
  else if ( step === "faIntro" ) {
    return OnboardingFlowStepType.FAINTRO;
  }
  else if ( step === "unknown" ) {
    return OnboardingFlowStepType.UNKNOWN;
  }
  else {
    return OnboardingFlowStepType.TEMPLATE;
  }
}

export enum OnboardingFlowStepType {
  API_CALL = 'apiCall',
  COMPLETE = 'complete',
  CAMPAIGN = 'campaign',
  FAINTRO = 'faIntro',
  REDIRECT = 'redirect',
  SURVEY = 'survey',
  TEMPLATE = 'template',
  UNKNOWN = 'unknown'
}

export enum CampaignEventStatus {
  SKIPPED = 'skipped',
  SUCCESS = 'success',
  FAILED = 'failed',
  NEUTRAL = 'neutral',
}


export enum OnboardingFlowStepStatus {
  SKIPPED = 'skipped',
  SUCCESS = 'success',
  FAILED = 'failed',
  NEUTRAL = 'neutral',
}

export const onboardingStatusFromCampaignStatus = (status: CampaignEventStatus): OnboardingFlowStepStatus => {
  switch(status) {
    case CampaignEventStatus.SKIPPED:
        return OnboardingFlowStepStatus.SKIPPED;
    case CampaignEventStatus.SUCCESS:
      return OnboardingFlowStepStatus.SUCCESS;
    case CampaignEventStatus.FAILED:
      return OnboardingFlowStepStatus.FAILED;
    case CampaignEventStatus.NEUTRAL:
      return OnboardingFlowStepStatus.NEUTRAL;
  }
}


export enum OnboardingCampaignType {
  ONBOARDING_CREDIT = 'onboardingCredit',
  FA_SCHEDULE = 'faSchedule',
  UNKNOWN = 'unknown',
}



@Injectable({
  providedIn: 'root',
})
export class FlowManagerService {
  private API_BASIC = 'api-mobile';
  private BASE_PATH = '/onboarding';

  static clearSuppressIntercom() {
    Cache.removeItem('HIDE_INTERCOM_FIRST_LOAD');
  }

  constructor(private analytics: FirebaseService, private router: Router) {}

  storeOnboardingResponse(response: OnboardingFlowResponse) {
    Cache.setItem("ONBOARDING",response);
  }

  fetchOnboardingResponse(): OnboardingFlowResponse {
    return Cache.getItem("ONBOARDING") as OnboardingFlowResponse;
  }

  onboardingAlreadyActive() : boolean {
    return Cache.getItem("ONBOARDING_FLOW_ACTIVE") as boolean || false;
  }

  enterOnboarding() {
    Cache.setItem('ONBOARDING_FLOW_ACTIVE', true);
    Cache.setItem('ONBOARDING_ENDPOINT', '');
  }

  exitOnboarding() {
    Cache.removeItem('ONBOARDING_FLOW_ACTIVE');
    Cache.removeItem('ONBOARDING_ENDPOINT');
    Cache.removeItem('ONBOARDING');
  }

  private storePostOnboarding(postEndpoint: string) {
    Cache.setItem('ONBOARDING_ENDPOINT', postEndpoint);
    // This should be removed the first time the feed is loaded after this.
    Cache.setItem('HIDE_INTERCOM_FIRST_LOAD',false);
  }

  private clearPostOnboarding() {
    Cache.removeItem('ONBOARDING_ENDPOINT');
    // This should be removed the first time the feed is loaded after this.
    Cache.setItem('HIDE_INTERCOM_FIRST_LOAD',true);
  }

  buildStepDetails(flowStep: FlowStep): FlowStep {
    if ( isAnOnboardingCampaign(flowStep)) {
      return flowStep;
    }
    else if ( isAnOnboardingSurvey(flowStep) ) {
      return this.buildStepSurvey(flowStep);
    }
    else if ( isAnOnboardingRedirect(flowStep)) {
      return flowStep;
    }
    else if ( isAnOnboardingTemplate(flowStep)) {
      return this.buildStepTemplate(flowStep);
    }
    else if ( isAnOnboardingFaIntro(flowStep)) {
      return flowStep;
    }
    /**
     * if this happens the onboarding flow was setup wrong. please look into the onboarding json response. if nextStep.type == "survey" it has to include a survey object.
     *
     */
    this.analytics.logEvent("error_shown", { 'error id': "invalid_onboarding_flow"});
    throw new Error(`Sorry, something went wrong with the onboarding process`);
  }

  private buildStepSurvey(survey: OnboardingSurvey): OnboardingSurveyStep {

    //this is nested into the survey step object.
    const questionSettings = new QuestionsSettings({
      pageTitle: '',
      pageSubTitle: '',
      pageCtaLabel: 'Continue',
      pageSecondaryCtaLabel: survey.canSkipQuestions ? 'Skip' : '',

      pageOnForwardCtaPath: [],

      questions: survey.questions || [],
      surveyType: survey.type || '',
      fbPage: 'onboarding',
      fbCategory: 'survey',
      fbSubCategory: '',
    });

    return new OnboardingSurveyStep(survey,questionSettings);
  }

  /**
   * This is in the hopes that Desktop will support template pages one day..
   *
   * @param template
   * @private
   */
  private buildStepTemplate(template: OnboardingFlowTemplate) {
    return {
      analytics: template.analytics,
      pageTitle: template.title || '',
      pageSubTitle: template.textBody || '',
      pageCtaLabel: template.cta || 'Continue',
      pageSecondaryCtaLabel: '',

      pageOnForwardCtaPath: [],

      fbPage: '',
      fbCategory: '',
    };
  }

  private executeGet(): Observable<any> {
    Cache.removeItem('onboardingError');
    return from(API.get(this.API_BASIC, this.BASE_PATH, {}).catch(err => {
      Hub.dispatch('OnboardingChannel', { event: 'OnboardingComplete', message: '' });
      Cache.setItem('onboardingError', true);
      this.router.navigateByUrl('/home');
    }));
  }

  private executePost(body: any): Observable<any> {
    return from(API.post(this.API_BASIC, this.BASE_PATH, { body }));
  }

  private executePut(status: string): Observable<any> {
    return from(API.put(this.API_BASIC, `${this.BASE_PATH}?status=${status}`, {}));
  }

  UpdateOwner() : Observable<any> {
    return from(API.post('api-mobile', '/client/event', { body: { type: 'UpdateOwner' } }));
  }

  getCurrent(): Observable<OnboardingFlowResponse> {
    return this.executeGet();
  }

  updateWithPost(payload: any): Observable<any> {
    return this.executePost(payload);
  }

  updateWithPut(status: string): Observable<any> {
    return this.executePut(status);
  }

  //These methods are not straight-forward as they manage any automatic skipping/non-supported responses
  getNextValidStep(): Observable<OnboardingFlowStep> {
    return this.getCurrent().pipe(
      switchMap(async (flowResp) => {

        //Check for null or otherwise complete
        if (!flowResp) {
          return null;
        }

        this.storeOnboardingResponse(flowResp);

        if (flowResp.postOnboarding && flowResp.postOnboarding.endpoint) {
          this.storePostOnboarding(flowResp.postOnboarding.endpoint);
        }
        else {
          // if it comes down non existent in the last step, we shouldn't do this thing.
          // honestly this postOnboarding only applies to the last step..
          this.clearPostOnboarding();
        }

        return flowResp;
      }),
      map((flowResp) => {
        //Check for null or otherwise complete
        if (!flowResp) {
          return { type: OnboardingFlowStepType.COMPLETE };
        }

        const type:OnboardingFlowStepType = mapFlowStepFromString(flowResp.nextStep.type);

        const flowStep = getFlowStep(flowResp,flowResp.nextStep.type);

        if (!flowStep) {

          throw new Error(`Error in onboarding step!`);
        }


        return { type, details: this.buildStepDetails(flowStep), apiResponse: flowResp };
      })
    );
  }

  getFAIntroFlow() : OnboardingFaIntro[] {
    return [
      {
        cta: 'Continue',
        image: 'fa_intro_1',
        ctaPath: 'forward',
        key: 'fa_intro_1',
        title: 'Meet your Financial Assistant',
        textBody: 'A dedicated financial expert to work side by side with you whenever you need it.',
        analytics: {}
      },
      {
        cta: 'Continue',
        image: 'fa_intro_2',
        ctaPath: 'forward',
        secondaryCtaPath: 'back',
        key: 'fa_intro_2',
        title: 'Get immediate help',
        textBody: 'Your Financial Assistant will explore your best options for urgent needs.',
        analytics: {}
      },
      {
        cta: 'I am ready',
        image: 'fa_intro_3',
        ctaPath: 'complete',
        secondaryCtaPath: 'back',
        key: 'fa_intro_3',
        title: 'Tailor your financial goals',
        textBody: 'They can help you create and progress towards your financial goals.',
        analytics: {}
      }
    ];
  }

}
