import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { API, Cache } from 'aws-amplify';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MicroCoreApiCacheService {
  constructor() {}

  get<T>(api: string, key: string, init?: { [key: string]: any }, extendCacheSecondsBy: number = 0): Observable<T> {
    const cached = Cache.getItem(key);
    if (cached) {
      return of(cached);
    } else {
      return from(API.get(api, key, init)).pipe(
        tap((response) => {
          Cache.setItem(key, response, {
            expires: moment()
              .add(30 + extendCacheSecondsBy, 'seconds')
              .valueOf(),
          });
        })
      );
    }
  }

  //Instead of adding an ambiguous boolean value, added new method. If we switch params to not be so static we should keep
  //it to one method though
  getForced<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    //Remove cache before making API call
    Cache.removeItem(key);

    return this.get<T>(api, key, init);
  }

  getItem<T>(key: string): T {
    return Cache.getItem(key);
  }

  setItem(key: string, value: any) {
    Cache.setItem(key, value);
  }

  refreshItem(key: string) {
    Cache.removeItem(key);
  }
}
