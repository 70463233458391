import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'brightside-web-intro-faq',
  templateUrl: './intro-faq.component.html',
  styleUrls: ['./intro-faq.component.scss'],
})
export class IntroFaqComponent {
  @Output() dismissEvent = new EventEmitter();

  //Use the total count starting at 1.
  faqSectionCount = 8;
  faqSectionPrefix = 'SAVINGS_FAQ_';
  faqSectionDescPostfix = '_DESC';

  savingsFaqTranslationKeys: string[][] = [];

  constructor() {
    this.buildFaqSections();
  }

  private buildFaqSections() {
    //We start the index at 1 to make sure we build the labels staring
    //with 1, 2, 3 ...
    for (let i = 1; i <= this.faqSectionCount; i++) {
      this.savingsFaqTranslationKeys.push([
        `${this.faqSectionPrefix}${i}`,
        `${this.faqSectionPrefix}${i}${this.faqSectionDescPostfix}`,
      ]);
    }
  }

  dismiss() {
    this.dismissEvent.emit();
  }
}
