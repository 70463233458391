import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, Cache } from 'aws-amplify';
import { AxiosError } from 'axios';
import { ApiResponse, BasicComponentConfig } from '@brightside-web/desktop/data-access/shared';
import { from, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'brightside-web-verify-dob',
  templateUrl: './verify-dob.component.html',
  styleUrls: ['./verify-dob.component.css']
})
export class VerifyDobComponent implements OnInit, OnDestroy {
  verifyForm:FormGroup;
  showHrModal: boolean;
  processing: boolean;
  sub = new Subscription();
  private isRegistration: boolean;
  registerModal: boolean;

  config: BasicComponentConfig = new BasicComponentConfig({ fbPage: "verify_dob", fbCategory: "registration", fbEventName: "verify_dob"});

  constructor(private regSvc:RegistrationService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.verifyForm = new FormGroup({
      'dateOfBirth':
      new FormControl('', [
        Validators.required,
        Validators.pattern('(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d'),
        Validators.maxLength(10),
        Validators.minLength(10)
      ]),
      lastName:new FormControl(Cache.getItem('lastName')),
      last4ssn:new FormControl(Cache.getItem('last4ssn'))
    });
    this.sub.add(from(Auth.currentAuthenticatedUser()).subscribe(_ => {
    }, ()=>{
      this.isRegistration = true;
    }));
  }

  submit() {
    this.processing = true;
    //https://sourcegraph.insidebrightside.com/android/-/blob/BrightSide/app/src/main/java/com/androidapp/gobrightside/mobile/loginregister/view/VerifyIdentityActivity.java#L108:39
    this.sub.add(this.regSvc.forgotPasswordPrecheck({last_four_ssn:this.verifyForm.controls['last4ssn'].value,
      last_name:this.verifyForm.controls['lastName'].value, date_of_birth:moment(this.verifyForm.controls['dateOfBirth'].value, 'MM/DD/YYYY').format('YYYY-MM-DD')}, this.isRegistration).subscribe(resp=>{
        Cache.setItem('phoneNumber', resp.phoneNumber );
        Cache.setItem('recoveryToken', resp.recoveryToken);
        this.router.navigate(['../','reset-password'], {relativeTo:this.route})
      },
      (err: AxiosError) => {
        this.processing = false;
        // TODO when using angular 9 + ts 3.7 use optional chaining operator
        this.handleResponse(
          err.response && err.response.data && typeof err.response.data === 'object' ? err.response.data : {}
        );
      }));
  }

  handleResponse(resp:ApiResponse){
    if (resp.statusCode === 412){
      this.registerModal = true;
    } else {
      this.showHrModal = true;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  register(){
    this.router.navigate(['/registration','sign_up']);
  }

}
