export * from './content-util-map.enum';

export enum UiTemplateContentType {
  CDN_CONTENT = 'content.cdn-url',
  TITLE_TEXT = 'content.title-text',
  LIST_ITEM = 'content.list-item',
  GROUP_CELL = 'content.group-cell',
}

export enum UiTemplateContentFormType {
  SELECT_AMOUNT = 'content.form.select.amount',
  GROUP_RADIO = 'content.form.group.radio',
}

export enum UiTemplateContentGroupRadioDisplayStye {
  BLOCK = 'display.stye.block',
}
