import {Inject, Injectable} from '@angular/core';
import {
  TranslateService,
  TranslateStore,
  TranslateLoader,
  TranslateCompiler,
  TranslateParser,
  MissingTranslationHandler,
  USE_DEFAULT_LANG,
  USE_STORE, USE_EXTEND,
  DEFAULT_LANGUAGE, MissingTranslationHandlerParams
} from "@ngx-translate/core";
import {forkJoin, isObservable, Observable, of, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {Cache, Hub} from "aws-amplify";
import {CompanyService} from "./company.service";
import { TitleCasePipe } from "@angular/common";
import {FirebaseService} from "./firebase.service";

@Injectable({
  providedIn: 'root',
})
export class BrightsideTranslateService extends TranslateService implements TranslateService {

  private sub = new Subscription();

  _company: string;
  _companyDisplay: string;

  _hours: string;

  get hours(): string {
    if (!this._hours) {
      const cacheHours = Cache.getItem('FAHOURS');
      this._hours = cacheHours ?? 'Weekdays, 8 a.m. to 8 p.m. ET';
      if (!cacheHours) Cache.setItem('FAHOURS', this._hours);
    }
    return this._hours;
  }

  get companyDisplay(): string {
    return this._companyDisplay;
  }

  set companyDisplay(value: string) {
    if (value.toLowerCase() === 'mckesson') {
      this._companyDisplay = 'McKesson';
    } else if (value.toLowerCase() === 'airproducts') {
      this._companyDisplay = 'Air Products';
    } else {
      this._companyDisplay = this.titleCasePipe.transform(value.toLowerCase());
    }
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value.toUpperCase().split(' ').join('_');
    this.companyDisplay = value;
  }

  _supportNumber: string;

  get supportNumber(): string {
    return this._supportNumber;
  }

  set supportNumber(value: string) {
    this._supportNumber = value;
  }


  constructor(
    public store: TranslateStore,
    public currentLoader: TranslateLoader,
    public compiler: TranslateCompiler,
    public parser: TranslateParser,
    public missingTranslationHandler: MissingTranslationHandler,
    private companyService: CompanyService,
    @Inject(USE_DEFAULT_LANG) useDefaultLang: boolean = true,
    @Inject(USE_STORE) isolate: boolean = false,
    @Inject(USE_EXTEND) extend: boolean = false,
    @Inject(DEFAULT_LANGUAGE) defaultLanguage: string = 'en',
    private analytics: FirebaseService,
    private titleCasePipe: TitleCasePipe
  ) {
    super(store, currentLoader, compiler, parser, missingTranslationHandler, useDefaultLang, isolate, extend, defaultLanguage);
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value) {
            this.company = value;
          }
        }
      )
    );
    this.sub.add(
      this.companyService.supportNumber.subscribe(
        value => {
          if (value) {
            this.supportNumber = value;
          }
        }
      )
    );
  }

  public setLanguageAs(newLang: string) {
    //Only support en and es
    if (['en', 'es'].includes(newLang)) {
      this.use(newLang);
    }
  }

  public getParsedResult(translations: any, key: any, interpolateParams?: Object): any {

    let res: string | Observable<string> | undefined;
    if (key instanceof Array) {
      let result: any = {},
        observables: boolean = false;
      for (let k of key) {
        result[k] = this.getParsedResult(translations, k, interpolateParams);
        if (isObservable(result[k])) {
          observables = true;
        }
      }
      if (observables) {
        const sources = key.map(k => isObservable(result[k]) ? result[k] : of(result[k] as string));
        return forkJoin(sources).pipe(
          map((arr: Array<unknown>) => {
            const obj: any = {};
            arr.forEach((value: unknown, index: unknown) => {
              obj[key[index as number]] = value;
            });
            return obj;
          })
        );
      }
      return result;
    }
    const tempUpperCaseKey = key.toUpperCase();
    // try company key first
    if (this.company) {

      const companyKey = `${tempUpperCaseKey}.${this.company}`;

      if (translations) {
        res = this.parser.interpolate(this.parser.getValue(translations, companyKey), interpolateParams);
      }

      if (typeof res === "undefined" && this.defaultLang != null && this.defaultLang !== this.currentLang && true) {
        res = this.parser.interpolate(this.parser.getValue(this.translations[this.defaultLang], companyKey), interpolateParams);
      }
    }

    if (typeof res === "undefined") {
      res = this.parser.interpolate(this.parser.getValue(translations, tempUpperCaseKey), interpolateParams);
    }

    if (typeof res === "undefined" && this.defaultLang != null && this.defaultLang !== this.currentLang && true) {
      res = this.parser.interpolate(this.parser.getValue(this.translations[this.defaultLang], tempUpperCaseKey), interpolateParams);
    }

    if (typeof res === "undefined") {
      const params: MissingTranslationHandlerParams = {key, translateService: this};
      if (typeof interpolateParams !== 'undefined') {
        params.interpolateParams = interpolateParams;
      }
      res = this.missingTranslationHandler.handle(params);
    }

    // res = res === "undefined" ?? this.globalsReplacement(res);

    return typeof res !== "undefined" ? this.globalsReplacement(res) : key;
  }

  globalsReplacement(parsedString: string | Observable<string>) {
    if (typeof parsedString === 'string') {

      let newResp = parsedString;
      newResp = parsedString.replace(/\$COMPANY/gm, this.companyDisplay);
      newResp = newResp.replace(/\$HOURS/gm, this.hours);
      newResp = newResp.replace(/\$PHONE/gm, this.supportNumber);
      return newResp;
    } else {
      return parsedString;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
