<div class="mb-md">
  <h2 headline-semibold class="mb-xs">How often?</h2>
  <bw-radio
    id="frequencyEveryPaycheck"
    value="everyPaycheck"
    label="Every paycheck"
    name="selectedFormFrequency"
    [startSelected]="startThisSelected === 'everyPaycheck'"
    [radioList]="true"
    (radioClick)="handleRadioClicked($event.value)"
  ></bw-radio>
  <bw-radio
    id="frequencyEveryOtherPaycheck"
    value="alternatePaycheck"
    label="Every other paycheck"
    name="selectedFormFrequency"
    [startSelected]="startThisSelected === 'alternatePaycheck'"
    [radioList]="true"
    (radioClick)="handleRadioClicked($event.value)"
  ></bw-radio>
</div>
