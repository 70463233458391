<div class="full-width">
  <p text-large-semibold>Savings Projections (est.)</p>

  <div>
    <bw-cell *ngFor="let row of projectionRows" separatorType="full" contentLeft="true" rightLabelColor="--brand" [labelRight]="row.value | currency: 'USD':'symbol':'1.0-0'">
      <p content-left text-large-regular color="var(--secondary-text)">{{ row.label }}</p>
    </bw-cell>
  </div>

  <p text-small-regular class="secondary-text center mt-xs">
    Estimates may vary as reward rate is subject to change. You can change this amount once the account is setup.
  </p>
</div>
