import { Environment, OAuthEnvironment } from '@brightside-web/micro/core/environment';

const OAUTH_SETTINGS: OAuthEnvironment = {
  id: '7o6ghm341m8k52e9ooar4slpma',
  url: 'https://gobrightside.auth.us-west-2.amazoncognito.com',
  redirectBaseUrl: '.gobrightside.com/registration/sign_up',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone'],
  companies: ['unum'],
  config: {
    unum: {
      name: 'Unum',
      provider: 'Microsoft',
    },
  },
};

/**
 * PLEASE DO NOT ADD ANYTHING HERE THAT CAN BE FETCHED FROM FIREBASE REMOTE CONFIG
 * THIS SHOULD ONLY BE FOR INITIALIZING SERVICES THAT CANNOT DEPEND ON FRC BEING AVAILABLE YET
 */
export const env = {
  awsConnectSnippetId:
    'QVFJREFIZ2FmaHlLVlhzMXdOM213U09zSVJiNFhEdjVTNFI4YWdlakN5c2NqL2w4R2dINHczb3IwOFZJOEpyZ1haNE9JUmE0QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNSURLMEdOcld5cEpGd1JkdUFnRVFnQ3Znb2x6djRXN3RSQzhoRnBCNnJ6cWw5d1F0eUJacXAzTUpRWXNldDVqVjIrRVVaWXllVnlVaE9TN3A6Om1FZ1lYRUcxWWR5azUyVDN4bEhSNHpnTDI2c0o3ak53c3NPOERMN3dTL2JLZ3hHV2RiOTZXZUYyMGxYYnFaUGI3NFBNVVh1eXpDamFOQUNiYVFYT0Y0L1N6Rm4vY3RqMWdrVFgzeUxvUkUxL3M0cGNWcHNIYmFHdmkwWC95R1pwNHNHWXdXY3pGYnJDNHd2SHVGMU5mSUlpVXByTDFCMD0=',
  awsConnectWidgetId: '9aadd46f-a8f8-457a-853a-b5ebe59e23de',
  sprigId: 'Q1EPELlXcF',
  hostedDomain: 'gobrightside',
  amplitude: {
    apiKey: 'ae415196f49971fcdbe49870c2a2e936',
  },
  awsmobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_9sCNKRchg',
    aws_user_pools_web_client_id: '5ctikoeanan96semo363le4rd0',
    endpoints: {
      'api-mobile': {
        name: 'api-mobile',
        endpoint: 'https://api2.gobrightside.com/v1',
      },
      'api-mobile-noauth': {
        name: 'api-mobile-noauth',
        endpoint: 'https://api2.gobrightside.com/v1',
      },
      'cdn-mobile-noauth': {
        name: 'cdn-mobile-noauth',
        endpoint: 'https://cdn.gobrightside.com',
      },
    },
  },
  performance: {
    sentry: {
      dsn: '',
    },
  },
  crmUrl: 'https://brightsidebenefit.lightning.force.com/lightning/r/Contact/%1$s/view',
  cdnUrl: 'https://cdn.gobrightside.com/',
  firebase: {
    apiKey: 'AIzaSyDQ0ig6nM9ZHo_ZSfUSpcojgCyNxfaUgvo',
    authDomain: 'healhtydollar-android.firebaseapp.com',
    databaseURL: 'https://healhtydollar-android.firebaseio.com',
    projectId: 'healhtydollar-android',
    storageBucket: 'healhtydollar-android.appspot.com',
    messagingSenderId: '812435151064',
    appId: '1:812435151064:web:0a24b1f19f1c8233005fad',
    measurementId: 'G-6KZ5ZTH1T3',
  },
  intercomAppId: 't6b4cxfz',
  googleTagManagerId: 'GTM-PCSDF63',
  googleTrackingId: 'UA-113184142-9',
  production: true,
  oauth: OAUTH_SETTINGS,
  featureFlags: {},
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('package.json').version,
};
class EnvironmentImpl implements Environment {
  awsConnectSnippetId = env.awsConnectSnippetId;
  awsConnectWidgetId = env.awsConnectWidgetId;
  hostedDomain = env.hostedDomain;
  awsmobile = env.awsmobile;
  oauth = env.oauth;
  intercomAppId = env.intercomAppId;
  production = env.production;
  firebase = env.firebase;
  crmUrl = env.crmUrl;
  cdnUrl = env.cdnUrl;
  googleTagManagerId = env.googleTagManagerId;
  googleTrackingId = env.googleTrackingId;
  sprigId = env.sprigId;
  featureFlags = env.featureFlags;
  amplitude = env.amplitude;
  appVersion = env.appVersion;
  performance = env.performance;
}
export const environment = new EnvironmentImpl();
