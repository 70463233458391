import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType } from '@micro-ui/template/content';
import { GetUiTemplateActionRoute, UiTemplateFlyerDataBuilder, UiTemplateNavType } from '@micro-ui/template/page';

import { MessageBusEventUtil, UseThisForStandaloneOrThat } from '@micro-core/message-bus';

import {
  MicroContentMediaFor,
  MicroContestAnalyticsEventName,
  MicroContestRouteKey,
  MicroContestTranslationKey,
} from '../../model/contest.enum';

const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UseThisForStandaloneOrThat(UiTemplateNavType.CLOSE, UiTemplateNavType.NONE),
    header: {
      type: UiHeaderType.EYE_BROW,
      title: MicroContestTranslationKey.CONTEST_RESULT_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_RESULT_SUB_TITLE,
    },
    content: [
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          title: MicroContestTranslationKey.CONTEST_RESULT_EXTRA_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE,
        },
      },
    ],
    media: MicroContentMediaFor(MicroContestAnalyticsEventName.RESULT),
    cta1Label: MicroContestTranslationKey.CONTEST_RESULT_CTA1,
    viewName: MicroContestAnalyticsEventName.RESULT,
  },
  actions: {
    navLeftIcon: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeature),
    cta1: GetUiTemplateActionRoute.ROUTE_SAME_LEVEL([MicroContestRouteKey.REVEAL]),
  },
});

export default TemplateData;
