import {Injectable, NgZone} from '@angular/core';
import {Hub} from "aws-amplify";
import {FirebaseService, CampaignRoutingService, TopicRoutingService} from "@brightside-web/desktop/data-access/shared";
import {Router} from "@angular/router";
import {ToastService} from "@brightside/brightside-ui-services";

export enum MappingParamType {
  AUTO = 'auto',
  DEFAULT = 'default',
}

export interface MappingDetail {
  path: string;
  queryParams?: Record<string, string>;
  queryParamsType?: MappingParamType;
}

export interface RouteInfoInterface {
  mappingDetail: MappingDetail;
  queryString: string;
}

@Injectable({
  providedIn: 'root'
})
export class InternalLinkRoutingService {

  constructor(
    protected analytics: FirebaseService,
    private _ngZone: NgZone,
    private router: Router,
    private campaignRouting: CampaignRoutingService,
    private topicRouting:  TopicRoutingService,
    private toastSvc: ToastService
  ) { }

  public routeToLink(brightsideLink: string) {
    let routeInfo: RouteInfoInterface | undefined;

    if (brightsideLink.includes('gobrightside://topic')) {
      routeInfo = this.topicRouting.navigateToTopic(brightsideLink);
    } else {
      routeInfo = this.campaignRouting.navigateToCampaign(brightsideLink);
    }

    if (routeInfo) {
      this.routeMainAppTo(routeInfo.mappingDetail, routeInfo.queryString, brightsideLink)
    } else {
      this.toastError(brightsideLink);
    }
  }

  private toastError(brightsideLink: string) {
    // this.toastSvc.warn('Page could not be found',
    //   {
    //     key: "internalLinkNotFound"
    //   }
    // );
    // this.analytics.logEvent('error_shown', { 'error id': brightsideLink })
  }

  private routeMainAppTo(routeToOptions: MappingDetail, routeQueryToString: string, brightsideLink: string)  {
    const additionalOptions = {queryParams: {}};

    if (routeToOptions.queryParamsType === MappingParamType.AUTO && routeQueryToString) {
      const splitRouteQuery = routeQueryToString.split('&');

      additionalOptions.queryParams = splitRouteQuery.reduce((finalParams: { [key: string]: string }, paramValue: string) => {
        const brokenValue = paramValue.split('=');

        finalParams[brokenValue[0]] = brokenValue[1];

        return finalParams;
      }, {});
    }

    this._ngZone.run(() => {
      this.router.navigate(routeToOptions.path.split('/'), additionalOptions)
        .catch(reason => {
          this.toastError(brightsideLink);
        })
        .finally(() => {
        Hub.dispatch('IntercomChannel', {event: 'close'});
      });
    });
  };

}
