import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit, Output,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { TitleCasePipe } from "@angular/common";
import { CompanyService } from "@brightside-web/desktop/data-access/shared";
import {Subscription} from "rxjs";

@Component({
  selector: 'brightside-web-how-we-help',
  templateUrl: './how-we-help.component.html',
  styleUrls: ['./how-we-help.component.scss'],
  providers: [TitleCasePipe]
})
export class HowWeHelpComponent implements OnInit, OnDestroy, AfterViewInit {

  private sub = new Subscription();

  company: string;

  @Input() heading: string;
  @Input() bodyCopy: string;
  @Input() iconGroupTitle: string;
  @Output() introVideoClicked: EventEmitter<any> = new EventEmitter<any>()

  showIntroVid: ElementRef;

  constructor(
    private titleCase: TitleCasePipe,
    private companyService: CompanyService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          this.company = this.titleCase.transform(value);
        }
      )
    )
  }

  ngAfterViewInit() {
    this.showIntroVid = this.el.nativeElement.querySelector('.showIntroVid');
    if (this.showIntroVid) {
      this.renderer.listen(this.showIntroVid, 'click', (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        this.introVideoClicked.emit(true);
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
