import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router,
} from '@angular/router';
import {Observable, of,} from 'rxjs';
import { Hub } from 'aws-amplify';
import { map } from 'rxjs/operators';
import {MobileStateService} from "@brightside-web/desktop/data-access/shared";

@Injectable({
  providedIn: 'root',
})
export class OnboardingCompleteGuard implements CanActivate, CanActivateChild, CanLoad {

  hasOnboardingCompleted: boolean;

  constructor(private mobileStateService: MobileStateService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getOnboardingCompleteFromMS(state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getOnboardingCompleteFromMS();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.getOnboardingCompleteFromMS();
  }

  getOnboardingCompleteFromMS(state?:RouterStateSnapshot) : Observable<boolean> {
    let byPassRedirect = false;
    if (state && state.url.indexOf('creditScore') > -1) {
     byPassRedirect = true;
    }

    if (this.hasOnboardingCompleted) {
      Hub.dispatch('OnboardingChannel', { event: 'OnboardingComplete', message: 'cleared guard' });
      return of(true);
    }

    return this.mobileStateService.get().pipe(
      map( (value => {
        if (value.onboardingComplete) {
          this.hasOnboardingCompleted = true;
          Hub.dispatch('OnboardingChannel', { event: 'OnboardingComplete', message: 'cleared guard' });
          return true;
        } else if (value.redirect === "onboarding" && !byPassRedirect ) {
          this.router.navigate(['onboarding']);
          return false;
        }
        return true;
      }))
    );
  }

}
