<div #cellContainer class="cell-container" (click)="cellClicked()" [ngClass]="{clickable: _isClickable}">
  <div class="cell-inner-container">
    <div class="inner-left">
      <!-- TODO CE-6454 maybe there's a cleaner way to pick icons -->
      <i [ngClass]="'pi '+iconNameLeft" *ngIf="iconNameLeft && iconNameLeft.startsWith('pi-')"></i>
      <div class="logo" *ngIf="iconNameLeft && iconNameLeft.startsWith('http')">
        <img  [attr.src]="iconNameLeft" alt="logo"/>
      </div>
      <bw-icon *ngIf="iconNameLeft && !iconNameLeft.startsWith('pi-') && !iconNameLeft.startsWith('http')" [iconName]="iconNameLeft" size="24" [horizontalSpace]="'mr-sm'"></bw-icon>
      <div class="cell-label-container" *ngIf="labelLeft" [ngClass]="{'flip-labels': flipLeftLabels}">
        <p *ngIf="labelLeft" text-large-semibold class="truncate-line" [mwTextColor]="'var('+leftLabelColor+')'">{{labelLeft}}</p>
        <p *ngIf="labelSubLeft" text-small-regular class="truncate-line" mwTextColor="var(--secondary-text)">{{labelSubLeft}}</p>
      </div>
      <div class="cell-label-container" *ngIf="contentLeft || contentSubLeft">
        <ng-content *ngIf="contentLeft" select="[content-left]"></ng-content>
        <ng-content *ngIf="contentSubLeft" select="[content-sub-left]"></ng-content>
      </div>
      <div class="cell-label-container" *ngIf="singleLeftLabel">
        <p text-large-regular class="truncate-line" [mwTextColor]="'var('+leftLabelColor+')'" >{{singleLeftLabel}}</p>
      </div>
    </div>
    <div class="inner-right" *ngIf="contentRight || labelRight || labelSubRight || iconNameRight || showToggleControl">
      <div class="cell-label-container pull-right">
        <p *ngIf="!showToggleControl && labelRight" text-large-regular [mwTextColor]="'var('+rightLabelColor+')'">{{labelRight}}</p>
        <p *ngIf="!showToggleControl && labelSubRight" text-small-regular>{{labelSubRight}}</p>
        <bw-checkbox *ngIf="showToggleControl"></bw-checkbox>
        <ng-content *ngIf="contentRight" select="[content-right]"></ng-content>
      </div>
      <div style="margin-top: 8px;">
        <i [ngClass]="'pi '+iconNameRight" *ngIf="iconNameRight && iconNameRight.startsWith('pi-')"></i>
        <bw-icon *ngIf="iconNameRight && !iconNameRight.startsWith('pi-')" [iconName]="iconNameRight" [horizontalSpace]="'mr-xxs'" size="24"></bw-icon>
      </div>
    </div>
  </div>

  <hr class="cell-bottom-bar" [ngClass]="getSeparatorClassName()" />
</div>
