import { MessageBusInternalService } from '../internal/message-bus-internal.service';

import {
  StandardEvent_EnterExternalAccountConnect as enterExternalAccountConnect,
  StandardEvent_ExitFeature as exitFeature,
  StandardEvent_ExitFeatureToChat as exitFeatureToChat,
  StandardEvent_ExitFeatureToSavingsHome as exitFeatureToSavingsHome,
  StandardEvent_HideLoader as hideLoadingSpinner,
  StandardEvent_PageLoaded as pageLoaded,
  StandardEvent_ShowLoader as showLoadingSpinner,
} from '../event/standard-events';

import { BuildForPathAndReturnStandardEvent as forPathAndReturnStandardEvent } from '../event/builder-events';
import { BuildHalfsheetConfigAndReturnStandardEvent as halfsheetConfigAndReturnStandardEvent } from '../event/builder/halfsheet-builder.event';

const standard = {
  enterExternalAccountConnect,
  exitFeature,
  exitFeatureToChat,
  exitFeatureToSavingsHome,
  hideLoadingSpinner,
  pageLoaded,
  showLoadingSpinner,
};
const builder = { forPathAndReturnStandardEvent, halfsheetConfigAndReturnStandardEvent };

/**
 * MessageBusEventUtil contains pre-built events, builders for events, and reference to
 * the outgoing sender.
 *
 * event.standard - Pre-built events like exitFeature
 * events.builder - Builders like path navigation forPathAndReturnStandardEvent
 *
 * sendOutgoing(<MessageBusInternalHubEvent>) - Sends an event to the outgoing message bus
 */
const MessageBusEventUtil = { event: { standard, builder }, sendOutgoing: MessageBusInternalService.sendOutgoingHubEvent };

export { MessageBusEventUtil };
