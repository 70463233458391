export * from './analytics/analytics.enum';
export * from './form/form.enum';
export * from './i18n/i18n.enum';
export * from './route/route.enum';

import { UiMoleculeMediaType, UiMoleculeMedia } from '@micro-ui/molecule';

import { MicroContestAnalyticsEventName } from './analytics/analytics.enum';

const MicroContentImageName: {
  [key: string]: UiMoleculeMedia[];
} = {};

MicroContentImageName[MicroContestAnalyticsEventName.ENTRY_INTRO] = [
  {
    type: UiMoleculeMediaType.IMAGE,
    name: 'contest/intro.svg',
  },
];

MicroContentImageName[MicroContestAnalyticsEventName.ENTRY_COMPLETE] = [
  {
    type: UiMoleculeMediaType.IMAGE,
    name: 'contest/confirmation.svg',
  },
];

MicroContentImageName[MicroContestAnalyticsEventName.RESULT] = [
  {
    type: UiMoleculeMediaType.IMAGE,
    name: 'contest/reveal-result.svg',
  },
];

MicroContentImageName[MicroContestAnalyticsEventName.RESULT_WON] = [
  {
    type: UiMoleculeMediaType.ANIMATION,
    name: 'contest/reveal-result-won.gif',
  },
  {
    type: UiMoleculeMediaType.IMAGE,
    name: 'contest/reveal-result-won.svg',
  },
];

MicroContentImageName[MicroContestAnalyticsEventName.RESULT_LOST] = [
  {
    type: UiMoleculeMediaType.IMAGE,
    name: 'contest/reveal-result-non-winner.svg',
  },
];

/**
 * Use this helper to get the image information for your view name
 *
 * @param viewName <MicroContestAnalyticsEventName>
 * @returns [] | UiTemplateFlyerMedia[]
 */
const MicroContentMediaFor = (viewName: string) => MicroContentImageName[viewName] || [];

export { MicroContentMediaFor };
