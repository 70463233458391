import { UiMoleculeIcon } from '../shared/shared.enum';

export enum UiHeaderType {
  DEFAULT = 'default',
  EYE_BROW = 'eyeBrow',
}

export interface UiHeaderDefaultInputs {
  type: UiHeaderType;
  title: string;
  subTitle?: string;
  icon?: UiMoleculeIcon | string;
}

export interface UiHeaderEyebrowInputs {
  type: UiHeaderType;
  title: string;
  subTitle?: string;
}
