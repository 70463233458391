import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MessageBusInternalService, MessageBusEventUtil } from '@micro-core/message-bus';
import { MicroRouteFindDataByKey, MicroUtilTranslationService } from '@micro-core/utility';
import { UiTemplateFlyerInterface } from '@micro-ui/template/page';

import { MicroContestRouteDataKey, MicroContestTranslationKey } from '../../../model/contest.enum';
import { MicroContestAppResponse } from '../../../service/micro-contest-app.service';

type DynamicTranslation = Partial<Record<MicroContestTranslationKey, string>>;

@Injectable()
export class MicroContestEntryIntroResolver implements Resolve<UiTemplateFlyerInterface> {
  constructor(private currencyPipe: CurrencyPipe, private utilTranslationService: MicroUtilTranslationService) {}

  private getDynamicTranslations(routeSnapshot: ActivatedRouteSnapshot): DynamicTranslation {
    const contestDetails: MicroContestAppResponse | undefined = MicroRouteFindDataByKey<MicroContestAppResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_RESULTS
    );

    //If there are not details, we should start routing away
    if (contestDetails === undefined) {
      MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeature);
      return {};
    }

    if (contestDetails.data?.isClientParticipating) {
      MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeatureToSavingsHome);
      return {};
    }

    return {
      [MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE]: this.utilTranslationService.instant(
        MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE,
        {
          prizeAmount: `${this.currencyPipe.transform(
            contestDetails.data?.messageValues.prizeAmount || 0,
            'USD',
            'symbol',
            '1.0'
          )}`,
        }
      ),
      [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE]: this.utilTranslationService.instant(
        MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE,
        {
          prizeAmount: `${this.currencyPipe.transform(
            contestDetails.data?.messageValues.prizeAmount || 0,
            'USD',
            'symbol',
            '1.0'
          )}`,
        }
      ),
    };
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const templateData: UiTemplateFlyerInterface = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
    const dynamicTranslatedValues = this.getDynamicTranslations(routeSnapshot);
    const returnTemplateData: UiTemplateFlyerInterface = {
      ...templateData,
      inputs: {
        ...this.utilTranslationService.deepCopyKeySwapForDynamicValue(templateData.inputs, dynamicTranslatedValues),
      },
    };

    return returnTemplateData;
  }
}
