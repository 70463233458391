import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'bw-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  createPassword: FormGroup;
  displayRules: boolean;

  @Input() label: string;
  @Input() placeholder = '';
  @Input() fcName = 'password';
  @Input() autoFocus:boolean;

  constructor() {
    this.createPassword = new FormGroup({});
  }

  ngOnInit(): void {
    this.createPassword.addControl(this.fcName, new FormControl('',
      [Validators.required, Validators.minLength(8), Validators.maxLength(24), hasCapital(), hasDigit(), has8To24NoSpaces()]));
  }

  shouldDisplayRules(isFocused: boolean) {
    if (isFocused) {
      this.displayRules = true;
    } else if (!this.createPassword.invalid || this.createPassword.pristine) {
      this.displayRules = false;
    }
  }
}

export const hasCapital = (): ValidatorFn =>
   (control: AbstractControl): { [key: string]: any } | null =>
     control.value.match(/^(?=.*[A-Z])/) ? null : { capitalLetterUnsatisfied: true };



export const hasDigit = (): ValidatorFn =>
   (control: AbstractControl): { [key: string]: any } | null =>
     control.value.match(/^(?=.*\d)/) ? null : { atLeastOneNumberUnsatisfied: true };



export const has8To24NoSpaces = (): ValidatorFn =>
   (control: AbstractControl): { [key: string]: any } | null =>
     control.value.match(/^\S{8,24}$/)
      ? null
      : { characterAmountNoSpacesUnsatisfied: true };


