import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AutosaveComponentConfig,
  AutosaveMode,
  FirebaseService,
  SavingsAccount,
  SavingsPayrollScheduleResponse,
  SavingsSchedulePatchAction,
  SavingsSchedulePatchRequest,
  SavingsScheduleResponse,
  SavingsScheduleType,
  SavingsSource,
} from '@brightside-web/desktop/data-access/shared';
import { RoutingStateService, ToastService } from '@brightside/brightside-ui-services';
import { SavingsAccountService, SavingsPayrollService } from '@brightside-web/desktop/data-access/savings';

import { Hub } from 'aws-amplify';
import { Subscription } from 'rxjs';

import * as moment from 'moment-business-days';

const DEFAULT_CONFIG = new AutosaveComponentConfig({
  fbPage: '',
  fbCategory: '',
  fbEventName: '',
  mode: AutosaveMode.EDIT,

  pageAutosaveAmount: 10,
  pageStepperActiveIndex: -1,
  pageStepperActiveIndexForSkipped: -1,
  pageOnBackCtaPath: ['home'],
  pageOnForwardCtaPath: ['home'],
});

@Component({
  selector: 'brightside-web-autosave-edit',
  templateUrl: './autosave-edit.component.html',
})
export class AutosaveEditComponent implements OnInit, OnDestroy {
  private sub = new Subscription();

  private hasTransferToSource = false;
  hasCurrentAutosaveSchedule = false;

  isCurrentlyPaused = false;

  processing = false;

  config: AutosaveComponentConfig = DEFAULT_CONFIG;
  brightsideWebFbBase = { page: '', category: '' };

  pageTitle = 'Autosave';
  pageCtaLabel = 'Save changes';
  pageSecondaryCtaLabel = '';
  pageStepperItems: any[] = [{}, {}, {}, {}];

  autosaveForm = this.formBuilder.group({
    selectedAmount: [this.config.pageAutosaveAmount, [Validators.required, Validators.min(1), Validators.max(9999.99)]],
    selectedFormFrequency: [SavingsScheduleType.everyPaycheck, [Validators.required]],
  });

  account: SavingsAccount;
  scheduleList: string[];

  showStepper = false;
  showConfirm = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routingState: RoutingStateService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private savingsService: SavingsAccountService,
    protected analytics: FirebaseService,
    private payrollService: SavingsPayrollService
  ) {}

  ngOnInit() {
    this.setUpHub();
    this.checkForConfigViaData();

    this.subscribeAndLoadSavings();
    this.subscribeAndLoadSchedule();

    this.autosaveForm.valueChanges.subscribe(() => {});
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get pageSubTitle() {
    return `Any changes you make will be reflected on ${this.getExpectedReflectionDate()}`;
  }

  get selectedAmount() {
    return this.autosaveForm.get('selectedAmount');
  }

  get selectedFormFrequency() {
    return this.autosaveForm.get('selectedFormFrequency');
  }

  private setUpHub() {
    Hub.listen('ErrorChannel', () => {
      this.createOrUpdateAutosaveSchedule();
    });
  }

  private checkForConfigViaData() {
    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new AutosaveComponentConfig(this.activatedRoute.snapshot.data.pageConfig);

      this.brightsideWebFbBase.page = this.config.fbPage;
      this.brightsideWebFbBase.category = this.config.fbCategory;

      this.selectedAmount?.setValue(this.config.pageAutosaveAmount || 10);
      this.showStepper = (this.config.pageStepperActiveIndex || -1) >= 0;
    } else {
      console.log('No configs have been passed in the DATA of the route.');
    }
  }

  private subscribeAndLoadSavings() {
    const handleSuccess = (account?: SavingsAccount) => {
      this.account = account as SavingsAccount; // assume parent component has guard enforcing savings account exists

      //Side affects
      this.checkForFrequency();
      this.checkForPausedSchedule();
      this.checkAndSetUpForCurrentAutosaveSchedule();
      this.checkForLinkedBankOrSpendingAccount();
    };
    const handleFailure = () => {};

    this.sub.add(this.savingsService.getSavingsAccount().subscribe(handleSuccess, handleFailure));
  }

  private subscribeAndLoadSchedule() {
    const handleSuccess = (response: SavingsPayrollScheduleResponse) => {
      this.scheduleList = response.schedule;
    };
    const handleFailure = () => {
      this.scheduleList = [];
    };

    this.sub.add(this.payrollService.getSchedule().subscribe(handleSuccess, handleFailure));
  }

  private createOrUpdateAutosaveSchedule() {
    const handleSuccess = (response: SavingsScheduleResponse) => {
      if (response.statusCode >= 400) {
        handleFailure();

        return false;
      }

      // We do this to skip linked account stage
      if (this.hasTransferToSource) {
        this.handleRouteBackward(true);
      } else {
        this.handleRouteForward();
      }
    };
    const handleFailure = () => {
      this.processing = false;
      this.analytics.logEvent('error_shown', { 'error id': 'autosave_schedule' });
      this.toastService.error('Looks like something went wrong saving your autosave information.', {
        link: 'Retry',
        transient: false,
      });
    };

    this.processing = true;
    this.savingsService.updateSchedule(this.getRequestBody()).subscribe(handleSuccess, handleFailure);
  }

  private checkForFrequency() {
    if (this.account.schedule_type) {
      this.selectedFormFrequency?.setValue(this.account.schedule_type);
    }
  }

  private checkForPausedSchedule() {
    this.isCurrentlyPaused = !!this.account.schedule_paused;
  }

  private checkForLinkedBankOrSpendingAccount() {
    this.hasTransferToSource = SavingsAccountService.isLinkedBankPresent(this.account);
  }

  private checkAndSetUpForCurrentAutosaveSchedule() {
    if (this.account.next_deposit_amount) {
      this.pageSecondaryCtaLabel = '';
      this.hasCurrentAutosaveSchedule = true;
      this.selectedAmount?.setValue(this.account.next_deposit_amount);
    }
  }

  private getRequestBody(): SavingsSchedulePatchRequest {
    const requestBody: SavingsSchedulePatchRequest = {
      schedule_type: this.selectedFormFrequency?.value,
      next_transfer_date: this.scheduleList[0] || '',
      amount: this.selectedAmount?.value,
      source: this.account.source || SavingsSource.payroll,
      action: SavingsSchedulePatchAction.UPDATE,
    };

    // we only support payroll at this time
    // if (this.account.source === 'payroll') {
    //   requestBody.schedule_type = this.selectedFormFrequency?.value;
    // } else if (this.account.source === 'ach') {
    //   requestBody.schedule_type = 'biweekly';
    // }

    return requestBody;
  }

  private getExpectedReflectionDate() {
    if (!this.scheduleList && !this.account) {
      return '';
    }

    return moment(this.account.next_deposit_date || this.scheduleList[0]).format('MMMM Do, yyyy');
  }

  private handleRoute(popHistory: boolean, routeHere: string[]) {
    if (popHistory) {
      this.routingState.popAndNavigateTo(routeHere);
    } else {
      this.router.navigate(routeHere);
    }
  }

  private handleRouteBackward(popHistory?: boolean, overrideRoute?: string[]) {
    const routeHere = overrideRoute || this.config.pageOnBackCtaPath;

    this.handleRoute(popHistory || false, routeHere);
  }

  private handleRouteForward(popHistory?: boolean, overrideRoute?: string[]) {
    const routeHere = overrideRoute || this.config.pageOnForwardCtaPath;

    this.handleRoute(popHistory || false, routeHere);
  }

  getFbInfo(extendWith?: any) {
    return { ...this.brightsideWebFbBase, ...extendWith };
  }

  handlePauseStatusChange(statusDetail: { status: string }) {
    this.isCurrentlyPaused = statusDetail.status === 'pause';
  }

  handleBackNavigation() {
    this.handleRouteBackward(true);

    // Needed some bw-back does not navigate for us
    return false;
  }

  handleSecondaryCtaClick() {
    // We do this to skip linked account stage
    if (this.hasTransferToSource) {
      this.handleRouteBackward(true);
    } else {
      this.handleRouteForward();
    }
  }

  handleCtaClick() {
    if (this.processing) {
      return;
    }

    // Make sure we don't move save and move forward if values did not change
    if (
      this.selectedAmount?.value === this.account.next_deposit_amount &&
      this.selectedFormFrequency?.value === this.account.schedule_type
    ) {
      this.handleRouteForward();
      return;
    }

    this.createOrUpdateAutosaveSchedule();
  }

  checkForConfirm() {
    if (this.selectedAmount?.value > 199){
      this.showConfirm = true;
    } else {
      this.handleCtaClick();
    }
  }
}
