import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { UiTemplateContentFormType, UiTemplateContentSelectAmount, UiTemplateFormContent } from '@micro-ui/template/content';
import { UiTemplateFormFlyerInterface, UiTemplateStateHandlerService } from '@micro-ui/template/page';

import {
  MicroContestFormControlKey,
  MicroContestRouteDataKey,
  MicroContestRouteModalKey,
  MicroContestRouteParamKey,
} from '../../../model/contest.enum';
import { MicroContestDetail } from '../../../model/contest.interface';

@Injectable({ providedIn: 'root' })
export class MicroContestEntryAutosaveCustomModalResolver implements Resolve<MicroContestDetail | undefined> {
  //This state should be tied to the state being used in the parent component
  constructor(protected uiTemplateStateHandlerService: UiTemplateStateHandlerService) {}

  /**
   * Another mess... but not sure the right way to do this. Maybe placeholders like we do in
   * lang stuff that can be replaced with that unil method?
   *
   * Checks
   * 1 - First make sure we have modal data in parent template data
   * 2 - Make sure we have the right modal data and form content inside of it
   * 3 - Go through the content and look for matching types/keys
   * 4 - If matched, adjust the initial value of the modal data
   */
  adjustFormContent(templateData: UiTemplateFormFlyerInterface) {
    const state = this.uiTemplateStateHandlerService.getState();

    if (state?.state_child_modal && state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM]) {
      if (
        templateData.modals &&
        templateData.modals[MicroContestRouteModalKey.AUTOSAVE_CUSTOM] &&
        (templateData.modals[MicroContestRouteModalKey.AUTOSAVE_CUSTOM].data as UiTemplateFormFlyerInterface).inputs?.formContent
      ) {
        (
          templateData.modals[MicroContestRouteModalKey.AUTOSAVE_CUSTOM].data as UiTemplateFormFlyerInterface
        ).inputs.formContent?.forEach((details: UiTemplateFormContent) => {
          //If we are the select type then we can check if id matches
          if (
            state.state_child_modal &&
            details.type === UiTemplateContentFormType.SELECT_AMOUNT &&
            details.data.groupName === MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM
          ) {
            (details.data as UiTemplateContentSelectAmount).value = Number(
              state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM]
            );
          }
        });
      }
    }
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    /**
     * Unblock this if we need contest id
     *
     * const contestId: string =
     * routeSnapshot.queryParams[MicroContestRouteParamKey.CONTEST_ID] ||
     * routeSnapshot.params[MicroContestRouteParamKey.CONTEST_ID] ||
     * '';
     */
    const modalId: MicroContestRouteModalKey =
      routeSnapshot.queryParams[MicroContestRouteParamKey.MODAL] || MicroContestRouteModalKey.NONE;

    if (modalId !== MicroContestRouteModalKey.AUTOSAVE_CUSTOM) {
      console.log('Wrong modal!!');
      return undefined;
    }

    this.adjustFormContent(routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {});

    //We don't need any special data but if we did... it could be passed down via this
    //Maybe there is chance to use this as standard "override" tool
    return undefined;
  }
}
