import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsAttributesV4} from "@brightside-web/desktop/data-access/shared";
import {MenuItem} from "@brightside/brightside-ui";

@Component({
  selector: 'brightside-web-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
  @Input() pageStepperActiveIndex = -1;
  @Input() pageOnBackCtaPath: string[] = [];
  @Input() pageOnForwardCtaPath: string[] = [];

  @Input() fbPage = '';
  @Input() fbCategory = '';
  @Input() fbEventName = '';
  fbShownEventObject: AnalyticsAttributesV4 = { event_name: ''};
  fbTappedEventObject: AnalyticsAttributesV4 = { event_name: ''};
  fbDismissedEventObject: AnalyticsAttributesV4 = { event_name: ''};
  pageStepperItems:MenuItem[] = [{},{},{},{}];
  processing=false;
  faqVisible=false;

  constructor(private router:Router,
    private activatedRoute: ActivatedRoute
  ) {
    const data = this.activatedRoute.snapshot.data;
    this.pageStepperActiveIndex = data.pageStepperActiveIndex;
    this.pageOnForwardCtaPath = data.pageOnForwardCtaPath;
    this.fbPage = data.fbPage ? data.fbPage : '';
    this.fbCategory = data.fbCategory ? data.fbCategory : '';
    this.fbEventName = data.fbEventName ? data.fbEventName : '';

    if (this.fbEventName !== '') {
      this.fbShownEventObject = { event_name: this.fbEventName + '_shown' };
      this.fbTappedEventObject = { event_name: this.fbEventName + '_tapped' };
      this.fbDismissedEventObject = { event_name: this.fbEventName + '_dismissed' };
    }
  }

  handleCtaClick() {
    this.router.navigate(this.pageOnForwardCtaPath);
  }
}
