import { Component, Input, Output, ElementRef, EventEmitter, forwardRef, ViewChild, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioComponent),
  multi: true,
};

@Component({
  selector: 'bw-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [RADIO_VALUE_ACCESSOR],
})
export class RadioComponent implements OnInit, ControlValueAccessor {
  /** The radio control value */
  @Input() value: any;

  /** The name attribute that is used to associate the radio control with a radio group */
  @Input() name: string;

  /** Whether the input is disabled */
  @Input() disabled: boolean;

  /** The HTML label element for the radio control */
  @Input() label: string;

  /** The text line that appears above the label */
  @Input() preLabel: string;

  /** The text line that appears below the label */
  @Input() postLabel: string;

  /** The id of the input, used to associate the radio control with its label tag  */
  @Input() inputId: string;
  @Input() tabindex: number;
  @Input() startSelected = false;
  @Input() radioList = false;
  @Input() radioBlock = false;

  @Output() radioClick: EventEmitter<any> = new EventEmitter();
  @Output() radioFocus: EventEmitter<any> = new EventEmitter();
  @Output() radioBlur: EventEmitter<any> = new EventEmitter();

  @ViewChild('radio', { static: true }) inputViewChild: ElementRef;

  isBoldLabel = false;

  public checked: boolean;
  public focused: boolean;
  public onModelChange: Function = () => {};
  public onModelTouched: Function = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if(this.startSelected) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
    }
    if (this.radioBlock && this.postLabel) {
      this.isBoldLabel = true;
    }
  }

  handleClick(event: { preventDefault: () => void }, radioButton: { focus: () => void }, focus: any) {
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.select(event);

    if (focus) {
      radioButton.focus();
    }
  }

  select(event: any) {
    if (!this.disabled) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.radioClick.emit({ event, value: this.value });
    }
  }

  writeValue(value: any): void {
    this.checked = value === this.value;

    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.checked = this.checked;
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  onRadioFocus(event: any) {
    this.focused = true;
    this.radioFocus.emit(event);
  }

  onRadioBlur(event: any) {
    this.focused = false;
    this.onModelTouched();
    this.radioBlur.emit(event);
  }

  onChange(event: any) {
    this.select(event);
  }
}
