import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';

import {
  UiHeaderDefaultInputs,
  UiHeaderEyebrowInputs,
  UiFooterType,
  UiFooterDefaultData,
  UiMoleculeMediaType,
  UiMoleculeMedia,
} from '@micro-ui/molecule';
import { UiTemplateContent } from '@micro-ui/template/content';

import { UiTemplateStateComponent, UiTemplateStateInterface } from '../shared/state-page-template.component';

import { UiTemplateNavType, UiTemplateAction } from '../shared/model/page-template.enum';
import { UiTemplateBaseActions, UiTemplateBaseInputs } from '../shared/model/page-template.interface';

import { UiTemplateActionHandler } from '../service/action-handler/action-handler.shared';
import { PageTransitionAnimationTriggers } from '../util/page-transition/page-transition.component';

export interface UiTemplateFlyerInterface extends UiTemplateStateInterface {
  inputs: UiTemplateFlyerInputs;
  modals?: { [key: string]: UiTemplateFlyerModal };
  actions: UiTemplateFlyerActions;
}

export interface UiTemplateFlyerInputs extends UiTemplateBaseInputs {
  content?: UiTemplateContent[];
  header?: UiHeaderDefaultInputs | UiHeaderEyebrowInputs;
  media?: UiTemplateFlyerMedia[];
  viewName: string;
}

export interface UiTemplateFlyerModal {
  modalId: string;
  data: UiTemplateFlyerInterface;
}

export type UiTemplateFlyerActions = UiTemplateBaseActions;
export type UiTemplateFlyerMedia = UiMoleculeMedia;

export const UiTemplateFlyerMediaType = UiMoleculeMediaType;

@Component({
  animations: PageTransitionAnimationTriggers,
  selector: 'ui-page-flyer',
  templateUrl: './flyer-page-template.component.html',
  styleUrls: ['./flyer-page-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiTemplateFlyerComponent extends UiTemplateStateComponent implements OnDestroy {
  @Input() override data: UiTemplateFlyerInterface;
  @Input() override logPrefix = 'UiTemplateFlyer -';
  @Input() override allowVerboseDebugMode = false;

  animationTriggerName = 'pageEnterFromRight';

  //Make sure the nav types can be used in template
  navType = UiTemplateNavType;
  action = UiTemplateAction;

  /**
   * The properties that began with fromData should not be set outside
   * templateDataChanged.
   */
  fromDataContent: UiTemplateContent[];
  fromDataFooter: UiFooterDefaultData;
  fromDataHeader: UiHeaderDefaultInputs | UiHeaderEyebrowInputs;
  fromDataMedia: UiTemplateFlyerMedia[];
  fromDataTrackingDetail = { event_name: '' };
  fromDataAction: UiTemplateActionHandler = new UiTemplateActionHandler();

  fromDataActiveModal: UiTemplateFlyerModal | undefined;

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.resetFromDataAction();
  }

  private resetFromDataAction() {
    //this.fromDataAction =
  }

  private updateActionsFromData() {
    this.fromDataAction.updateUiComponent(this);
    this.fromDataAction.updateActions(this.data?.actions || {});
  }

  private updateFooterFromData() {
    this.fromDataFooter = {
      inputs: {
        cta1Label: this.data?.inputs?.cta1Label || '',
        cta2Label: this.data?.inputs?.cta2Label || '',
        trackingDetail: this.fromDataTrackingDetail,
        link: this.data?.inputs?.link || '',
        infoText: this.data?.inputs?.infoText || '',
        type: UiFooterType.DEFAULT,
      },
    };
  }

  public queryParamsChanged(): void {
    super.queryParamsChanged();

    const targetModalId: string = this.activatedRoute.snapshot.queryParams['modal'] || '';

    //This is to clean out any modal if not there
    if (!targetModalId) {
      this.fromDataActiveModal = undefined;
      return;
    }

    if (!this.data.modals || !this.data.modals[targetModalId]) {
      console.warn('Eh, looks like there is a modal trying to load without any data in the templateData for it.');
      return;
    }

    this.fromDataActiveModal = this.data.modals[targetModalId];
    this.animationTriggerName = 'pageStayPut';

    this.logForDebugging('queryParamsChanged - Modal Found', this.fromDataActiveModal.data);
  }

  public templateDataChanged(): void {
    super.templateDataChanged();

    if (!this.data?.inputs) {
      return;
    }

    this.fromDataTrackingDetail.event_name = this.data?.inputs?.viewName || 'flyer_template';

    if (this.data.inputs.content) {
      this.fromDataContent = this.data.inputs.content;
    }

    if (this.data.inputs.header) {
      this.fromDataHeader = this.data.inputs.header;
    }

    if (this.data.inputs.media) {
      this.fromDataMedia = this.data.inputs.media;
    }

    this.updateActionsFromData();
    this.updateFooterFromData();
  }

  public templateStateChanged(): void {
    super.templateStateChanged();
  }
}
