/**
 * Use this for references to single route path pieces. These
 * will be used in the module routing objects
 */
enum MicroContestRouteKey {
  AUTOSAVE = 'type-autosave',
  BASE = 'contest',
  BASE_RESULT = 'contest-reveal',
  BASE_PROMO = 'contest-promo',
  COMPLETE = 'complete',
  INTRO = 'intro',
  RESULT = 'result',
  REVEAL = 'reveal',
  RULES = 'rules',
  MORE_INFO = 'more-info',
}

/**
 * Use this for references to know param keys as needed for the route
 */
enum MicroContestRouteParamKey {
  CONTEST_ID = 'contestId',
  MODAL = 'modal',
}

/**
 * Keys for router data usage
 */
enum MicroContestRouteDataKey {
  REMOTE_CONFIG_CONTEST = 'remoteConfigContest',
  REMOTE_CONFIG = 'remoteConfig',
  RESPONSE_CONTEST_DETAIL = 'RESPONSE_CONTEST_DETAIL',
  RESPONSE_CONTEST_RESULTS = 'RESPONSE_CONTEST_RESULTS',
  RESPONSE_CONTESTS = 'RESPONSE_CONTESTS',
  DISPLAY_STYLE = 'appDisplayStyle',
  TEMPLATE = 'templateData',
}

/**
 * Keys for modal usage
 */
enum MicroContestRouteModalKey {
  NONE = '',
  AUTOSAVE_CONFIRMATION = 'modal.autosave.confirmation',
  AUTOSAVE_CUSTOM = 'modal.autosave.custom',
}

/**
 * Use this to reference full routing array for defined paths
 *
 * { [key: string]: (string|MicroContestRouteKey)[] }
 */
const MicroContestRoute = {
  /**
   * Route path is made with: MicroContestRouteKey.BASE, MicroContestRouteKey.INTRO, MicroContestRouteParamKey.CONTEST_ID, MicroContestRouteKey.COMPLETE
   */
  COMPLETE: [
    MicroContestRouteKey.BASE,
    MicroContestRouteKey.INTRO,
    MicroContestRouteParamKey.CONTEST_ID,
    MicroContestRouteKey.COMPLETE,
  ],
  /**
   * Route path is made with: MicroContestRouteKey.BASE_RESULT, MicroContestRouteKey.RESULT, MicroContestRouteParamKey.CONTEST_ID
   */
  RESULT: [MicroContestRouteKey.BASE_RESULT, MicroContestRouteKey.RESULT, MicroContestRouteParamKey.CONTEST_ID],
  /**
   * Route path is made with: MicroContestRouteKey.BASE_RESULT, MicroContestRouteKey.RESULT, MicroContestRouteParamKey.CONTEST_ID, MicroContestRouteKey.REVEAL
   */
  RESULT_REVEAL: [
    MicroContestRouteKey.BASE_RESULT,
    MicroContestRouteKey.RESULT,
    MicroContestRouteParamKey.CONTEST_ID,
    MicroContestRouteKey.REVEAL,
  ],
};

export {
  MicroContestRoute,
  MicroContestRouteDataKey,
  MicroContestRouteKey,
  MicroContestRouteModalKey,
  MicroContestRouteParamKey,
};
