import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  DesktopCard,
  DesktopSegmentType,
  MobileStateService,
} from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { LinkedAccountService } from '@brightside-web/desktop/data-access/account-linking';
import { ToastService } from '@brightside/brightside-ui-services';
import { ExternalSavingsService } from '@brightside-web/desktop/data-access/external-savings'
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavingsCardDataService {
  constructor(
    private toastService: ToastService,
    private savingsService: SavingsAccountService,
    private mobileStateService: MobileStateService,
    private currencyPipe: CurrencyPipe,
    private extSavingsService: ExternalSavingsService
  ) {}

  readonly BANNER_VERIFY_LINKING = {
    id: 'verifyLinkedAccount',
    link: 'Verify your bank account',
    transient: false,
  };

  private handleBannerSideAffect(message: string, options?: any) {
    this.toastService.infoBlue(message, options);
  }

  async groomCardData(startingData: DesktopCard): Promise<DesktopCard> {
    const mobilestate = await this.mobileStateService.get().toPromise();
    const savingsAccount = await this.savingsService.getSavingsAccount().toPromise();

      // Make sure we have a savings account before checking anything else

      if (savingsAccount || mobilestate.externalSavings) {
        startingData.segments?.forEach((segment) => {
          if (segment.type === DesktopSegmentType.DEFAULT) {
            forkJoin([this.extSavingsService.getSavingsTotalBalance(), this.extSavingsService.hasExternalSavingsAccount()]).subscribe(([totalBalRet, hasExtSavingsRet]) => {
              const totalBalance = totalBalRet;
              segment.dynamicValue = this.currencyPipe.transform(totalBalance) || '$0.00';
              if (hasExtSavingsRet) {
                segment.title = 'Total Balance';
              } else {
                segment.title = 'Brightside savings';
              }
            })
          }
        });
        if (savingsAccount) {
          const bankPendingLink = LinkedAccountService.getAccountIdPendingDeposits(savingsAccount);
          if (bankPendingLink) {
            //TODO if we ever allow linking more than one bank, the route would change from creation- to additional-
            this.handleBannerSideAffect('Verify your bank account', {
              ...this.BANNER_VERIFY_LINKING,
              route: ['home', 'savings', 'creation-linked-banks-verify', String(bankPendingLink)],
            }); // Need ' ' until the banner service handles chevron
          }
        }
      } else {
      startingData.footer = {
        leftImage: "alert",
        style: "Error",
        title: "An error has occurred"
      };
    }

    return startingData;
  }
}
