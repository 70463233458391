<bw-modal *ngIf="visible" modalWidth="medium" [showCloseButton]="true" (closeModal)="handleClick(false)">
  <typography title-medium color="var(--primary-text)" class="mb-sm">Connect your credit report</typography>
  <section class="body" [brightsideWebFb]="{ event_name: 'credit_details_shown' }">
    <bw-paragraph>
      <typography text-large-regular color="var(--secondary-text)">
        <p class="mb-xs">
          Access and share your Equifax credit report with Brightside to help your Financial Assistant connect you with the best
          solutions.
        </p>
        <p class="mb-xs">Accessing your Equifax credit report will not affect your credit score.</p>
        <p>
          You may disconnect your credit report by contacting your Financial Assistant or from within the credit report feature.
        </p>
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [brightsideWebFb]="{ event_name: 'credit_details_tapped', action: 'click' }"
        (click)="results.emit(true)"
      >
        Continue
      </button>
    </div>
  </footer>
</bw-modal>
