import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { SavingsAccount } from '@brightside-web/desktop/data-access/shared';

@Injectable({
  providedIn: 'root',
})
export class NoAutosaveGuard implements CanActivate {
  constructor(private savingsService: SavingsAccountService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.savingsService.getSavingsAccount().pipe(
      map((savingsAccount) => {
        const hasAutosaveSetupBefore =
          Boolean(savingsAccount) && SavingsAccountService.hasSetupAutosaveBefore(savingsAccount as SavingsAccount);
        if (hasAutosaveSetupBefore) {
          this.router.navigate(next.data.redirectRoute || ['home']);
          return false;
        }

        return true;
      })
    );
  }
}
