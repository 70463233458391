import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { UiHeaderType, UiHeaderEyebrowInputs } from '../header.enum';

@Component({
  selector: 'ui-header-eyebrow',
  templateUrl: './header-eyebrow.component.html',
  styleUrls: ['./header-eyebrow.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiHeaderEyebrowComponent implements UiHeaderEyebrowInputs {
  type: UiHeaderType = UiHeaderType.EYE_BROW;

  @Input() title: string;
  @Input() subTitle: string;
}
