import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, of, Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import {
  ApiCacheService,
  CreditSetupResponse,
  CreditSetupVerifyResponse,
  CreditSetupKbaRequest,
  CreditSetupStatus,
  CreditSetupVerifyStatus, FirebaseService,
} from '@brightside-web/desktop/data-access/shared';

@Injectable({
  providedIn: 'root',
})
export class CreditSetupService {
  constructor(
    private apiSvc: ApiCacheService,
    private analytics: FirebaseService
  ) {}

  static isVerifyOtp(statusCode: CreditSetupStatus): boolean {
    return [CreditSetupStatus.VERIFY_OTP].includes(statusCode);
  }

  static isVerifyKba(statusCode: CreditSetupStatus): boolean {
    return [CreditSetupStatus.VERIFY_KBA].includes(statusCode);
  }

  static isCompleted(statusCode: CreditSetupStatus): boolean {
    return [CreditSetupStatus.FULFILLED, CreditSetupStatus.VERIFIED, CreditSetupStatus.ENROLLED].includes(statusCode);
  }

  private forceVerifyAndEnroll(): Promise<CreditSetupResponse> {
    return API.patch('api-mobile', '/credit/subscription', {
      headers: { 'Content-Type': 'application/json' },
      body: null,
    });
  }

  private verifyWithData(body: CreditSetupKbaRequest | { otpCode: string }): Observable<CreditSetupResponse> {
    return from(API.patch('api-mobile', '/credit/subscription', { headers: { 'Content-Type': 'application/json' }, body }));
  }

  private async verifyConfirm(response: CreditSetupResponse, skipForceVerify: boolean = true) {
    let rtnVerifyStatus: CreditSetupVerifyStatus = CreditSetupVerifyStatus.COMPLETE;
    let results;

    if (response.reasonCode) {
      this.analytics.logEvent('credit identity api call completed', {code: response.reasonCode});
    }

    if (!skipForceVerify && response.statusCode === 200) {
      results = await this.forceVerifyAndEnroll();

      if (results.statusCode > 299) {
        rtnVerifyStatus = CreditSetupVerifyStatus.COMPLETE_UNCONFIRMED;
      }
    }

    //We need to clear mobile cache so the cards will adjust as needed
    this.apiSvc.refreshItem('/client/mobilestate');

    return {
      apiResponse: response,
      verifyStatus: rtnVerifyStatus,
    };
  }

  VerifyWithOtp(code: string): Observable<CreditSetupVerifyResponse> {
    return this.verifyWithData({ otpCode: code }).pipe(
      switchMap(resp => this.verifyConfirm(resp)),
      catchError((err) => {
        let rtnVerifyStatus: CreditSetupVerifyStatus = CreditSetupVerifyStatus.ERROR_ALLOW_RETRY;
        let rtnApiResponse = null;
        if (err.response.data.reasonCode) {
          this.analytics.logEvent('credit identity api call completed', {code: err.response.data.reasonCode});
        }
        if(err.response.data.statusCode === 429) {
          rtnVerifyStatus = CreditSetupVerifyStatus.ERROR_FATAL;
          rtnApiResponse = {
            ...err.response.data,
          };
        } else if (err.response.data.statusCode === 406) {
          rtnVerifyStatus = CreditSetupVerifyStatus.ERROR_NO_RETRY;
          rtnApiResponse = {
            ...err.response.data,
          };
        }

        return of({
          apiResponse: rtnApiResponse,
          verifyStatus: rtnVerifyStatus,
        });
      })
    );
  }

  VerifyWithKba(kbaRequest: CreditSetupKbaRequest): Observable<CreditSetupVerifyResponse> {
    return this.verifyWithData(kbaRequest).pipe(
      switchMap(resp => this.verifyConfirm(resp)),
      catchError((err) => {
        let rtnVerifyStatus: CreditSetupVerifyStatus = CreditSetupVerifyStatus.ERROR_ALLOW_RETRY;
        let rtnApiResponse = null;
        if (err.response.data.reasonCode) {
          this.analytics.logEvent('credit identity api call completed', {code: err.response.data.reasonCode});
        }
        if (err.response.data.statusCode === 406) {
          rtnVerifyStatus = CreditSetupVerifyStatus.MORE_INFORMATION;
          rtnApiResponse = {
            ...err.response.data,
          };
        } else if (err.response.data.statusCode === 429) {
          rtnVerifyStatus = CreditSetupVerifyStatus.ERROR_NO_RETRY;
          rtnApiResponse = {
            ...err.response.data,
          }
        }

        return of({
          apiResponse: rtnApiResponse,
          verifyStatus: rtnVerifyStatus,
        });
      })
    );
  }

  getSetupStatus(): Observable<CreditSetupResponse> {
    return from(
      API.get('api-mobile', '/credit/subscription', {
        responseType: 'application/json',
      })
    );
  }

  startSetup(): Observable<CreditSetupResponse> {
    return from(API.post('api-mobile', '/credit/subscription', { headers: { 'Content-Type': 'application/json' } }));
  }
}
