import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '@brightside/brightside-ui-services';
import { ApiCacheService } from './api-cache.service';

export interface ChatTokenResponse {
  chatToken: string;
}


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiCache: ApiCacheService) {}

  getProfile(): Observable<ProfileResponse> {
    return this.apiCache.get<ProfileResponse>('api-mobile', '/client/profile');
  }

  getChatToken(): Observable<ChatTokenResponse> {
    return this.apiCache.get<ChatTokenResponse>('api-mobile','/chat/token');
  }
}

export interface ProfileResponse {
  company: string;
  phoneNumber: string;
  email: string;
  verified: boolean;
}
