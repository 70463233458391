import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import {
  ApiCacheService,
  ApiResponse,
  CreditScore,
  CreditSubscription,
  CreditSummary,
  DesktopCard, DesktopCardFooter,
  DesktopSegmentType,
} from '@brightside-web/desktop/data-access/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  async groomCardData(card: DesktopCard): Promise<DesktopCard> {
    const defaultSegment = card.segments?.find((seg) => seg.type === DesktopSegmentType.DEFAULT);
    if (defaultSegment) {
      try {
        const resp = await this.getCreditScore().toPromise();
        defaultSegment.dynamicValue = resp.score;
        card.ctaPath = '/credit/dashboard';
      } catch (e) {
        defaultSegment.dynamicValue = '--';
        const sub = await this.getCreditSubscription().toPromise();
        if (sub.status === 'FULFILLED') {
          card.ctaPath = 'toast:noCreditData';
        } else {
          card.ctaPath = 'toast:creditSyncing';
        }
        card.footer = <DesktopCardFooter>{
          leftImage: "alert",
          style: "Error",
          title: "An error has occurred"
        };
      }
    }

    return card;
  }

  constructor(private apiSvc: ApiCacheService) {}

  disconnectCreditReport(): Observable<ApiResponse> {
    this.apiSvc.refreshItem('/credit/subscription');
    this.apiSvc.refreshItem('/credit/?filter=summary');
    this.apiSvc.refreshItem('/client/mobilestate');
    return from(API.del('api-mobile', '/credit/subscription', { headers: { 'Content-Type': 'application/json' } }));
  }

  getCreditScore(): Observable<CreditScore> {
    return this.getCreditSummary().pipe(map((resp) => resp.creditScore));
  }

  getCreditSummary(): Observable<CreditSummary> {
    return this.apiSvc.get<CreditSummary>('api-mobile', '/credit/?filter=summary');
  }

  getCreditReport(): Observable<Blob> {
    return from<Promise<string>>(
      API.get('api-mobile', '/credit/report?type=pdf', {
        responseType: 'text',
      })
    ).pipe(
      map((data) => {
        //https://stackoverflow.com/a/52091804/763648
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: 'application/pdf;base64' });
      })
    );
  }

  getCreditSubscription(): Observable<CreditSubscription> {
    return this.apiSvc.get('api-mobile', '/credit/subscription');
  }
}
