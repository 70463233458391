import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';

import {
  FirebaseAnalyticsService,
  FirebaseService,
  MicroCoreUtilityModule,
  MicroUtilTranslationService,
} from '@micro-core/utility';

import { MicroUiTemplateContentModule } from '@micro-ui/template/content';
import { MicroUiTemplatePageModule } from '@micro-ui/template/page';

import { BaseRoutes } from './routes/entry';

import { MicroContestsResolver } from './routes/resolver/contests.resolver';
import { MicroContestDetailsResolver } from './routes/resolver/contest-details.resolver';
import { MicroContestResultsResolver } from './routes/resolver/contest-results.resolver';

import { MicroContestEntryAutosaveConfirmModalResolver } from './routes/savings-type/confirm-modal/entry-confirm-modal.resolver';
import { MicroContestEntryAutosaveCustomModalResolver } from './routes/savings-type/custom-modal/entry-modal-custom.resolver';

import { MicroContestEntryAutosaveResolver } from './routes/savings-type/autosave/entry-autosave.resolver';
import { MicroContestEntryIntroResolver } from './routes/entry/intro/entry-intro.resolver';

import { MicroContestEntryRemoteConfigResolver } from './routes/resolver/entry-remote-config.resolver';
import { MicroContestEntryRemoteContestDetailsResolver } from './routes/resolver/entry-contest-details.resolver';

import translationFile_en from './assets/i18n/en';

const moduleRoutes: Routes = [...BaseRoutes];
const languageFile = {
  [MicroUtilTranslationService.Language.English.code]: translationFile_en,
};

export const MicroContestNgModuleConfig = {
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    MicroCoreUtilityModule,
    MicroUiTemplateContentModule,
    MicroUiTemplatePageModule,
  ],
  providers: [
    CurrencyPipe,
    MicroContestDetailsResolver,
    MicroContestEntryAutosaveConfirmModalResolver,
    MicroContestEntryAutosaveCustomModalResolver,
    MicroContestEntryAutosaveResolver,
    MicroContestEntryRemoteContestDetailsResolver,
    MicroContestEntryIntroResolver,
    MicroContestEntryRemoteConfigResolver,
    MicroContestResultsResolver,
    MicroContestsResolver,
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: TranslateService, useClass: MicroUtilTranslationService },
  ],
};

@NgModule({
  imports: [...MicroContestNgModuleConfig.imports, RouterModule.forChild(moduleRoutes)],
  providers: [...MicroContestNgModuleConfig.providers],
})
export class MicroContestModule {
  constructor(private utilTranslationService: MicroUtilTranslationService) {
    const localCopy = this.checkForLocalCopy(this.utilTranslationService.currentCurrentLanguageCode);

    if (localCopy) {
      this.utilTranslationService.updateTranslate(localCopy);
    }
  }

  private checkForLocalCopy(code: string): { [key: string]: string } | undefined {
    return languageFile[code] || undefined;
  }
}
