<ng-container *ngFor="let radioOption of data.options">
  <ng-container
    *ngTemplateOutlet="
      radioTemplate;
      context: {
        $implicit: radioOption,
        labelPre: (radioOption.label.pre || ''),
        labelMain: radioOption.label.main,
        labelPost: (radioOption.label.post || '')
      }
    "
  ></ng-container>
  <!-- ToDo: Why does translate not work here it changes formate of radio -->
</ng-container>

<ng-template #radioTemplate let-radioOption let-labelPre="labelPre" let-labelMain="labelMain" let-labelPost="labelPost">
  <bw-radio
    [name]="data.groupName"
    [radioBlock]="data.displayStyle === DISPLAY_STYLE.BLOCK"
    [value]="radioOption.value"
    [preLabel]="labelPre"
    [label]="labelMain"
    [postLabel]="labelPost"
    (radioClick)="formValuesChanged.emit({ name: data.groupName, value: $event.value })"
  ></bw-radio>
</ng-template>
