import { MessageBusEventUtil } from '@micro-core/message-bus';

import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateAction, UiTemplateFlyerDataBuilder, UiTemplateNavType, UiTemplateState } from '@micro-ui/template/page';

import { MicroContestAnalyticsEventName, MicroContestRouteKey, MicroContestTranslationKey } from '../../../model/contest.enum';

const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.CLOSE,
    header: {
      type: UiHeaderType.DEFAULT,
      title: MicroContestTranslationKey.CONTEST_MORE_TITLE,
      subTitle: '',
      icon: '',
    },
    content: [],
    link: MicroContestTranslationKey.CONTEST_MORE_LINK,
    cta1Label: MicroContestTranslationKey.CONTEST_MORE_CTA1,
    viewName: MicroContestAnalyticsEventName.ENTRY_RULES,
  },
  actions: {
    [UiTemplateAction.navLeftIcon]: () =>
      MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeatureToSavingsHome),
    [UiTemplateAction.footerLink]: { route: ['../', 'redirect', 'rules'] },
    [UiTemplateAction.cta1]: (state?: UiTemplateState) => {
      let routeToEvent = MessageBusEventUtil.event.standard.exitFeatureToSavingsHome;

      if (state?.state_form?.values && !state.state_form.values['autosaving']) {
        const routeTo = `${MicroContestRouteKey.BASE}/${MicroContestRouteKey.INTRO}/1`;

        routeToEvent = MessageBusEventUtil.event.builder.forPathAndReturnStandardEvent({ path: routeTo, route: routeTo });
      }

      MessageBusEventUtil.sendOutgoing(routeToEvent);
    },
  },
});

export default TemplateData;
