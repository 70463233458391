import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router, Params
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';

import {RegistrationService} from "@brightside-web/desktop/data-access/onboarding";
import {Cache} from "aws-amplify";

@Injectable({
  providedIn: 'root'
})
export class IdentityGuard implements CanActivate {

  neededParams: string[]= ['referrerType', 'firstName', 'clientType', 'company'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private regSvc: RegistrationService ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      this.regSvc.skipAccountCreation ||
      (Cache.getItem('customerType') && Cache.getItem('employmentType'))
    ) {
      return of(true);
    } else {
      this.router.navigate(['registration/type']);
      return of(false);
    }

  }

}
