import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { API } from 'aws-amplify';

export interface WaitlistPayload {
  last_name:string;
  first_name:string;
  phone_number:string;
  company:string;
  email:string;
}

@Injectable({
  providedIn: 'root'
})
export class WaitlistService {

  constructor() { }

  submitEntry(payload: WaitlistPayload) {
    /**
      {
          "last_name": "tester",
          "email": "faker@faker.com",
          "first_name": "Kate",
          "phone_number": "6028675309",
          "company": "zappos"
      }
     */
    return from(API.post('api-mobile-noauth', '/waitlist', { body: payload }));
  }
}
