import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TermsService} from "@brightside-web/desktop/data-access/shared";
import {map} from "rxjs/operators";
import {MessageBusInternalService, MessageBusOutgoingEventKey} from "@brightside-web/micro/core/message-bus";

@Injectable({
  providedIn: 'root'
})
export class ConsentsGivenGuard implements CanActivate {
  constructor(protected termsSvc: TermsService, protected router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.termsSvc.getTermsAcceptance(...next.data.consents).pipe(
      map((resp) => {
        if (resp) {
          return true;
        }
        MessageBusInternalService.sendOutgoingHubEvent(
          {
            event: MessageBusOutgoingEventKey.ROUTE,
            bridgeSetting: {
              data: {
                path: next.data.guardRedirect?.bridge ? next.data.guardRedirect?.bridge : 'home',
              },
            },
            fallbackSetting: {
              routeToUrl: next.data.guardRedirect?.route ? next.data.guardRedirect.route.join('/') : ['home'].join('/'),
            },
          }
        );
        return false;
      })
    );
  }

}
