<div [ngStyle]="{ display: showReset ? 'inherit' : 'none' }">
  <bw-action-article>
    <bw-back header-actions></bw-back>
    <typography header-title title-medium>Reset password</typography>
    <div content-section style="max-width: 360px;">
      <bw-paragraph>Let’s reset the password for your account</bw-paragraph>
      <bw-create-password label="New Password" fcName="password" [autoFocus]="true" (keyup.enter)="requestCode()"
      ></bw-create-password>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <!-- http://codelyzer.com/rules/templates-no-negated-async/ the form does not work right unless the conditional is implemented this way -->
      <button
        bw-button
        (click)="requestCode()"
        [processing]="processing"
        [disabled]="!(resetPasswordFormIsValid$ | async)">
        Continue
      </button>
    </div>
  </bw-action-article>
</div>

<brightside-web-call-fa
  *ngIf="showFaModal"
  (dismiss)="showFaModal = false"
  title="Contact Financial Assistant"
  ctaText="Dismiss"
  [company]="company"
></brightside-web-call-fa>

<div *ngIf="showConfirm">
  <!-- TODO WB-1112 can this forgot pw confirm be combined with the signup confirm -->
  <form [formGroup]="confirmForm" (ngSubmit)="confirmSubmit()">
    <bw-action-article>
      <bw-back
        header-actions
        [emit]="true"
        (back)="showConfirm = false; showReset = true">
      </bw-back>
      <typography title-medium header-title>Confirm device</typography>
      <div content-section style="max-width: 360px;">
        <bw-paragraph [textStyle]="'Body1'">Before we update your password, enter the code sent to {{ phone | phone: true }}</bw-paragraph>
        <bw-paragraph [textStyle]="'Body1'" *ngIf="!isRegistration">Not your number?&nbsp
          <a
          bw-anchor
          anchorUnderline="true"
          (click)="showFaModal = true">
          Contact Assistant</a>
        </bw-paragraph>
        <bw-input
          formControlName="code"
          label="Code"
          inputMask="000000"
          minlength="6"
          maxlength="6"
          [inputError]="!!error && confirmForm.controls['code']?.touched"
          [inputErrorText]="error"
          verticalSpace="mb-md"
          [autoFocus]="true"
        ></bw-input>
        <div [ngClass]="{'center': isRegistration}">
          <a
            bw-anchor
            anchorStyle="text-small-regular"
            (click)="requestCode()">
            Resend code
          </a>
          <span *ngIf="isRegistration">
            &nbsp;|&nbsp;
            <a bw-anchor anchorStyle="text-small-regular" (click)="changeNumber()"> Change Number </a>
          </span>
        </div>
      </div>
      <div footer-actions class="flex-row row-reverse">
        <button
          bw-button
          type="submit"
          [processing]="processing"
          [disabled]="confirmForm.invalid">
          Continue
        </button>
      </div>
    </bw-action-article>
  </form>
</div>
