<bw-toggle
  label="Pause autosavings"
  [startSelected]="isCurrentlyPaused"
  [letCallerSetOn]="true"
  (statusChanged)="handleToggleChanged($event)"
></bw-toggle>

<bw-modal
  *ngIf="showConfirmModal"
  [brightsideWebFb]="{ event_name: 'savings_autosave_pause_shown' }"
  modalWidth="medium">
  <typography title-medium>Confirmation</typography>
  <div class="body">
    <typography text-large-regular color="var(--secondary-text)"
      >Are you sure you want to pause automatic savings from your paycheck?</typography
    >
  </div>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [ngStyle]="{ 'background-color': 'var(--error)' }"
        (click)="handleConfirmCta()"
        [processing]="processing"
        [brightsideWebFb]="{ event_name: 'savings_autosave_pause_tapped', action: 'click' }">
        <typography text-large-semibold>
          Yes, I'm sure
        </typography>
      </button>
      <button
        bw-link-button
        class="bs-blue pr-xl"
        (click)="handleCancelCta()"
        [brightsideWebFb]="{ event_name: 'savings_autosave_pause_dismissed', action: 'click' }"
        [dismissedCta]="true">
          No, cancel
      </button>
    </div>
  </div>
</bw-modal>
