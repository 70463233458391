import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FirebaseAnalyticsService, FirebaseService, MicroUtilTranslationService } from '@micro-core/utility';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';

import { MicroContestModule, MicroContestNgModuleConfig } from './micro-contest.module';
import { MicroContestPromoCardComponent } from './component/contest-promo/contest-promo-card.component';

import { MicroContestPromoMoreResolver } from './routes/promo/more-info/promo-more-info.template.resolver';

import { BaseRoutes as PromoRoutes } from './routes/promo/index';

@NgModule({
  ...MicroContestNgModuleConfig,
  imports: [...MicroContestNgModuleConfig.imports, MicroUiMoleculeModule, RouterModule.forChild(PromoRoutes)],
  exports: [MicroContestPromoCardComponent],
  declarations: [MicroContestPromoCardComponent],
  providers: [
    CurrencyPipe,
    MicroContestPromoMoreResolver,
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: TranslateService, useClass: MicroUtilTranslationService },
  ],
})
export class MicroContestPromoModule extends MicroContestModule {}
