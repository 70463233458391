import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { UiTemplateBaseComponent } from './base-page-template.component';

import { UiTemplateStateHandlerService } from '../service/state-handler/state-handler.service';
import { UiTemplateStateKey } from '../service/state-handler/state.enum';
import { DeepPartial, UiTemplatePageStateCondition, UiTemplateState } from '../service/state-handler/state.interface';

export interface OnTemplateStateChanged {
  /**
   *
   */
  templateStateChanged(): void;
}

import { UiTemplateBaseInterface } from './model/page-template.interface';

export interface UiTemplateStateInterface extends UiTemplateBaseInterface {
  state?: {
    initial?: UiTemplateState;
    updateWith?: DeepPartial<UiTemplateState>;
  };
  stateConditions?: UiTemplatePageStateCondition[];
}

@Component({
  template: '',
})
export class UiTemplateStateComponent extends UiTemplateBaseComponent implements OnDestroy, OnInit, OnTemplateStateChanged {
  private stateUnsubscriber: Subject<void> = new Subject<void>();

  override logPrefix = '<notSetUp>';
  override data: UiTemplateStateInterface;

  /**
   * state handler should be the only way to update/access
   * state.
   *
   * Interface UiTemplateStateHandler
   */
  stateKeys = UiTemplateStateKey;

  constructor(
    public state: UiTemplateStateHandlerService,
    protected activatedRoute: ActivatedRoute,
    protected changeDetectorRef: ChangeDetectorRef,
    protected injector: Injector,
    protected router: Router
  ) {
    super(activatedRoute, changeDetectorRef, injector, router);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.state.isNotEmpty(this.data.state?.initial)) {
      this.logForDebugging('Init state');

      this.state.initialize(this.data.state?.initial || {}, this.stateUnsubscriber);

      this.templateStateChanged();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.stateUnsubscriber?.unsubscribe();
    this.state?.destroy();
  }

  private _templateDataChanged(): void {
    this.logForDebugging(
      'Condition Checks (templateDataChanged)',
      this.state.isNotEmpty(this.data.state?.initial),
      this.state.isNotEmpty(this.data.state?.updateWith),
      !this.state.isStateEqualWith(this.data.state?.updateWith || {})
    );

    if (
      this.state.isNotEmpty(this.data.state?.initial) &&
      this.state.isNotEmpty(this.data.state?.updateWith) &&
      !this.state.isStateEqualWith(this.data.state?.updateWith || {})
    ) {
      this.logForDebugging('Template state change 1');

      this.templateStateChanged();
    }
  }

  private _checkForConditionalChanges(pendingState: UiTemplateState): UiTemplateState {
    return pendingState;
  }

  protected setUpdateStateWith(partialState: DeepPartial<UiTemplateState>) {
    if (this.data.state) {
      this.data.state.updateWith = partialState;
    }

    this.templateStateChanged();
  }

  public templateDataChanged(): void {
    super.templateDataChanged();

    this._templateDataChanged();
  }

  public templateStateChanged(): void {
    this.state.updateWithInterface(this.data);
  }
}
