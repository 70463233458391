import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ToastService } from '@brightside/brightside-ui-services';
import { MenuItem } from '@brightside/brightside-ui';
import { Environment } from '@micro-core/environment';
import { PageTransitionAnimationTriggers } from '@micro-ui/template/page';

import {
  FirebaseService,
  LinkedAccountRequestBody,
  LinkedAccountResponse,
  LinkedAccountType,
} from '@brightside-web/desktop/data-access/shared';
import { LinkedAccountService } from '@brightside-web/desktop/data-access/account-linking';

import { Subscription } from 'rxjs';
import { Hub } from 'aws-amplify';
import * as moment from 'moment-business-days';

export const luhnValidator =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const luhnFail = !_passesLuhnCheck(control.value);
    return luhnFail ? { luhnFail: { value: control.value } } : null;
  };

const _passesLuhnCheck = (routing: string) => {
  const checksumTotal =
    7 * (parseInt(routing.charAt(0), 10) + parseInt(routing.charAt(3), 10) + parseInt(routing.charAt(6), 10)) +
    3 * (parseInt(routing.charAt(1), 10) + parseInt(routing.charAt(4), 10) + parseInt(routing.charAt(7), 10)) +
    9 * (parseInt(routing.charAt(2), 10) + parseInt(routing.charAt(5), 10) + parseInt(routing.charAt(8), 10));
  const checksumMod = checksumTotal % 10;
  return checksumMod === 0;
};

@Component({
  animations: PageTransitionAnimationTriggers,
  selector: 'brightside-web-account-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AccountAddComponent implements OnInit {
  // ToDo: should have an object passed in that can have all these configs - maybe
  @Input() pageStepperActiveIndex = -1;
  @Input() pageOnBackCtaPath: string[] = [];
  @Input() pageOnForwardCtaPath: string[] = [];
  @Input() pageOnBackwardCtaPath: string[] = [];

  @Input() fbPage = '';
  @Input() fbCategory = '';
  @Input() fbEventName = '';

  protected sub = new Subscription();

  accountTypes = [
    { name: 'Checking account', code: LinkedAccountType.CHECKING },
    { name: 'Savings account', code: LinkedAccountType.SAVINGS },
  ];

  selectConfig = {
    displayKey: 'name',
    clearOnSelection: true,
  };

  routingForm = new FormGroup(
    {
      routingNumber: new FormControl('', [Validators.required, Validators.pattern('[012346789][0-9]{8}'), luhnValidator()]),
      accountNumber: new FormControl('', [Validators.required, Validators.pattern('\\d{5,25}')]),
      accountNumberConfirm: new FormControl('', [Validators.required, Validators.pattern('\\d{5,25}')]),
      accountType: new FormControl('', [Validators.required]),
    },
    { validators: [] }
  );

  pageTitle = 'Link external account';
  pageSubTitle = 'Linking your bank account makes for easy withdrawals anytime you need cash.';
  pageCtaLabel = 'Connect';
  pageSecondaryCtaLabel = '';
  pageStepperItems: MenuItem[] = [{}, {}, {}, {}];
  pageHideBackButton = false;

  microTitle = 'Verify your account';
  microLineWithDate = '';
  microPrimaryCta = 'I understand';

  processing = false;
  fatalError = false;

  isCtaDisabled = true;

  showMicroWarning = false;
  shouldReverseExitAnimation = false;

  constructor(
    protected analytics: FirebaseService,
    private changeDetectorRef: ChangeDetectorRef,
    private env: Environment,
    private router: Router,
    private toastService: ToastService,
    private linkedAccountService: LinkedAccountService,
    private activatedRoute: ActivatedRoute
  ) {
    const data = this.activatedRoute.snapshot.data;

    this.pageStepperActiveIndex = data.pageStepperActiveIndex || -1;
    this.pageOnBackwardCtaPath = data.pageOnBackwardCtaPath || [];
    this.pageOnForwardCtaPath = data.pageOnForwardCtaPath || [];
    this.pageHideBackButton = data.pageHideBackButton || false;

    if (data.pageSecondaryCtaLabel) {
      this.pageSecondaryCtaLabel = data.pageSecondaryCtaLabel;
    } else {
      this.pageSecondaryCtaLabel = this.pageStepperActiveIndex > 0 ? 'Set up later' : '';
    }

    this.fbPage = data.fbPage;
    this.fbCategory = data.fbCategory;
    this.fbEventName = data.fbEventName;
  }

  ngOnInit() {
    this.setUpHub();

    this.routingForm.valueChanges.subscribe(() => {
      // Need to check if all values are ready
      this.checkIsFormComplete();
    });
  }

  get accountType() {
    return this.routingForm.get('accountType');
  }
  get routingNumber() {
    return this.routingForm.get('routingNumber');
  }
  get accountNumber() {
    return this.routingForm.get('accountNumber');
  }
  get accountNumberConfirm() {
    return this.routingForm.get('accountNumberConfirm');
  }

  public isValidEntry(field: AbstractControl | null) {
    return field && field.value.length > 0 && !field.errors;
  }

  public checkIsFormComplete() {
    //todo this really should all be done with form validators, whatever
    this.isCtaDisabled =
      this.accountType?.value &&
      this.isValidEntry(this.accountNumber) &&
      this.isValidEntry(this.accountNumberConfirm) &&
      this.isValidEntry(this.routingNumber) &&
      this.accountNumber?.value === this.accountNumberConfirm?.value
        ? false
        : true;
  }

  private setUpHub() {
    Hub.listen('ErrorChannel', () => {
      this.createLinkedAccount();
    });
  }

  private getTwoBusinessDays() {
    return moment(new Date()).local().businessAdd(2).startOf('day');
  }

  private getRequestBody(): LinkedAccountRequestBody {
    return {
      account_class: this.accountType?.value?.code || LinkedAccountType.SAVINGS,
      account_num: this.accountNumber?.value || '',
      routing_num: this.routingNumber?.value || '',
    };
  }

  private handleSuccessOnLinkedAccount(response: LinkedAccountResponse) {
    // If the account is linked completely move forward
    if (response.linkedCompletely) {
      this.router.navigate(this.pageOnForwardCtaPath);
      return;
    }

    this.microLineWithDate = `Look for these deposits from Brightside around ${this.getTwoBusinessDays().format('MMM Do')}`;
    this.showMicroWarning = true;

    this.processing = false;
    this.fatalError = false;
  }

  private handleFailureOnLinkedAccount() {
    this.processing = false;
    this.fatalError = true;
    this.analytics.logEvent('error_shown', { 'error id': 'create_linked_account' });
    this.toastService.error('Looks like something went wrong with adding your account.', { link: true, transient: false });
  }

  private createLinkedAccount() {
    this.processing = true;
    this.linkedAccountService.createAccount(this.getRequestBody()).subscribe(
      (response: LinkedAccountResponse) => this.handleSuccessOnLinkedAccount(response),
      () => this.handleFailureOnLinkedAccount()
    );
  }

  handleBackCtaClick() {
    this.shouldReverseExitAnimation = true;
    this.changeDetectorRef.detectChanges();

    this.handleSecondaryCtaClick();
  }

  handleSecondaryCtaClick() {
    this.router.navigate(this.pageOnBackwardCtaPath.length > 0 ? this.pageOnBackwardCtaPath : this.pageOnForwardCtaPath);
  }

  handleCtaClick() {
    if (this.processing || this.isCtaDisabled) {
      return;
    }

    this.createLinkedAccount();
  }

  handleMicroCtaClick() {
    this.router.navigate(this.pageOnForwardCtaPath);
  }
}
