import { Routes } from '@angular/router';

import { UiTemplateFlyerComponent, UiTemplateFormFlyerComponent } from '@micro-ui/template/page';

import {
  MicroContestRouteDataKey,
  MicroContestRouteKey,
  MicroContestRouteModalKey,
  MicroContestRouteParamKey,
} from '../../model/contest.enum';

import { ContestEntryGuard } from '../guard/contest-entry.guard';

import { MicroContestsResolver } from '../resolver/contests.resolver';
import { MicroContestDetailsResolver } from '../resolver/contest-details.resolver';
import { MicroContestResultsResolver } from '../resolver/contest-results.resolver';

import { MicroContestEntryAutosaveConfirmModalResolver } from '../savings-type/confirm-modal/entry-confirm-modal.resolver';
import { MicroContestEntryAutosaveCustomModalResolver } from '../savings-type/custom-modal/entry-modal-custom.resolver';
import { MicroContestEntryRemoteConfigResolver } from '../resolver/entry-remote-config.resolver';
import { MicroContestEntryRemoteContestDetailsResolver } from '../resolver/entry-contest-details.resolver';

import TemplateData_EntryIntro from './intro/entry-intro.template.data';
import { MicroContestEntryIntroResolver } from './intro/entry-intro.resolver';

import TemplateData_EntryAutosave from '../savings-type/autosave/entry-autosave.template.data';
import { MicroContestEntryAutosaveResolver } from '../savings-type/autosave/entry-autosave.resolver';

import TemplateData_EntryRules from './contest-rules/entry-rules.template.data';

import TemplateData_EntryComplete from './complete/entry-complete.template.data';

export const BaseRoutes: Routes = [
  {
    path: MicroContestRouteKey.INTRO,
    resolve: {
      [MicroContestRouteDataKey.REMOTE_CONFIG]: MicroContestEntryRemoteConfigResolver,
      [MicroContestRouteDataKey.RESPONSE_CONTESTS]: MicroContestsResolver,
    },
    children: [
      {
        path: `:${MicroContestRouteParamKey.CONTEST_ID}`,
        canActivate: [],
        resolve: {
          [MicroContestRouteDataKey.RESPONSE_CONTEST_DETAIL]: MicroContestDetailsResolver,
          [MicroContestRouteDataKey.RESPONSE_CONTEST_RESULTS]: MicroContestResultsResolver,
          [MicroContestRouteDataKey.REMOTE_CONFIG_CONTEST]: MicroContestEntryRemoteContestDetailsResolver,
        },
        children: [
          {
            path: '',
            component: UiTemplateFlyerComponent,
            canActivate: [ContestEntryGuard],
            resolve: {
              [MicroContestRouteDataKey.TEMPLATE]: MicroContestEntryIntroResolver,
            },
            data: {
              [MicroContestRouteDataKey.TEMPLATE]: TemplateData_EntryIntro,
            },
          },
          {
            path: MicroContestRouteKey.RULES,
            component: UiTemplateFlyerComponent,
            canActivate: [],
            data: {
              [MicroContestRouteDataKey.TEMPLATE]: TemplateData_EntryRules,
            },
          },
          {
            path: MicroContestRouteKey.AUTOSAVE,
            children: [
              {
                path: '',
                component: UiTemplateFormFlyerComponent,
                canActivate: [],
                resolve: {
                  [MicroContestRouteDataKey.TEMPLATE]: MicroContestEntryAutosaveResolver,
                  [MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION]: MicroContestEntryAutosaveConfirmModalResolver,
                  [MicroContestRouteModalKey.AUTOSAVE_CUSTOM]: MicroContestEntryAutosaveCustomModalResolver,
                },
                data: {
                  [MicroContestRouteDataKey.TEMPLATE]: TemplateData_EntryAutosave,
                },
                //This line is to make sure we load resolvers for modal changes
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
              },
            ],
          },
          {
            path: MicroContestRouteKey.COMPLETE,
            component: UiTemplateFlyerComponent,
            canActivate: [],
            resolve: {
              [MicroContestRouteDataKey.TEMPLATE]: MicroContestEntryIntroResolver,
            },
            data: {
              [MicroContestRouteDataKey.TEMPLATE]: TemplateData_EntryComplete,
            },
          },
        ],
      },
    ],
  },
];
