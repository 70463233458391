<ng-container *ngFor="let contentControl of data">
  <ng-container *ngTemplateOutlet="contentCdnUrl; context: { $implicit: contentControl }"></ng-container>
  <ng-container *ngTemplateOutlet="contentTitleText; context: { $implicit: contentControl }"></ng-container>
  <ng-container *ngTemplateOutlet="contentListItem; context: { $implicit: contentControl }"></ng-container>
  <ng-container *ngTemplateOutlet="contentGroupCell; context: { $implicit: contentControl }"></ng-container>
</ng-container>

<ng-content select="[custom-content-section]"></ng-content>

<ng-template #contentCdnUrl let-contentControl>
  <ui-content-cdn-url *ngIf="contentType.CDN_CONTENT === contentControl.type" [data]="contentControl.data"></ui-content-cdn-url>
</ng-template>

<ng-template #contentTitleText let-contentControl>
  <ui-content-title-text
    *ngIf="contentType.TITLE_TEXT === contentControl.type"
    [data]="contentControl.data"
  ></ui-content-title-text>
</ng-template>

<ng-template #contentListItem let-contentControl>
  <bw-list-item-details
    *ngIf="contentControl.type === contentType.LIST_ITEM"
    [avatarIconName]="contentControl.data.icon | translate"
    [rightIconName]="contentControl.data.rightIcon | translate"
    [listTitle]="contentControl.data.title | translate"
    [listDetail]="contentControl.data.subTitle | translate"
    [rightIconColor]="contentControl.data.rightIconColor"
    [blockStyle]="contentControl.data.showAsBlock"
  >
  </bw-list-item-details>
</ng-template>

<ng-template #contentGroupCell let-contentControl>
  <ng-container *ngIf="contentControl.type === contentType.GROUP_CELL">
    <bw-cell
      *ngFor="let item of contentControl.data.options"
      [separatorType]="separatorType.Full"
      [contentLeft]="true"
      [contentRight]="true"
    >
      <ng-container content-left>
        <p text-large-regular class="truncate-line">{{ item.label | translate }}</p>
      </ng-container>

      <ng-container content-right>
        <p text-large-regular class="truncate-line">{{ item.value | translate }}</p>
      </ng-container>
    </bw-cell>
  </ng-container>
</ng-template>
