<bw-modal modalWidth="large">
  <typography title-medium>FAQ</typography>
  <ng-container class="body">
    <ng-container *ngFor="let term of savingsFaqTranslationKeys;">
      <div class="mb-sm">
        <p headline-semibold>{{ term[0] | translate }}</p>
      </div>
      <div class="mb-sm">
        <p text-medium-regular mwTextColor="var(--secondary-text)" [innerHTML]="term[1] | translate"></p>
      </div>
    </ng-container>
  </ng-container>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="dismiss()"
              [brightsideWebFb]="{ event_name: 'savings_intro_faq_tapped', action: 'click' }" >OK</button>
    </div>
  </div>
</bw-modal>
