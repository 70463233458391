import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MessageBusInternalService, MessageBusEventUtil } from '@micro-core/message-bus';

import { MicroContestAppService, MicroContestAppResponse } from '../../service/micro-contest-app.service';
import { MicroContestRouteParamKey } from '../../model/contest.enum';

@Injectable({
  providedIn: 'root',
})
export class ContestEntryGuard implements CanActivate {
  constructor(private microContestAppService: MicroContestAppService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const contestId =
      route.queryParams[MicroContestRouteParamKey.CONTEST_ID] || route.params[MicroContestRouteParamKey.CONTEST_ID] || '';

    //ToDo: we need logic here to make sure the contest is over
    return this.microContestAppService.getDetails({ id: contestId }).pipe(
      map((contestResponse: MicroContestAppResponse | undefined) => {
        if (contestResponse?.data?.isClientParticipating === false) {
          return true;
        }

        MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeatureToSavingsHome);
        return false;
      })
    );
  }
}
