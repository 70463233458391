import { ActivatedRoute } from '@angular/router';

const isTemplateFullScreen = (activatedRoute: ActivatedRoute) =>
  activatedRoute?.snapshot.data &&
  activatedRoute?.snapshot.data.appDisplayStyle &&
  activatedRoute?.snapshot.data.appDisplayStyle === 'fullScreen';

const convertBase64ToBlob = (base64String: string) => {
  const byteChars = atob(base64String); //To decrypt data
  const dataArray = new Array(byteChars.length);

  for (let i = 0; i < byteChars.length; i++) {
    dataArray[i] = byteChars.charCodeAt(i);
  }

  const byteArray = new Uint8Array(dataArray);

  return new Blob([byteArray], { type: 'application/pdf' });
};

export { isTemplateFullScreen, convertBase64ToBlob };
