import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router:Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.hasCurrentUser().pipe(
      tap(hasUser => {
        if (!hasUser){
          this.authService.redirectUrl = window.location.pathname;
          if (window.location.href.indexOf('signin=true') > -1) {
            this.router.navigate(['/registration/sign_in'])
          } else {
            this.router.navigate(['/registration/create-account'])
          }
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.hasCurrentUser().pipe(
      tap(hasUser => {
        if (!hasUser){
          this.authService.redirectUrl = state.url;
          if (window.location.href.indexOf('signin=true') > -1) {
            this.router.navigate(['/sign_in'])
          } else {
            this.router.navigate(['/sign_up'])
          }
        }
      })
    );
  }
}
