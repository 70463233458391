import {Component,Input,Output,EventEmitter,ChangeDetectionStrategy, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem } from '../utils/menuitem';

@Component({
    selector: 'bw-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class StepsComponent implements OnInit, OnDestroy {

    @Input() activeIndex = 0;

    @Input() model: MenuItem[];

    @Input() readonly =  true;

    @Input() style: any;

    @Input() styleClass: string;

    @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

    constructor(private router: Router, private route:ActivatedRoute, private cd: ChangeDetectorRef) { }

    subscription: Subscription;

    ngOnInit() {
        this.subscription = this.router.events.subscribe(() => this.cd.markForCheck());
    }

    itemClick(event: Event, item: MenuItem, i: number) {
        if (this.readonly || item.disabled) {
            event.preventDefault();
            return;
        }

        this.activeIndexChange.emit(i);

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item,
                index: i
            });
        }
    }

    isClickableRouterLink(item: MenuItem) {
        return item.routerLink && !this.readonly && !item.disabled;
    }

    isComplete(item: MenuItem, index: number) {
      if (item) {
        return this.activeIndex > index;
      }
    }

    isActive(item: MenuItem, index: number) {
        if (item.routerLink)
            return this.router.isActive(item.routerLink, false) || this.router.isActive(this.router.createUrlTree([item.routerLink], {relativeTo: this.route}).toString(), false);
        else
            return index === Number(this.activeIndex);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
