import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';

import {Cache} from 'aws-amplify';
import {Observable, of, from} from 'rxjs';
import {
  MobileStateService,
  RequiredVerificationsEnums,
  RequiredVerificationService,
  VerificationStatus
} from '@brightside-web/desktop/data-access/shared';
import {map, switchMap} from 'rxjs/operators';

enum VerificationPaths {
  ACCOUNT = '/sso-setup/create-account',
  EMAIL = '/sso-setup/collect-email',
  EMPLOYEEALIAS = '/registration/employee-alias',
  HOMEADDRESS = '/registration/home-address',
  HOMEADDRESSAUTO = '/registration/home-address-auto',
  FULLSSN = '/registration/verify-full-ssn'
}


@Injectable({
  providedIn: 'root'
})
export class RequiredVerificationGuard implements CanActivate, CanLoad {
  verificationPathsPiece = [
    'create-account','collect-email','employee-alias','home-address','home-address-auto', 'identity'
  ]

  knownEligibility = [
    'unique_id',
    'first_name',
    'last_name',
    'date_of_birth',
    'last_four_ssn',
    'home_zip_code',
    'work_state'
  ]

  constructor(
    protected service: RequiredVerificationService,
    protected router: Router,
    private mobileStateService: MobileStateService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.checkRequiredVerification(next.url.join('/'));
  }


  checkRequiredVerification(path: string): Observable<boolean> {
    return this.mobileStateService.get(true).pipe(
      switchMap((value, index) => {
        if (value.onboardingComplete) {
          return of(true);
        } else {
          return this.service.getNextRequiredVerification().pipe(
            map(resp => {
              const foundPath = this.verificationPathsPiece.some((element: string) => path.includes(element));
              const foundEligibility = this.knownEligibility.some((element: string) => resp.type.toLowerCase() === element.toLowerCase());

              if (resp.type === 'none' && foundPath) {
                this.router.navigate(['/']);
                return false;
              }

              if (resp.type === 'account') {
                this.router.navigate(['/sso-setup/create-account']);
                return false;
              } else if (resp.type === 'email') {
                this.router.navigate(['/sso-setup/collect-email']);
                return false;
              } else if (resp.type === RequiredVerificationsEnums.HOMEADDRESS && !path.includes('home-address')) {
                const homeAddressRoute = Cache.getItem('useManualHomeAddressForm') === true
                  ? 'registration/home-address' : '/registration/home-address-auto';
                this.router.navigate([homeAddressRoute]);
                return false;
              } else if (foundEligibility
                && !path.includes('identity')
                && !this.service.isIdentityCompleted) {
                this.router.navigate(['/registration/identity']);
                return false;
              }

              return true;
            })
          );
        }
      })
    );

  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkRequiredVerification(route.path || '');
  }
}
