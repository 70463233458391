import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import {
  AuthGuard,
  OnboardingCompleteGuard,
  RequiredVerificationGuard,
  DisplayLoaderComponent, VerificationStatusGuard, ProductVerificationGuard, GuidGuard,
} from '@brightside-web/shared/desktop';
import { LoanEligibleGuard, PayrollEligibleGuard } from '@brightside-web/shared/autosave';

import { UiTemplateModalRouterComponent } from '@micro-ui/template/page';
import { MicroContestRouteParamKey } from '@micro-app/contest';

import { VerifyComponent } from './verify/verify.component';

//NOTE: all canLoad guards here must also be applied as canActivate guards in the modules
// see https://stackoverflow.com/questions/43933595/angular2-authguard-not-working-when-using-browser-navigation-buttons

import { Cache } from 'aws-amplify';

import { SSOAuthGuard } from './sso.guard';
import { ReviewAppGuard } from './review-app.guard';
import { WaitlistComponent } from './waitlist/waitlist.component';

import { LayoutStyle } from './routing.service';

import {
  ROUTES_PATH as INVITE_ROUTES_PATH,
  ROUTES as INVITE_ROUTES,
  BREADCRUMB_LABEL as INVITE_BREADCRUMB_LABEL,
} from './routes/invite';

import { ROUTES_PATH as DEEP_SAVINGS_ROUTES_PATH, ROUTES as DEEP_SAVINGS_ROUTES } from './routes/deep-savings';

const microAppRoutes = [
  {
    path: 'contest',
    loadChildren: () => import('@micro-app/contest').then((m) => m.MicroContestModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard, VerificationStatusGuard],
  },
  {
    path: 'contest-legal',
    loadChildren: () => import('@micro-app/contest').then((m) => m.MicroContestLegalModule),
  },
  {
    path: 'contest-promo',
    canLoad: [AuthGuard, OnboardingCompleteGuard, VerificationStatusGuard],
    loadChildren: () => import('@micro-app/contest').then((m) => m.MicroContestPromoModule),
  },
  {
    path: 'contest-reveal',
    outlet: 'modal',
    component: UiTemplateModalRouterComponent,
    loadChildren: () => import('@micro-app/contest').then((m) => m.MicroContestResultModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard, VerificationStatusGuard],
  },
  {
    /** This config is needed to ensure we always load results into a modal */
    path: `contest-reveal/result/:${MicroContestRouteParamKey.CONTEST_ID}`,
    redirectTo: `/home(modal:contest-reveal/result/:${MicroContestRouteParamKey.CONTEST_ID})`,
  },
  {
    path: 'external-accounts',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('@brightside-web/micro/manage/external-accounts').then((m) => m.MicroManageExternalAccountsModule),
    canLoad: [AuthGuard, VerificationStatusGuard],
    canActivateChild: [ProductVerificationGuard],
  },
  {
    path: 'financialsnapshot',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('@brightside-web/micro/financial-snapshot').then((m) => m.MicroFinancialSnapshotModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'helping-hands',
    data: { breadcrumb: { skip: true } },
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    loadChildren: () => import('@brightside-web/micro/helping-hands').then((m) => m.MicroHelpingHandsModule),
  },
  {
    path: 'mpap',
    data: { breadcrumb: { skip: true } },
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    loadChildren: () => import('@brightside-web/micro/mpap').then((m) => m.MicroMpapModule),
  },
  {
    path: 'user/creation',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('@brightside-web/micro/user-creation').then((m) => m.MicroUserCreationModule),
    canLoad: [AuthGuard],
  },
  {
    //Please pass the forwarding path in the query param like: ?pageOnForwardCtaPath=<route>
    path: 'loader',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'loader',
        fbCategory: 'pls-loader',
        pageOnForwardCtaPath: [],
      },
    },
  },
  {
    path: 'contest-loader/:contestId',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'loader',
        fbCategory: 'pls-loader',
        pageOnForwardCtaPath: ['contest', 'intro', ':contestId'],
      },
    },
  },
  {
    path: 'financialsnapshot-loader',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'loader',
        fbCategory: 'financial-snapshot',
        pageOnForwardCtaPath: ['financialsnapshot', 'intro'],
      },
    },
  },
];

const routes: Routes = [
  ...microAppRoutes,
  {
    path: 'registration',
    canLoad: [SSOAuthGuard],
    loadChildren: () => import('@brightside-web/desktop/feature/registration').then((m) => m.DesktopFeatureRegistrationModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('@brightside-web/desktop/feature/onboarding').then((m) => m.DesktopFeatureOnboardingModule),
    canLoad: [RequiredVerificationGuard, AuthGuard],
    data: { appDisplayStyle: LayoutStyle.FULL_SCREEN },
  },
  {
    path: 'sso-setup',
    loadChildren: () => import('@brightside-web/desktop/feature/sso-setup').then((m) => m.DesktopFeatureSsoSetupModule),
    canLoad: [AuthGuard],
    data: { appDisplayStyle: LayoutStyle.FULL_SCREEN },
  },
  {
    path: 'loans',
    loadChildren: () => import('@brightside-web/desktop/feature/payroll-loan').then((m) => m.DesktopFeaturePayrollLoanModule),
    canLoad: [AuthGuard, PayrollEligibleGuard, LoanEligibleGuard, VerificationStatusGuard],
    canActivateChild: [ProductVerificationGuard],
    data: { breadcrumb: { label: 'Home', routeInterceptor: () => '/home' } },
  },
  {
    path: 'credit',
    loadChildren: () => import('@brightside-web/desktop/feature/credit').then((m) => m.DesktopFeatureCreditModule),
    canLoad: [AuthGuard],
    data: { breadcrumb: { label: 'Home', routeInterceptor: () => '/home' } },
  },
  {
    path: 'credit-setup',
    loadChildren: () => import('@brightside-web/desktop/feature/credit-setup').then((m) => m.DesktopFeatureCreditSetupModule),
    canLoad: [AuthGuard],
    canActivateChild: [ProductVerificationGuard],
    data: {
      breadcrumb: { label: 'Home', routeInterceptor: () => '/home' },
    },
    resolve: {
      appDisplayStyle: 'CreditLayoutResolver',
    },
  },
  {
    path: 'home',
    loadChildren: () => import('@brightside-web/desktop/feature/home').then((m) => m.DesktopFeatureHomeModule),
    canLoad: [AuthGuard],
    canActivate: [GuidGuard, OnboardingCompleteGuard],
    data: { breadcrumb: 'Home', appDisplayStyle: LayoutStyle.DEFAULT },
  },
  {
    //https://netbasal.com/give-your-modals-url-address-with-auxiliary-routes-in-angular-eb76497c0bca
    path: 'settings',
    outlet: 'modal',
    loadChildren: () => import('@brightside-web/desktop/feature/settings').then((m) => m.DesktopFeatureSettingsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'security',
    outlet: 'modal',
    loadChildren: () => import('@brightside-web/desktop/feature/security').then((m) => m.DesktopFeatureSecurityModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'ways-we-can-help',
    canLoad: [AuthGuard],
    loadChildren: () => import('@brightside-web/desktop/feature/wwch').then((m) => m.DesktopFeatureWwchModule),
    data: { breadcrumb: 'Ways we can help' },
  },
  {
    path: 'verification',
    loadChildren: () => import('@brightside-web/desktop/feature/verification').then((m) => m.DesktopFeatureVerificationModule),
  },
  {
    path: 'program',
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    loadChildren: () => import('@brightside-web/desktop/feature/programs').then((m) => m.DesktopFeatureProgramsModule),
  },
  {
    path: INVITE_ROUTES_PATH,
    canLoad: [AuthGuard],
    children: INVITE_ROUTES,
    data: { breadcrumb: INVITE_BREADCRUMB_LABEL },
  },
  {
    path: DEEP_SAVINGS_ROUTES_PATH,
    canLoad: [AuthGuard],
    children: DEEP_SAVINGS_ROUTES,
    data: { breadcrumb: { label: 'Home', routeInterceptor: () => '/home' } },
  },
  { path: 'sign_in', redirectTo: 'registration/sign_in' },
  { path: 'get_started', redirectTo: 'registration/get_started' },
  { path: 'verify', component: VerifyComponent },
  { path: 'waitlist', component: WaitlistComponent, data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS } },
  {
    path: 'review-app',
    canActivate: [ReviewAppGuard],
    component: DisplayLoaderComponent,
    data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS },
  },
  {
    path: 'setupcredit',
    redirectTo: 'credit-setup/creation' },
  {
    path: 'savings' /* the default card route ? */,
    redirectTo: 'home/savings',
  },
  { path: '**', redirectTo: 'registration/sign_up' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [
    {
      provide: 'CreditLayoutResolver',
      useValue: () => (Cache.getItem('ONBOARDING_FLOW_ACTIVE') === true ? LayoutStyle.FULL_SCREEN : LayoutStyle.DEFAULT),
    },
  ],
})
export class AppRoutingModule {}
