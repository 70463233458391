import { Component, OnInit, Output, Input, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';

export enum CellSeparatorType {
  None = 'none',
  Full = 'full',
  Offset = 'offset',
  Center = 'center',
}

@Component({
  selector: 'bw-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CellComponent implements OnInit, AfterViewInit {
  /**
   * default, but overridable.  Only used for single text variants, or the top label.
   */
  @Input() leftLabelColor = '--primary-text';
  @Input() rightLabelColor = '--primary-text';

  @Input() iconNameLeft = '';
  @Input() labelLeft = '';
  @Input() labelSubLeft = '';
  @Input() singleLeftLabel = '';
  @Input() flipLeftLabels: boolean;
  @Input() contentLeft: boolean;
  @Input() contentRight: boolean;
  @Input() contentSubLeft: boolean;
  @Input() iconNameRight = ''; //chevron_right
  @Input() labelRight = '';
  @Input() labelSubRight = '';

  @Input() showToggleControl = false;

  @Input() separatorType: CellSeparatorType = CellSeparatorType.Full;

  @Output() clicked = new EventEmitter();
  _isClickable = false;

  @ViewChild('cellContainer') cellContainer: ElementRef;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    this._isClickable = this.clicked.observers.length > 0;
  }

  ngAfterViewInit() {
    if (this._isClickable) {
      this.renderer.setAttribute(this.cellContainer.nativeElement, 'tabindex', '0');
    }
  }

  cellClicked() {
    this.clicked.emit();
  }

  getSeparatorClassName() {
    if (this.separatorType === CellSeparatorType.None) {
      return 'hidden';
    }

    if (this.separatorType === CellSeparatorType.Offset) {
      return 'offset-left';
    }

    if (this.separatorType === CellSeparatorType.Center) {
      return 'offset-left offset-right';
    }

    return '';
  }
}
