import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { from, of, Subscription } from 'rxjs';
import { AnalyticsAttributesV4, SupportService } from '@brightside-web/desktop/data-access/shared';
import { Auth } from 'aws-amplify';
//TODO this has a lot of overlap with the hr-modal component, can we reduce this with subclasses/abstraction?
@Component({
  selector: 'brightside-web-call-fa',
  templateUrl: './call-fa.component.html',
  styleUrls: ['./call-fa.component.scss']
})
export class CallFaComponent implements OnInit, OnDestroy {
  @Input() title = 'Call your Financial Assistant';
  @Input() ctaText = 'OK';
  @Input() prompt = 'You can contact a Brightside Financial Assistant for help during regular business hours at this phone number';
  @Input() firebase: AnalyticsAttributesV4;
  // if you pass in a company it will be used, if not, we will attempt to get it from the session
  @Input() company?:string;
  phoneNumber: string;
  sub = new Subscription();
  @Output() dismiss = new EventEmitter();
  loading:boolean;
  constructor(private support:SupportService) { }

  ngOnInit() {
    this.loading = true;
    const obs = this.company ? of({
        attributes: {
          'custom:company':this.company
        }
    }) : from(Auth.currentUserInfo());
    this.sub.add(obs.subscribe(info => {
      if (info) {
        this.company = info.attributes['custom:company'];
      }
    }, _=>{
      this.fallback();
    }, ()=>{
      if (this.company) {
        this.sub.add(this.support.support(this.company).subscribe(response => {
          this.phoneNumber = response;
          this.loading = false;
        }));
      } else {
        this.fallback();
      }
    }));
  }

  //TODO if no session, can we load the company from the domain name?
  fallback(){
    this.phoneNumber = '8559401507';
    this.loading = false;
  }

  emitClick() {
    this.dismiss.emit();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
