import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FirebaseService, SavingsScheduleType } from '@brightside-web/desktop/data-access/shared';

export const FORM_FIELD_NAME = 'selectedFormFrequency';

@Component({
  selector: 'brightside-web-autosave-frequency',
  templateUrl: './autosave-frequency.component.html',
  styleUrls: ['./autosave-frequency.component.scss'],
})
export class AutosaveFrequencyComponent implements OnInit {
  @Input() formGroup: FormGroup;

  startThisSelected = SavingsScheduleType.everyPaycheck;

  constructor(protected analytics: FirebaseService) {}

  ngOnInit() {
    const selected = this.formGroup.get(FORM_FIELD_NAME)?.value as SavingsScheduleType;

    if (selected) {
      this.startThisSelected = selected;
    }
  }

  handleRadioClicked(value: SavingsScheduleType) {
    this.formGroup.get(FORM_FIELD_NAME)?.setValue(value);
  }
}
