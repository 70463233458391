<div *ngIf="!hideCard" class="card mb-md mr-md">
  <ng-container [ngSwitch]="displayType">
    <bw-card
      *ngSwitchCase="displayTypes.ACTIVE_DEFAULT"
      cardType="promo"
      [cardTextColor]="card.closeable ? 'var(--primary-text)' : 'var(--blue-70)'"
      [title]="card.title | translate"
      [bgImageSrc]="card.graphic"
      [closeable]="card.closeable"
      (closeCard)="completeCardHandler()"
    >
      <bw-card-body>{{ card.subText | translate }}</bw-card-body>
      <bw-card-footer>
        <button *ngIf="card.cta && card.closeable" bw-outlined-button (click)="ctaHandler($event)">{{ card.cta | translate }}</button>
        <button *ngIf="card.cta && !card.closeable" bw-button (click)="ctaHandler($event)">{{ card.cta | translate }}</button>
      </bw-card-footer>
    </bw-card>

    <bw-card *ngSwitchCase="displayTypes.ACTIVE" cardType="active-product" [title]="card.title | translate" (cardAction)="ctaHandler($event)">
      <bw-card-header>
        <ng-container *ngFor="let segment of infoSegments">
          <div class="flex-row pointer" (click)="bannerHandler($event)">
            <typography text-small-regular class="disabled-text mr-xs">{{ segment.title | translate }}</typography>
            <!-- the icon is not stored in the segment anywhere so we will presume to show it when the cta is for a modal -->
            <bw-icon
              *ngIf="segment.ctaPath && segment.ctaPath.includes('modal:')"
              size="16"
              iconName="information"
              color="var(--disabled-text)"
            ></bw-icon>
          </div>
        </ng-container>
      </bw-card-header>
      <bw-card-body>
        <div class="flex-row space-between pointer">
          <ng-container *ngFor="let segment of defaultSegments">
            <div class="flex-row">
              <bw-icon [iconName]="segment.icon" horizontalSpace="mr-sm" color="var(--brand)"></bw-icon>
              <typography text-large-regular>{{ segment.title | translate }}</typography>
            </div>
            <typography *ngIf="segment.type === 'Cta'; else dynamicValueDisplay" text-large-semibold>
              <a bw-anchor [routerLink]="segment.ctaPath" class="bs-blue brand pr-xl">View</a>
            </typography>

            <ng-template #dynamicValueDisplay>
              <typography text-large-regular>{{ segment.dynamicValue | translate }}</typography>
            </ng-template>
          </ng-container>
        </div>
        <div class="flex-row space-between pointer">
          <ng-container *ngFor="let segment of ctaSegments">
            <div class="flex-row">
              <bw-icon
                size="32"
                [iconName]="segment.icon"
                horizontalSpace="mr-sm"
                color="var(--brand)"
                backgroundColor="var(--bg-gray)"
              ></bw-icon>
              <div class="flex-column">
              <typography text-small-semibold color="var(--brand)">{{ segment.title | translate }}</typography>
                <typography text-small-regular>{{ segment.subText | translate }}</typography>
              </div>
            </div>
          </ng-container>
        </div>
      </bw-card-body>
      <bw-card-banner [cardBannerColor]="cardBannerColor" *ngIf="errorFooter" (click)="errorStateClickHandler($event)">
        <div class="flex-row" [ngClass]="{'pointer': errorFooter.ctaPath}">
          <div class="flex-row">
            <bw-icon iconName="alert" size='16' color="var(--red-50)" horizontalSpace="mr-xs"></bw-icon>
            <typography text-small-regular class="primary-text">{{ errorFooter.title | translate }}</typography>
          </div>
        </div>
      </bw-card-banner>
    </bw-card>

    <bw-card *ngSwitchCase="displayTypes.ACTIVE_LOCKED" cardType="info" [title]="card.title | translate" (click)="clickHandler()">
      <bw-card-body
        ><div class="flex-row">
          <bw-icon iconName="lock" horizontalSpace="mr-sm"></bw-icon
          ><typography class="disabled-text">Only available in our mobile app</typography>
        </div></bw-card-body
      >
    </bw-card>

    <bw-card cardType="action" *ngSwitchDefault [title]="(card.titleKey ?? card.title) | translate" (cardAction)="clickHandler()">
      <bw-card-body>{{ (card.textKey ?? card.text) | translate }}</bw-card-body>
<!--      <bw-card-body>{{ !card.textKey ? (card.text | translate) : card.textKey | translate }}</bw-card-body>-->
    </bw-card>
  </ng-container>
</div>

<bw-video
  *ngIf="displayType === displayTypes.ACTIVE_DEFAULT && showVideoModal"
  [show]="showVideoModal"
  [title]="card.title | translate"
  [cdnBase]="cdnBase"
  [path]="card.ctaPath"
  (modalClosed)="showVideoModal = false"
></bw-video>
<brightside-web-generic-modal
  *ngIf="bannerModalVisible"
  [model]="modalModel"
  fbPage="home"
  fbCategory="home"
  fbAction="view_card_banner_modal"
  (primaryClick)="bannerModalVisible = false"
  (secondaryClick)="bannerModalVisible = false"
></brightside-web-generic-modal>
