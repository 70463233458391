import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  /** note this class assumes that the desktop app module has already loaded in the Intercom module, it will not work otherwise **/
  constructor(private intercom:Intercom) {
  }

  openIntercomChat(){
      this.intercom.show();
  }
}
