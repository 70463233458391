import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { MicroRouteFindDataByKey } from '@micro-core/utility';

import {
  MicroContestAppService,
  MicroContestAppResultsResponse,
  MicroContestAppResponse,
} from '../../service/micro-contest-app.service';

import { MicroContestRouteDataKey, MicroContestRouteParamKey } from '../../model/contest.enum';

type ReturnResolveType = MicroContestAppResultsResponse;

@Injectable()
export class MicroContestResultsResolver implements Resolve<ReturnResolveType> {
  private _contestDetails: MicroContestAppResponse | undefined;

  constructor(private microContestAppService: MicroContestAppService) {}

  private reset() {
    this._contestDetails = undefined;
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const contestId: number =
      routeSnapshot.queryParams[MicroContestRouteParamKey.CONTEST_ID] ||
      routeSnapshot.params[MicroContestRouteParamKey.CONTEST_ID] ||
      1;
    const body = { contestDate: '' };

    this._contestDetails = MicroRouteFindDataByKey<MicroContestAppResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_DETAIL
    );

    if (this._contestDetails?.data?.previousDrawingDate) {
      body.contestDate = this._contestDetails.data.previousDrawingDate;
    }

    return this.microContestAppService.getResults({ id: contestId, body }).pipe(finalize(() => this.reset()));
  }
}
