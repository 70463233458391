import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Cache } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class VerifyDobGuard implements CanActivate {
  constructor(protected router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Cache.getItem('lastName') && Cache.getItem('last4ssn')) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

}
