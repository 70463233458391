<bw-action-article
  [pinnedFooter]="false"
  [minHeight]="minHeight"
  [brightsideWebFb]="{ event_name: 'employee_id_collect'}"
*ngIf="company">
    <bw-back
      *ngIf="displayBackButton"
      back-action-bottom
      [emit]="true"
      (back)="handleBackClick()"
    ></bw-back>
    <div header-title class="mb-sm">
      <typography *ngIf="companyReload" title-medium>{{ 'ALIAS_COLLECT_TITLE' | translate }}</typography>
    </div>
    <div *ngIf="companyReload" content-section>
      <p text-large-regular class="mb-md" color="var(--secondary-text)">
        {{ 'ALIAS_COLLECT_DESC' | translate }}
      </p>
      <form *ngIf="isFormReady" [formGroup]="employeeAliasForm" (submit)="onSubmit()">
        <div class="flex-row flex-start flex-wrap mb-sm">
          <div class="mr-sm mb-sm flex-item-flexible max-form-width">
            <bw-input
              [isCustomControl]="true"
              label="{{ 'ALIAS_TITLE' | translate }}"
              [inputError]="true"
              [inputErrorText]="errorTextKey | translate ">
              <input type="text" class="body" formControlName="uniqueId" />
            </bw-input>
            <ng-container *ngIf="uniqueIdFormElement.aliasValidatorRules.consentsKey">
              <bw-checkbox formControlName="consentItem">
                <typography *ngIf="('ALIAS_CONSENT_TITLE' | translate) !== ' '" text-medium-semibold color="var(--primary-text)">
                  {{ 'ALIAS_CONSENT_TITLE' | translate }}
                </typography>
                <typography text-medium-regular color="var(--secondary-text)"
                [innerHTML]="'ALIAS_CONSENT_DESC' | translate:{linkPath: consentPath}"></typography>
              </bw-checkbox>
            </ng-container>
          </div>

        </div>
      </form>
      <bw-caption [centerAlign]="centerAlignCaption" verticalSpace="24">
        <a
          bw-anchor
          anchorStyle="text-small-regular"
          anchorUnderline="true"
          target="_blank"
          (click)="showBadgeHelpModal = true"
          [innerHTML]="'BADGE_ID_HELP' | translate"
        ></a>
      </bw-caption>
    </div>
    <div footer-actions class="flex-column full-width">
      <typography *ngIf="('ALIAS_FOOTER_DESC' | translate) !== ' '"
        text-small-regular
        color="var(--secondary-text)"
        style="margin-bottom: 24px">
        {{ 'ALIAS_FOOTER_DESC' | translate }}
      </typography>
      <button *ngIf="isFormReady && !disableSubmit"
        bw-button
        [processing]="processing"
        [disabled]="employeeAliasForm?.invalid"
        (click)="onSubmit()"
        [brightsideWebFb]="{ event_name: 'employee_id_collect'}">
        {{ submitCtaCopy }}
      </button>
      <ng-container *ngIf="disableSubmit">
        <button
                bw-button
                [processing]="processing"
                disabled="true"
                style="background-color: var(--blue-20)">
          {{ submitCtaCopy }}
        </button>
      </ng-container>
    </div>

</bw-action-article>

<bw-modal *ngIf="showBadgeHelpModal"
          (closeModal)="showBadgeHelpModal = false; analytics.logEvent('employee_id_moreinfo_dismissed')"
          [brightsideWebFb]="{ event_name: 'employee_id_moreinfo' }">
  <typography title-medium color="var(--gray-70)">{{ 'BADGE_ID_HELP_HALFSHEET_TITLE' | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ 'BADGE_ID_HELP_HALFSHEET_DESC' | translate }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="showBadgeHelpModal = false"
        [brightsideWebFb]="{ event_name: 'employee_id_moreinfo' }">
        {{ 'GOT_IT' | translate }}
      </button>
    </div>
  </footer>
</bw-modal>

<bw-modal
  *ngIf="showErrorModal"
  (closeModal)="showErrorModal = false"
  [brightsideWebFb]="{event_name: 'error_shown', custom: {'error id': displayError.errorId }}"
  [error_view]="true">
  <typography title-medium color="var(--gray-70)">
    {{ displayError.errorTitleKey | translate }}
  </typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ displayError.errorDescKey | translate:{phone:supportNumber|phone} }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="hideErrorModal()"
        [brightsideWebFb]="{ event_name: displayError.errorId }">
        {{ displayError.ctaKey | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
