import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import { ApiCacheService} from './api-cache.service';
import {
  ApiResponse,
  MobileStateResponse,
  MobileStateActionRequest,
} from './api-contracts';
import { Auth, API, Cache } from 'aws-amplify';
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {EligibleProduct} from "./api-enums";

@Injectable({
  providedIn: 'root',
})
export class MobileStateService {

  private _mobileState: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public readonly mobileState: Observable<string> = this._mobileState.asObservable();

  constructor(private apiCache: ApiCacheService) {}

   getData() {
     const error = new HttpErrorResponse({status: 422});
    return of(error) as any;
  }
  get(force = false): Observable<MobileStateResponse> {
    if (force){
      this.apiCache.refreshItem('/client/mobilestate');
    }

    return this.apiCache.get<MobileStateResponse>('api-mobile', `/client/mobilestate`)
      .pipe(
        tap( ms => {
          this._mobileState.next(JSON.stringify(ms));
        }),
        catchError(err=>of({
          supportNumber: '8559401507',
          home: defaultHome,
        }))
      );
  }

  put(requestBody: MobileStateActionRequest): Observable<ApiResponse> {
    Cache.removeItem('/client/mobilestate');
    return from(API.put('api-mobile', `/client/mobilestate`, { body: requestBody }));
  }

  getProductOnboardingEligibility(product:EligibleProduct):Observable<boolean>{
    return this.get().pipe(
      map(mobileState => Boolean(mobileState.products?.includes(product)))
    )
  }
}

const defaultHome = {
  "waysWeCanHelp": [
    {
      "title": "Credit score help",
      "toolText": "Connect your credit report for personalized insights",
      "text": "We'll help you understand and create a plan for your credit",
      "icon": "gauge",
      "ctaPath": "topic/topics/creditScore.html",
      "key": "creditScore"
    },
    {
      "title": "Money emergencies",
      "icon": "help_ring",
      "text": "We'll help you find the right solution for your emergency",
      "ctaPath": "topic/topics/emergencyMoney.html",
      "key": "emergencyMoney"
    },
    {
      "title": "Reducing debt",
      "text": "We'll help you make a plan to pay down your debt",
      "icon": "credit_card",
      "ctaPath": "topic/topics/debtReduction.html",
      "key": "debtReduction"
    },
    {
      "icon": "money_bag",
      "text": "We make it easy to start saving regularly and reach your goals",
      "toolText": "Get rewards for saving directly from your paycheck",
      "title": "Saving money",
      "ctaPath": "topic/topics/savings.html",
      "key": "savings"
    }
  ],
  "financialTools": [
    {
      "icon": "money_bag",
      "text": "Get rewards for saving directly from your paycheck",
      "title": "Saving money",
      "key": "savings",
      "ctaPath": "chat"
    },
    {
      "title": "Credit score help",
      "text": "Connect your credit report for personalized insights",
      "icon": "gauge",
      "key": "creditScore",
      "ctaPath": "chat"
    },
    {
      "title": "Spending account",
      "icon": "help_ring",
      "text": "Set up a spending account to get advances on your paycheck",
      "key": "spendingAccount",
      "ctaPath": "chat"
    }
  ]
};
