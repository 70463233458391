import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType } from '@micro-ui/template/content';
import { UiTemplateAction, UiTemplateFlyerDataBuilder, UiTemplateNavType } from '@micro-ui/template/page';

import { MicroContestAnalyticsEventName } from '../../../model/contest.enum';
import RULES_DATA from '../../../assets/pls-rules.json';

const TemplateContentFromFile =
  RULES_DATA?.inputs.content.formatAndRepeat.map((contentLine: string) => ({
    type: UiTemplateContentType.TITLE_TEXT,
    data: {
      style: 'terms',
      title: '',
      subTitle: contentLine,
    },
  })) || [];
const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.CLOSE,
    header: {
      type: UiHeaderType.EYE_BROW,
      title: RULES_DATA?.inputs.header.title || '',
      subTitle: RULES_DATA?.inputs.header.subTitle || '',
      icon: '',
    },
    content: TemplateContentFromFile,
    viewName: MicroContestAnalyticsEventName.ENTRY_RULES,
  },
  actions: {
    [UiTemplateAction.navLeftIcon]: { route: ['../'] },
  },
});

export default TemplateData;
