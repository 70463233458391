import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

import {Subscription} from "rxjs";
import {
  UniqueIDFormElement,
  DisplayFormControlConfig
} from "@brightside-web/shared/desktop";
import {EmployeeAliasPayload, RegistrationService} from "@brightside-web/desktop/data-access/onboarding";
import {Router} from "@angular/router";
import {
  AmplitudeService, BadgeIDInterface, CompanyService, FirebaseService,
  ApiResponse, ProfileService, TermsService, ClientConfirmationService, CacheStorageService, ClientConfirmationErrorCode
} from "@brightside-web/desktop/data-access/shared";

import {Cache} from "aws-amplify";
import * as moment from "moment";
import {Environment} from "@brightside-web/micro/core/environment";

@Component({
  selector: 'brightside-web-badge-id-form',
  templateUrl: './badge-id-form.component.html',
  styleUrls: ['./badge-id-form.component.scss']
})
export class BadgeIdFormComponent {

  @Input() minHeight = 576;
  @Input() centerAlignCaption = true;
  @Input() displayBackButton = false;
  @Input() submitCtaCopy = 'Continue';

  @Output() aliasSubmitted: EventEmitter<BadgeIDInterface> = new EventEmitter<BadgeIDInterface>();
  @Output() backClicked:  EventEmitter<boolean> = new EventEmitter<boolean>();

  _company = '';

  get company() : string {
    return this._company;
  };

  set company(value: string) {
    this._company = value;
    this.companyReloaded();
    this.setUpRulesAndForm();
  };

  sub = new Subscription();
  formSub = new Subscription();

  companyReload = true;
  employeeAliasForm: FormGroup;


  uniqueIdFormElement : UniqueIDFormElement;

  controls: any[];
  controlConfig: DisplayFormControlConfig;

  payload: EmployeeAliasPayload;
  maxRetriesExceeded: boolean;
  alias: string;
  maxRetry = 3;

  disableSubmit = false;
  processing = false;
  showProcessError = false;
  processInterval: any;

  errorTextKey = '';
  isInvalid = true;
  showBadgeHelpModal = false;

  isFormReady = false;

  aliasValidatorWithRules: ValidatorFn;

  showErrorModal: boolean;
  displayError: ClientConfirmationErrorCode;
  supportNumber: string;// = Cache.getItem('supportNumber');
  canContinue = false;
  cdnUrl: string;
  consentPath: string;


  constructor(
    private service: RegistrationService,
    private router: Router,
    private analytics: AmplitudeService,
    private firebase: FirebaseService,
    private companyService: CompanyService,
    private profileService: ProfileService,
    private termsService: TermsService,
    private env: Environment,
    private clientConfirmationService: ClientConfirmationService,
    private cacheStorageSvc: CacheStorageService
  ) {
    this.supportNumber = this.cacheStorageSvc.getItem('supportNumber');
  this.uniqueIdFormElement = new UniqueIDFormElement(firebase);
  this.controls = [this.uniqueIdFormElement.CONTROL_UNIQUE_ID];
  this.controlConfig = this.uniqueIdFormElement.CONTROL_UNIQUE_ID;


  this.maxRetriesExceeded = Cache.getItem('uniqueIdRetriesExceeded');
  if (this.maxRetriesExceeded) {
    // if max retries reached, we shouldn't allow user to proceed after refresh
    this.showMaxRetriesModal();
  }
  this.cdnUrl = env.cdnUrl;
  this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value) this.company = value;
        }
      )
    )
}


ngOnDestroy() {
  clearInterval(this.processInterval);
  this.sub.unsubscribe();
  this.formSub.unsubscribe();
}

companyReloaded() {
  this.companyReload = false;
  setTimeout(()=>{
    this.companyReload = true;
  }, 0);
};

setUpRulesAndForm() {
  this.buildFormFromConfig();
}


  private buildFormFromConfig() {
  const controls: { [key: string]: FormControl } = {};

  this.aliasValidatorWithRules = this.aliasValidatorWithRules ? this.aliasValidatorWithRules : this.uniqueIdFormElement.aliasValidator(this.company);

    if (this.controls) {
      this.controls.forEach((controlConfig: DisplayFormControlConfig) => {
        controls[controlConfig.key] = controlConfig.control;
      });
      if (this.uniqueIdFormElement.aliasValidatorRules.consentsKey) {
        controls['consentItem'] = new FormControl('', [Validators.requiredTrue]);
        this.consentPath = `${this.cdnUrl}terms/${this.uniqueIdFormElement.aliasValidatorRules.consentsKey}.pdf`;
      }
    }
    this.employeeAliasForm = new FormGroup(controls);

    const uniqueIdControl = this.employeeAliasForm.get(this.uniqueIdFormElement.CONTROL_UNIQUE_ID.key);

    if (uniqueIdControl && !uniqueIdControl.hasValidator(this.aliasValidatorWithRules)) {
      uniqueIdControl.addValidators(this.aliasValidatorWithRules);
      uniqueIdControl.updateValueAndValidity();
      this.formSub.add(
        uniqueIdControl.statusChanges.subscribe(
          value => {
            if (value.toLowerCase() === 'invalid') {
              this.errorTextKey = uniqueIdControl.errors?.errorTextKey;
            } else {
              this.errorTextKey = '';
            }
          }
        )
      );
    }
    this.isFormReady = true;
  }

  onSubmit() {
    if (this.processing || this.disableSubmit) {
      return;
    }

    this.processing = true;
    this.alias = this.employeeAliasForm.get(this.uniqueIdFormElement.CONTROL_UNIQUE_ID.key)?.value.split('@').join('');

    if (this.uniqueIdFormElement.aliasValidatorRules.consentsKey) {
      this.sub.add(
        this.termsService.acceptTerms(this.uniqueIdFormElement.aliasValidatorRules.consentsKey).subscribe(
          response => {
            this.submitUniqueId();
          },
          error => {
            this.submitStatusCheck({statusCode: 400, message: ''});
          }
        )
      )
    } else {
      this.submitPreCheck();
    }
  }

  submitPreCheck() {
    if (this.alias) {
      this.sub.add(
        this.service.submitEmployeeAliasPrecheck(this.alias).subscribe(
          response => {
            if (response.statusCode === 200) {
              this.submitUniqueId();
            } else {
              this.submitStatusCheck(response);
            }
          },
          error => {
            const response = error.response.data;
            this.submitStatusCheck(response);
          }
        )
      )
    }
  }

  submitUniqueId() {
   if (this.alias) {
     this.sub.add(
       this.service.submitEmployeeAlias(this.alias).subscribe(
         response => {
           this.submitStatusCheck(response);
         },
         error => {
           const response = error.response.data;
           this.submitStatusCheck(response);
         }
       )
     )
   }
  }
  hideErrorModal () {
    if (this.canContinue) {
      this.processing = true;
      this.submitUniqueId();
    } else {
      this.processing = false;
      this.showErrorModal = false;
    }
    this.showErrorModal = false;
  }

  showMaxRetriesModal() {

    Cache.setItem('uniqueIdRetriesExceeded', true, { expires: moment().add(6000, 'seconds').valueOf() });
    this.displayError = this.canContinue ? this.clientConfirmationService.errors['maxSoft'] : this.clientConfirmationService.errors['max'];
    this.disableSubmit = true;
    this.showErrorModal = true;
  }

  submitStatusCheck(response: ApiResponse) {
    // switch (response.statusCode) {
    //   case 200: case 409:
    //     this.aliasSubmitted.emit({status: 'success', response: response});
    //     break;
    //   case 402: case 403:
    //     this.displayError = this.uniqueIdFormElement.errors[response.statusCode];
    //     this.uniqueIdFormElement.errors[response.statusCode].errorCount++;
    //     this.showErrorModal = true;
    //     break;
    //   case 404:
    //
    //     this.displayError = this.uniqueIdFormElement.errors[404];
    //     this.uniqueIdFormElement.errors[404].errorCount++;
    //     this.maxRetriesExceeded = this.uniqueIdFormElement.errors[404].errorCount >= this.maxRetry;
    //
    //     if (this.maxRetriesExceeded && this.uniqueIdFormElement.aliasValidatorRules.allowDoesNotExist) {
    //       this.canContinue = true;
    //       this.showMaxRetriesModal();
    //       break;
    //     } else if (this.maxRetriesExceeded) {
    //       this.showMaxRetriesModal();
    //       break;
    //     }
    //     this.showErrorModal = true;
    //     break;
    //   default:
    //     this.showProcessErrorMessage();
    //     break;
    // }
  }

  handleBackClick() {
    this.analytics.logEvent('employee_id_collect_dismissed');
    this.backClicked.emit(true);
  }

  showProcessErrorMessage() {
    this.canContinue = false;
    this.processing = false;
    this.displayError = this.clientConfirmationService.errors['process'];
    this.showErrorModal = true;
    this.disableSubmit = false;
  }

}
