import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify, { Auth } from 'aws-amplify';

import { initializeApp } from "firebase/app"
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import { getAnalytics, setUserProperties } from "firebase/analytics";

import amplitude from 'amplitude-js';
import { RemoteConfig_SupportNumber } from '@brightside-web/desktop/data-access/shared';

const getSubDomain = () => {
  const hostnameSplit = window.location.hostname.toLowerCase().split('.');
  const tldDomain = hostnameSplit[hostnameSplit.length - 2];
  if (hostnameSplit[0] === 'app' || tldDomain !== 'gobrightside') {
    return '';
  } else {
    return hostnameSplit[0];
  }
};

const getEnvironment = () => {
  const hostname = window.location.hostname.toLowerCase();

  if (environment.production) {
    return 'prod';
  } else if (hostname.includes('stage')) {
    return 'stage';
  } else {
    return 'dev2';
  }
};

const firebaseApp = initializeApp(environment.firebase);
const remoteConfig = getRemoteConfig(firebaseApp);
const fbAnalytics = getAnalytics(firebaseApp);

amplitude.getInstance().init(environment.amplitude.apiKey, undefined, {
  includeUtm: true,
  includeGclid: true,
  includeFbclid: true,
  includeReferrer: true,
});

amplitude.getInstance().setVersionName(environment.appVersion);


// default configs for RemoteConfig.
remoteConfig.defaultConfig = {
  supportNumber: '8559401507',
  bankHolidays:
    '2021-09-06,2021-10-11,2021-11-11,2021-11-25,2022-01-17,2022-02-21,2022-05-30,2022-06-20,2022-07-04,2022-09-05,2022-10-10,2022-11-11,2022-11-24,2022-12-26,2023-01-02,2023-01-16,2023-02-20,2023-05-29,2023-06-19,2023-07-04,2023-09-04,2023-10-09,2023-11-23,2023-12-25,2024-01-01,2024-01-15,2024-02-19,2024-05-27,2024-06-19,2024-07-04,2024-09-02,2024-10-14,2024-11-11,2024-11-28,2024-12-25,2025-01-01,2025-01-20,2025-02-17,2025-05-26,2025-06-19,2025-07-04,2025-09-01,2025-10-13,2025-11-11,2025-11-27,2025-12-25',
  unlockedCaseTypes: 'Emergency_Savings,Emergency_Cash,Credit_Score',
};

const company = getSubDomain();
const properties: any = {};
if (company) {
  properties['company'] = company;
}
const env = getEnvironment();
if (env) {
  properties['environment'] = env;
}


setUserProperties(fbAnalytics, properties);

remoteConfig.settings.minimumFetchIntervalMillis = environment.production ? 3000 : 0;

fetchAndActivate(remoteConfig).then(() => {
  console.log(`Support number: ${getValue(remoteConfig, RemoteConfig_SupportNumber).asString()}`);
});

const getTimeZone = () => {
  const formatParts = Intl.DateTimeFormat('en', { timeZoneName: 'short' }).format().split(' ');

  return formatParts[formatParts.length - 1];
};

const getBrowser = () => {
  const userAgent = navigator.userAgent;
  const browserMapping: { [key: string]: { validMatch: string[]; extraChecks?: Function[] } } = {
    Opera: {
      validMatch: ['Opera', 'OPR'],
    },
    Chrome: {
      validMatch: ['Chrome'],
    },
    Safari: {
      validMatch: ['Safari'],
    },
    Firefox: {
      validMatch: ['Firefox'],
    },
    IE: {
      validMatch: ['MSIE'],
      extraChecks: [() => Object.keys(document).includes('documentMode')],
    },
  };

  return (
    Object.keys(browserMapping).filter((key: string) => {
      const checkDetails = browserMapping[key];

      if (checkDetails.validMatch.some((browserName) => userAgent.includes(browserName))) {
        //If there are extra checks, make sure they all return true
        if (checkDetails.extraChecks && !checkDetails.extraChecks.every((checkFunc) => checkFunc())) {
          return false;
        }

        return true;
      }

      return false;
    })[0] || 'Unknown'
  );
};

// does anybody know why we are not simply using a UUID library to generate a UUID???
const generateUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
// tslint:enable:no-bitwise

const getDeviceId = () => {
  //don't want to use SessionStorage here.
  let deviceId = window.localStorage['DEVICE_ID'];
  if (!deviceId) {
    deviceId = generateUUID();
    window.localStorage['DEVICE_ID'] = deviceId;
  }
  return deviceId;
};

const getStandardHeaders = () => {
  const headers: { [key: string]: any } = {
    'Content-Type': 'application/json',
    'X-BS-OS-Name': 'Desktop',
    'X-BS-Model': getBrowser(),
    'X-BS-Timezone': getTimeZone(),
    'X-BS-GmtOffset': `${new Date().getTimezoneOffset() / -60}`,
    'X-BS-DeviceId': getDeviceId(),
  };

  return headers;
};

//sso domain for hosted ui
const domain = environment.hostedDomain;

//sso callback
let location = window.location.origin;
if (!location.endsWith('/')) {
  location = `${location}/`;
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module) => {
    //The code is needed to support the native application sending bridge events
    //into the message bus for the micro application
    window.app = module;
  })
  .catch((err) => console.error(err));

// allow both formats.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-bitwise
const authenticationFlowType = 'ALLOW_REFRESH_TOKEN_AUTH' | 'ALLOW_USERNAME_PASSWORD';

const translateDomain = (domainPart: string) => `${domainPart}.auth.us-west-2.amazoncognito.com`;

Amplify.configure({
  Auth: {
    region: environment.awsmobile.aws_cognito_region,
    userPoolId: environment.awsmobile.aws_user_pools_id,
    userPoolWebClientId: environment.awsmobile.aws_user_pools_web_client_id,
    authenticationFlowType,
    storage: window.sessionStorage,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // SSO config.
    oauth: {
      domain: translateDomain(domain),
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: location,
      redirectSignOut: location,
      responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: {
      myCustomKey: 'myCustomValue',
      platform: 'desktop',
    },
  },
  API: {
    endpoints: [
      {
        ...environment.awsmobile.endpoints['api-mobile'],
        custom_header: async () => ({
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          ...getStandardHeaders(),
        }),
      },
      {
        ...environment.awsmobile.endpoints['api-mobile-noauth'],
        custom_header: async () => ({
          ...getStandardHeaders(),
        }),
      },
      {
        ...environment.awsmobile.endpoints['cdn-mobile-noauth'],
      },
    ],
  },
  Cache: {
    storage: window.sessionStorage,
  },
});
