import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoSavingsAccountGuard implements CanActivate {
  /**
   * No Savings Account means no functional savings account
   * @param service
   * @param router
   */
  constructor(private service:SavingsAccountService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.service.hasFunctionalSavingsAccount().pipe(
      map(resp => {
        if (!resp){
          return true;
        }
        this.router.navigate(next.data.guardRedirect?.route || ['home','savings']);
        return false;
      })
    );
  }

}
