import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentFormType } from '@micro-ui/template/content';
import {
  GetUiTemplateActionRoute,
  UiTemplateAction,
  UiTemplateActionUpdateStateFunctionType,
  UiTemplateFormFlyerDataBuilder,
  UiTemplateNavType,
  UiTemplateState,
} from '@micro-ui/template/page';

import {
  MicroContestAnalyticsEventName,
  MicroContestTranslationKey,
  MicroContestRouteModalKey,
  MicroContestFormControlKey,
} from '../../../model/contest.enum';

const TemplateData = UiTemplateFormFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.NONE,
    header: {
      type: UiHeaderType.DEFAULT,
      title: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE,
      icon: '',
    },
    formContent: [
      {
        type: UiTemplateContentFormType.SELECT_AMOUNT,
        data: {
          groupName: MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM,
          label: 'Per day',
          value: 5,
          increment: 1,
        },
      },
    ],
    cta1Label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1,
    cta2Label: MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2,
    viewName: MicroContestAnalyticsEventName.ENTRY_AUTOSAVE_CUSTOM,
  },
  actions: {
    [UiTemplateAction.cta1]: GetUiTemplateActionRoute.MODAL_OPEN(MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION),
    [UiTemplateAction.cta2]: (state?: UiTemplateState, updateState?: UiTemplateActionUpdateStateFunctionType) => {
      if (state?.state_child_modal) {
        state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM] = undefined;

        if (updateState) {
          updateState(state);
        }
      }

      return GetUiTemplateActionRoute.MODAL_CLOSE();
    },
  },
});

export default TemplateData;
