export enum MicroContestTranslationKey {
  CONTEST_ENTRY_TITLE = 'CONTEST_ENTRY_TITLE',
  CONTEST_ENTRY_SUB_TITLE = 'CONTEST_ENTRY_SUB_TITLE',
  CONTEST_ENTRY_EXTRA1_TITLE = 'CONTEST_ENTRY_EXTRA1_TITLE',
  CONTEST_ENTRY_EXTRA1_SUB_TITLE = 'CONTEST_ENTRY_EXTRA1_SUB_TITLE',
  CONTEST_ENTRY_EXTRA1_ICON = 'CONTEST_ENTRY_EXTRA1_ICON',
  CONTEST_ENTRY_EXTRA2_TITLE = 'CONTEST_ENTRY_EXTRA2_TITLE',
  CONTEST_ENTRY_EXTRA2_SUB_TITLE = 'CONTEST_ENTRY_EXTRA2_SUB_TITLE',
  CONTEST_ENTRY_EXTRA2_ICON = 'CONTEST_ENTRY_EXTRA2_ICON',
  CONTEST_ENTRY_EXTRA3_TITLE = 'CONTEST_ENTRY_EXTRA3_TITLE',
  CONTEST_ENTRY_EXTRA3_SUB_TITLE = 'CONTEST_ENTRY_EXTRA3_SUB_TITLE',
  CONTEST_ENTRY_EXTRA3_ICON = 'CONTEST_ENTRY_EXTRA3_ICON',
  CONTEST_ENTRY_FOOTER_LINK = 'CONTEST_ENTRY_FOOTER_LINK',
  CONTEST_ENTRY_CTA1 = 'CONTEST_ENTRY_CTA1',
  CONTEST_ENTRY_RULES_TITLE = 'CONTEST_ENTRY_RULES_TITLE',
  CONTEST_ENTRY_RULES_SUB_TITLE = 'CONTEST_ENTRY_RULES_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_TITLE = 'CONTEST_ENTRY_AUTOSAVE_TITLE',
  CONTEST_ENTRY_AUTOSAVE_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CTA1 = 'CONTEST_ENTRY_AUTOSAVE_CTA1',
  CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1 = 'CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1',
  CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2 = 'CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1 = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1',
  CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2 = 'CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2',
  CONTEST_ENTRY_COMPLETE_TITLE = 'CONTEST_ENTRY_COMPLETE_TITLE',
  CONTEST_ENTRY_COMPLETE_SUB_TITLE = 'CONTEST_ENTRY_COMPLETE_SUB_TITLE',
  CONTEST_ENTRY_COMPLETE_CTA1 = 'CONTEST_ENTRY_COMPLETE_CTA1',
  CONTEST_RESULT_TITLE = 'CONTEST_RESULT_TITLE',
  CONTEST_RESULT_SUB_TITLE = 'CONTEST_RESULT_SUB_TITLE',
  CONTEST_RESULT_EXTRA_TITLE = 'CONTEST_RESULT_EXTRA_TITLE',
  CONTEST_RESULT_EXTRA_SUB_TITLE = 'CONTEST_RESULT_EXTRA_SUB_TITLE',
  CONTEST_RESULT_CTA1 = 'CONTEST_RESULT_CTA1',
  CONTEST_WON_TITLE = 'CONTEST_WON_TITLE',
  CONTEST_WON_SUB_TITLE = 'CONTEST_WON_SUB_TITLE',
  CONTEST_WON_EXTRA_TITLE = 'CONTEST_WON_EXTRA_TITLE',
  CONTEST_WON_EXTRA_SUB_TITLE = 'CONTEST_WON_EXTRA_SUB_TITLE',
  CONTEST_WON_CTA1 = 'CONTEST_WON_CTA1',
  CONTEST_LOST_TITLE = 'CONTEST_LOST_TITLE',
  CONTEST_LOST_SUB_TITLE = 'CONTEST_LOST_SUB_TITLE',
  CONTEST_LOST_EXTRA_TITLE = 'CONTEST_LOST_EXTRA_TITLE',
  CONTEST_LOST_EXTRA_SUB_TITLE = 'CONTEST_LOST_EXTRA_SUB_TITLE',
  CONTEST_LOST_CTA1 = 'CONTEST_LOST_CTA1',
  CONTEST_MORE_TITLE = 'CONTEST_MORE_TITLE',
  CONTEST_MORE_CONTENT_ONE_TITLE = 'CONTEST_MORE_CONTENT_ONE_TITLE',
  CONTEST_MORE_CONTENT_ONE_SUB_TITLE = 'CONTEST_MORE_CONTENT_ONE_SUB_TITLE',
  CONTEST_MORE_CONTENT_TWO_TITLE = 'CONTEST_MORE_CONTENT_TWO_TITLE',
  CONTEST_MORE_CONTENT_TWO_SUB_TITLE = 'CONTEST_MORE_CONTENT_TWO_SUB_TITLE',
  CONTEST_MORE_CONTENT_THREE_TITLE = 'CONTEST_MORE_CONTENT_THREE_TITLE',
  CONTEST_MORE_CONTENT_THREE_SUB_TITLE = 'CONTEST_MORE_CONTENT_THREE_SUB_TITLE',
  CONTEST_MORE_CONTENT_FOUR_TITLE = 'CONTEST_MORE_CONTENT_FOUR_TITLE',
  CONTEST_MORE_CONTENT_FOUR_SUB_TITLE = 'CONTEST_MORE_CONTENT_FOUR_SUB_TITLE',
  CONTEST_MORE_LINK = 'CONTEST_MORE_LINK',
  CONTEST_MORE_CTA1 = 'CONTEST_MORE_CTA1',
}

export enum MicroContestEntryType {
  AUTO_SAVE = 'autosave',
}
