<form [formGroup]="currencyInput" [ngClass]="[verticalSpace ? verticalSpace : '', horizontalSpace ? horizontalSpace : '']">
  <typography *ngIf="label" text-small-semibold class="primary-text mb-xs">{{ label }}</typography>
  <div class="input-field bw-input-simple" [ngClass]="{ focused: isFocused }">
    <input 
      type="text" 
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      inputmode="decimal" 
      [formControlName]="fcName" 
      currencyMask 
      [options]="currencyOptions"
      [attr.disabled]="disabled"
      [attr.placeholder]="placeholder" />
    <bw-icon [color]="isFocused ? 'var(--secondary-text)' : 'var(--disabled-text)'" iconName="clear" (click)="clearValue()"></bw-icon>
  </div>
  <div *ngIf="inputErrorText" [@enterAnimation]="inputError ? 'show' : 'hide'" class="error">{{ inputErrorText }}</div>
  <div *ngIf="hint" class="hint">{{ inputHintText }}</div>
</form>
