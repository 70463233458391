import { Injectable } from '@angular/core';
import {
  DesktopCard,
  DesktopCardCaseType,
  EligibleProduct,
  MobileStateExplore,
  MobileStateHome,
  MobileStateService,
} from '@brightside-web/desktop/data-access/shared';

import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoansService } from '@brightside-web/desktop/data-access/loans';
import { SavingsCardDataService } from './savings-card-data.service';
import { CreditService } from '@brightside-web/desktop/data-access/credit';

//Do we want to have a whole class for this>?
const CashFlowGroomerFunction = async (card: DesktopCard) => {
  const fsIntroRoutePath = 'financialsnapshot/intro';

  card.segments?.forEach((segment) => (segment.ctaPath = fsIntroRoutePath));

  return { ...card, ctaPath: fsIntroRoutePath };
};

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(
    private mobileStateService: MobileStateService,
    private savingsCardDataService: SavingsCardDataService,
    private loansSvc: LoansService,
    private creditSvc: CreditService
  ) {}

  // I don't like putting product specific stuff in this service, maybe we can access these through the service map
  private static cardToasts: Record<string, string> = {
    noCreditData: 'No credit data available. Please contact your Financial Assistant.',
    creditSyncing: 'Still syncing your credit data. Please contact your Financial Assistant.',
  };

  static getToastMessage(key: string): string {
    return this.cardToasts[key];
  }

  /* ToDo: This is temp way of grooming the savings card data. We will need a proper solution that's not so hard fixed */
  private groomDesktopFinancialToolCardData(card: DesktopCard, products: EligibleProduct[]) {
    // we only support payroll savings at this time, not ach
    if (card.key === 'savings' && !card.case_type && products.includes(EligibleProduct.payrollSavings)) {
      // key of 'savings' is not an unlocked case type but Emergency_Savings is
      card.key = DesktopCardCaseType.SAVINGS;
      card.ctaPath = 'home/savings/intro';
    } else if (card.key === 'creditScore') {
      card.key = DesktopCardCaseType.CREDIT_SCORE;
      card.ctaPath = 'credit-setup/creation';
    } else if (card.key === 'externalSavings') {
      card.key = DesktopCardCaseType.SAVINGS;
      card.ctaPath = 'external-accounts/intro';
    } else if (card.key === 'MPAP') {
      card.ctaPath = 'program/mpap'
    } else if (card.key === 'HELPING_HANDS') {
      card.ctaPath = 'program/helping-hands'
    }
    return card;
  }

  private convertCardsToDesktopCards = (cards: DesktopCard[]) =>
    cards.map((card) => {
      if (card.case_type === DesktopCardCaseType.SAVINGS) {
        card.ctaPath = 'home/savings';
      } else if (card.case_type === DesktopCardCaseType.CONTEST) {
        card.ctaPath = card.ctaPath?.replace('contest/intro/', 'contest-loader/');
      }
      return { ...card, expandable: false };
    });
  private convertHomeCardsToDesktopCards = (cards: MobileStateHome, products: EligibleProduct[]) =>
    cards.financialTools.map((card) =>
      this.groomDesktopFinancialToolCardData({...card, expandable: false}, products)
    );
  private convertExploreCardsToDesktopCards = (cards: MobileStateExplore) =>
    [...cards.waysWeCanHelp, ...cards.moreForYouToExplore].map((card) =>
      ({ ...card, expandable: false })
    );

  getDesktopHomeCards(): Observable<DesktopCards> {
    return forkJoin([this.getActivatedCards(), this.getHomeCards(), this.getProducts()]).pipe(
      map((responses) =>
        ({
          active: this.convertCardsToDesktopCards(responses.shift() as DesktopCard[]),
          financialTools: this.convertHomeCardsToDesktopCards(
            responses.shift() as MobileStateHome,
            responses.shift() as EligibleProduct[]
          ),
        })
      )
    );
  }

  getDesktopExploreCards(exploreCards: MobileStateExplore): WaysToHelpCards {
    return {
      active: this.convertExploreCardsToDesktopCards(exploreCards),
    };
  }

  getProducts(): Observable<EligibleProduct[]> {
    return this.mobileStateService.get().pipe(map((mobileState) => mobileState.products || []));
  }

  getHomeCards(): Observable<MobileStateHome> {
    return this.mobileStateService
      .get()
      .pipe(map((mobileState) => mobileState.home || { waysWeCanHelp: [], financialTools: [] }));
  }

  getHomeBlockCards(): Observable<DesktopCard[]> {
    return this.mobileStateService.get().pipe(
      map((mobileState) => {
        const baseCards: DesktopCard[] = [...(mobileState.home?.waysWeCanHelp || [])];
        const baseCardTypes = baseCards.map((card) => card.key || card.type || card.case_type);

        const extraCards: DesktopCard[] = [
          ...(mobileState.explore?.waysWeCanHelp || []),
          ...(mobileState.explore?.moreForYouToExplore || []),
        ];

        const addTheseOn: DesktopCard[] =
          extraCards.reduce((rtnArry: DesktopCard[], card: DesktopCard) => {
            if (!baseCardTypes.includes(card.key || card.type || card.case_type)) {
              return [...rtnArry, card];
            }

            return rtnArry;
          }, []) || [];

        return [...baseCards, ...addTheseOn];
      })
    );
  }

  getExploreCards(): Observable<MobileStateExplore> {
    return this.mobileStateService
      .get()
      .pipe(map((mobileState) => mobileState.explore || { waysWeCanHelp: [], moreForYouToExplore: [] }));
  }

  getCardMap(): CardMap {
    return {
      [DesktopCardCaseType.SAVINGS]: this.savingsCardDataService.groomCardData,
      // This is a miss here to ignore Credit_Card_Refi loans, CE-8924 opened to address this.
      [DesktopCardCaseType.CREDIT_CARD_REFI]: this.loansSvc.groomCardData,
      [DesktopCardCaseType.EMERGENCY_CASH]: this.loansSvc.groomCardData,
      [DesktopCardCaseType.CREDIT_SCORE]: this.creditSvc.groomCardData,
      [DesktopCardCaseType.CASH_FLOW]: CashFlowGroomerFunction,
    } as CardMap;
  }

  getServiceMap(): ServiceMap {
    return {
      [DesktopCardCaseType.SAVINGS]: this.savingsCardDataService,
      [DesktopCardCaseType.EMERGENCY_CASH]: this.loansSvc,
      [DesktopCardCaseType.CREDIT_CARD_REFI]: this.loansSvc,
      [DesktopCardCaseType.CREDIT_SCORE]: this.creditSvc,
    } as ServiceMap;
  }

  getActivatedCards(force: boolean = false): Observable<DesktopCard[]> {
    return this.mobileStateService.get(force).pipe(
      map((mobileState) => (Array.isArray(mobileState.activatedCards) ? mobileState.activatedCards : [])),
      switchMap(async (activatedCards) => {
        //probably not the most performant approach
        for (let i = 0; i < activatedCards.length; i++) {
          if (this.getCardMap()[activatedCards[i].case_type as DesktopCardCaseType]) {
            const groomingFunction = this.getCardMap()[activatedCards[i].case_type as DesktopCardCaseType];
            const groomingService = this.getServiceMap()[activatedCards[i].case_type as DesktopCardCaseType];

            //If there is no service, skip binding
            activatedCards[i] = await (!groomingService
              ? groomingFunction(activatedCards[i])
              : groomingFunction.bind(groomingService)(activatedCards[i]));
          }
        }

        return activatedCards;
      })
    );
  }

  getCards(): Observable<DesktopCard[]> {
    return this.mobileStateService.get().pipe(map((mobileState) => (Array.isArray(mobileState.cards) ? mobileState.cards : [])));
  }
}

export interface DesktopCards {
  active: DesktopCard[];
  financialTools: DesktopCard[];
}

export interface WaysToHelpCards {
  active: DesktopCard[];
}

export type CardMap = {
  [key in DesktopCardCaseType]: (card: DesktopCard) => Promise<DesktopCard>;
};
export type ServiceMap = {
  [key in DesktopCardCaseType]: any;
};
