import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Consent } from '../consent.manager';

@Component({
  selector: 'brightside-web-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit {
  // Analytics
  @Input() page: string;
  @Input() category: string;

  // default value.
  @Input() action_name = 'link_to_consent_details';

  @Input() consent: Consent;
  @Output() consentAccepted = new EventEmitter<boolean>();
  @Output() displayTerms = new EventEmitter<string>();

  ngOnInit() {
    this.normalizeWhiteSpace();
    this.appendPeriodIfNeeded();
  }

  private normalizeWhiteSpace() {
    if (this.consent.descriptionBeforeLink) {
      this.consent.descriptionBeforeLink = this.consent.descriptionBeforeLink.trim();

      if (this.consent.descriptionLink) {
        this.consent.descriptionBeforeLink += ' ';
      }
    }
  }

  private appendPeriodIfNeeded() {
    if (!this.consent.descriptionAfterLink) {
      return;
    }

    const countEnd = this.consent.descriptionAfterLink.length - 1;

    if (this.consent.descriptionAfterLink[countEnd] !== '.') {
      // this.consent.descriptionAfterLink += '.';
    }
  }

  toggle() {
    this.consent.accepted = !this.consent.accepted;
    this.consentAccepted.emit(this.consent.accepted);
  }

  fetchTerms(event: any) {
    event.stopPropagation();
    const key = this.consent.linkKey || this.consent.key;
    switch (key) {
      case 'CustodialAccount':
        window.open('https://cdn.gobrightside.com/terms/CUSTODY-US.pdf', "_blank");
        break;
      case 'DepositAccountAgreement':
        window.open('https://cdn.gobrightside.com/terms/DEPOSIT-US.pdf', "_blank");
        break;
      case 'DebitCardholderAgreement':
        window.open('https://cdn.gobrightside.com/terms/CARDHOLDER-US.pdf', "_blank");
        break;
      default:
        if (key) {
          this.displayTerms.emit(key);
        }
        break;
    }

  }
}
