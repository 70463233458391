import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Route, Router, RouterStateSnapshot, UrlSegment} from "@angular/router";
import {Observable, of, Subscription} from "rxjs";
import {Cache} from "aws-amplify";
import {
  MobileStateResponse,
  MobileStateService,
  ProdEligReqFieldsInterface,
  ProdEligResponseInterface,
  ProductEligibilityService, ProductPostIntroMapping,
  VerificationStatusService
} from "@brightside-web/desktop/data-access/shared";
import {map, switchMap} from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})
export class ProductVerificationGuard {

  sub = new Subscription();
  productEligibilityResp: ProdEligResponseInterface;
  nonRequiredFields = ['middle_name', 'address2'];

  constructor(
    private verificationStatus: VerificationStatusService,
    private router: Router,
    private prodElgSvc: ProductEligibilityService,
    private mobileStateService: MobileStateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot
  ): Observable<boolean> {
    /** this was meant to prevent clients from accessing the path directly after completing
     *  the review of their information; but it also prevents the back button from working
     *  need to consider a workaround, or if this is even an issue.
     */
    // const possibleRoutes = Object.keys(ProductPostIntroMapping);
    // const completedRoutes = possibleRoutes.find(posRoute => Cache.getItem(posRoute));
    // if (route.params.product && this.prodElgSvc.getProductCodeRedirect(route.params.product) === completedRoutes) {
    //  this.router.navigate([completedRoutes]);
    //   return of(false);
    // }
    return of(true)
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.mobileStateService.get(true).pipe(
      switchMap( ms => {
        if (ms.spendingAccount || ms.savingsAccount || ms.externalSavings) {
          return of(true);
        } else if (this.productEligibilityResp) {
          return of(this.canClientActivate(route, state));
        } else {
          return this.prodElgSvc.getProductEligibility().pipe(
            map(response => {
              this.productEligibilityResp = response;
              return this.canClientActivate(route, state);
            })
          );
        }
      })
    );
  }


  canClientActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (Object.keys(ProductPostIntroMapping).includes(state.url) && !route.queryParams.fromFormSubmission) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const productType = ProductPostIntroMapping[state.url];
      const productFields = this.productEligibilityResp.products.find(product => product.name.toLowerCase() === productType.toLowerCase());
      if (productFields &&
        productFields.required_fields &&
        productFields.required_fields.length > 0) {
        const shouldContinueArray = [];
        productFields.required_fields.forEach(field => {
          if (!field.not_replayable) {
            if (this.testReqValueForEmpty(field)) {
              shouldContinueArray.push(field);
            }
          }
          if (field.sub_fields) {
            field.sub_fields.forEach( subField => {
              if (!subField.not_replayable) {
                if (this.testReqValueForEmpty(subField)) {
                  shouldContinueArray.push(subField);
                }
              }
            })
          }
        });
        if (shouldContinueArray.length > 0) {
          this.router.navigate([`/verification/identity/${productType}`]);
          return false;
        }
      }
    }
    return true;
  }



  testReqValueForEmpty(field: ProdEligReqFieldsInterface) : boolean {
    // if a field is return null we want to display the intro and forms
    // if a required field is returned falsy (blank string) we want to display
    // intro and forms
    if (field.value === null
      || (!field.value && !this.nonRequiredFields.includes(field.name))
      || (field.name === 'middle_name' && field.value === 'middle')) {
      return true;
    } else {
      return false;
    }
  }
}
