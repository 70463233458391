import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FirebaseService,
  SavingsAccount,
  SavingsSchedulePatchAction,
  SavingsSchedulePatchRequest,
  SavingsScheduleResponse,
  SavingsScheduleType,
  SavingsSource,
} from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';

import { Subscription } from 'rxjs';

@Component({
  selector: 'brightside-web-autosave-pause',
  templateUrl: './autosave-pause.component.html',
  styleUrls: ['./autosave-pause.component.scss'],
})
export class AutosavePauseComponent implements OnInit, OnDestroy {
  @Output() pauseStatusChanged = new EventEmitter();

  private sub = new Subscription();
  private account: SavingsAccount;

  processing = false;

  isCurrentlyPaused = false;

  showConfirmModal = false;

  constructor(private analytics: FirebaseService, private savingsService: SavingsAccountService) {}

  ngOnInit() {
    this.subscribeAndLoadSavings();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private subscribeAndLoadSavings() {
    const handleSuccess = (account?: SavingsAccount) => {
      this.account = account as SavingsAccount; // assume parent component has guard enforcing savings account exists
      this.isCurrentlyPaused = Boolean(this.account.schedule_paused);
    };
    const handleFailure = () => {
      console.log('Unable to load savings account with paused control');
      this.analytics.logEvent('error_shown', { 'error id': 'load_savings' });
    };

    this.sub.add(this.savingsService.getSavingsAccount().subscribe(handleSuccess, handleFailure));
  }

  private pauseAutosaveSchedule() {
    const requestBody = this.getRequestBody(SavingsSchedulePatchAction.PAUSE);
    const handleSuccess = (response: SavingsScheduleResponse) => {
      this.processing = false;
      this.isCurrentlyPaused = true;
      this.showConfirmModal = false;
      this.pauseStatusChanged.emit({ status: 'pause' });
    };
    const handleFailure = () => {
      this.processing = false;
      this.isCurrentlyPaused = false;
      this.showConfirmModal = false;
    };

    if (!requestBody) {
      this.processing = false;
      return;
    }

    this.processing = true;
    this.savingsService.updateSchedule(requestBody).subscribe(handleSuccess, handleFailure);
  }

  private unpauseAutosaveSchedule() {
    const requestBody = this.getRequestBody(SavingsSchedulePatchAction.UNPAUSE);
    const handleSuccess = (response: SavingsScheduleResponse) => {
      this.processing = false;
      this.isCurrentlyPaused = false;
      this.pauseStatusChanged.emit({ status: 'unpause' });
    };
    const handleFailure = () => {
      this.processing = false;
      this.isCurrentlyPaused = true;
    };

    if (!requestBody) {
      this.processing = false;
      return;
    }

    this.processing = true;
    this.savingsService.updateSchedule(requestBody).subscribe(handleSuccess, handleFailure);
  }

  private getRequestBody(action: SavingsSchedulePatchAction): SavingsSchedulePatchRequest | null {
    if (!this.account.next_deposit_date || !this.account.next_deposit_amount) {
      return null;
    }

    return {
      schedule_type: this.account.schedule_type as SavingsScheduleType,
      next_transfer_date: this.account.next_deposit_date,
      amount: this.account.next_deposit_amount,
      source: this.account.source || SavingsSource.payroll,
      action,
    };
  }

  handleToggleChanged(isToggleOn: boolean) {
    if (isToggleOn) {
      this.showConfirmModal = true;
      return;
    } else {
      this.unpauseAutosaveSchedule();
      this.isCurrentlyPaused = false;
    }
  }

  handleConfirmCta() {
    if (this.processing) {
      return;
    }

    this.processing = true;
    this.pauseAutosaveSchedule();
  }

  handleCancelCta() {
    this.showConfirmModal = false;
    this.isCurrentlyPaused = false;
  }
}
