import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { MessageBusEventUtil, MessageBusOutgoingEventKey } from '@micro-core/message-bus';
import { RoutingStateService } from '@brightside/brightside-ui-services';

import { Auth } from 'aws-amplify';
import { from } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable()
export class MicroContestLegalClientResolver implements Resolve<undefined> {
  constructor(private routingStateService: RoutingStateService) {}

  private buildAmoeLink(details: { fName: string; lName: string; phone: string }): string {
    return `${window.location.origin}/contest-promo/contest-legal/sweepstakes-amoe?fName=${details.fName}&lName=${details.lName}&phone=${details.phone}`;
  }

  async resolve() {
    return from(Auth.currentUserInfo())
      .pipe(
        map((clientInfo) => {
          MessageBusEventUtil.sendOutgoing({
            event: MessageBusOutgoingEventKey.EXTERNAL_LINK,
            bridgeSetting: {
              data: {
                url: this.buildAmoeLink({
                  fName: clientInfo.attributes['given_name'],
                  lName: clientInfo.attributes['family_name'],
                  phone: clientInfo.username,
                }),
                chatIcon: false,
                displayStyle: 'browser',
              },
            },
          });

          return undefined;
        }),
        delay(5000),
        tap(() => {
          this.routingStateService.popAndNavigateTo(['home']);
        })
      )
      .toPromise();
  }
}
