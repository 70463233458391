import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TermsService } from '@brightside-web/desktop/data-access/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'brightside-web-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss']
})
export class ConsentModalComponent implements  OnDestroy  {

  @Input() title="Consent details";
  @Input() cta="OK";

  @Input() page:string;
  @Input() category:string;

  visible=false;

  sub = new Subscription();

  data: any;
  _key:string;

  constructor(protected terms:TermsService) {

  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  @Input() set key(value: string) {
    if ( value ) {
      this._key = value;
      this.sub.add(this.terms.fetchTerms(value).subscribe(data => {
        this.data = data;
        console.log(`making modal visible for keyL ${value}`);
        this.visible = true;
      }));
    }
  }

}
