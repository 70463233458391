<bw-action-article [brightsideWebFb]="config.analyticsAttributes('shown')">
  <div *ngIf='config.analytics && config.analytics.shown' [brightsideWebFb]='{ event_name: config.analytics.shown }'></div>
  <bw-back
    *ngIf="!shouldMoveNavToBottom"
    back-action-top
    [emit]="config.pageOnBackCtaPath.length > 0"
    [brightsideWebFb]="config.analyticsAttributes('dismiss')"
    (back)="handleRouteBackward()"
  ></bw-back>
  <bw-back
    *ngIf="shouldMoveNavToBottom"
    back-action-bottom
    [emit]="config.pageOnBackCtaPath.length > 0"
    [brightsideWebFb]="config.analyticsAttributes('dismiss')"
    (back)="handleRouteBackward()"
  ></bw-back>

  <div content-section>
    <form [formGroup]="consentsFormGroup" (ngSubmit)="accept()">
      <bw-steps
        *ngIf="showStepper"
        [model]="steps"
        [activeIndex]="config.pageStepperActiveIndex"
        styleClass="mb-md"
      ></bw-steps>
      <typography *ngIf="config.pageTitleKey" title-medium class="mb-sm">{{ config.pageTitleKey | translate }}</typography>
      <typography *ngIf="!config.pageTitleKey" title-medium class="mb-sm">{{ config.pageTitle | translate }}</typography>
      <p *ngIf="config.pageSubTitleKey" text-large-regular class="mb-md" color="var(--secondary-text)">{{ config.pageSubTitleKey | translate }}</p>
      <p *ngIf="!config.pageSubTitleKey" text-large-regular class="mb-md" color="var(--secondary-text)">{{ config.pageSubTitle }}</p>
      <typography text-large-semibold>{{ 'AGREEMENTS' | translate }}</typography>
      <bw-cell *ngFor="let consentLine of config.consentList" [separatorType]="CellSeparatorType.None" [contentLeft]="true">
        <ng-container content-left>
          <brightside-web-consent
            [consent]="consentLine"
            [page]="config.fbPage"
            [category]="config.fbPage"
            (displayTerms)="displayedConsentKey = $event"
          >
          </brightside-web-consent>
        </ng-container>
      </bw-cell>
    </form>
  </div>
  <div footer-top class="flex-row space-center">
    <form [formGroup]="confirmDataForm">
      <bw-checkbox [formControlName]="'confirmCheckbox'">
        <typography text-medium color="var(--primary-text)">
          {{ 'TERMS_ACCEPTANCE' | translate }}
        </typography>
      </bw-checkbox>
    </form>
  </div>
  <div footer-actions class="flex-row space-center full-width">
    <div>
      <button
        bw-button
        type="submit"
        [processing]="processing"
        [disabled]="confirmDataForm.invalid"
        (click)="accept()"
        [brightsideWebFb]="config.analyticsAttributes('tapped')"
      >{{ 'ACCEPT' | translate }}</button>
    </div>
  </div>
</bw-action-article>

<brightside-web-consent-modal
  [key]="displayedConsentKey"
  [page]="config.fbPage"
  [category]="config.fbCategory"
></brightside-web-consent-modal>
