import { MessageBusEventUtil } from '@micro-core/message-bus';

import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateAction, UiTemplateFlyerDataBuilder, UiTemplateNavType } from '@micro-ui/template/page';

import { MicroContentMediaFor, MicroContestAnalyticsEventName, MicroContestTranslationKey } from '../../../model/contest.enum';

const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.BACK,
    header: {
      type: UiHeaderType.DEFAULT,
      title: MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_SUB_TITLE,
      icon: '',
    },
    media: MicroContentMediaFor(MicroContestAnalyticsEventName.ENTRY_COMPLETE),
    cta1Label: MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_CTA1,
    viewName: MicroContestAnalyticsEventName.ENTRY_COMPLETE,
  },
  actions: {
    [UiTemplateAction.navLeftIcon]: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeature),
    [UiTemplateAction.cta1]: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeatureToSavingsHome),
  },
});

export default TemplateData;
