import {Component, Input, Output, OnInit, EventEmitter, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingStateService } from '@brightside/brightside-ui-services';
import {
  FlowManagerService,
  OnboardingSurveyStep,
} from '@brightside-web/desktop/data-access/onboarding';

import {Question, QuestionsSettings, QuestionType} from '@brightside-web/desktop/data-access/shared';


@Component({
  selector: 'brightside-web-display-questions',
  templateUrl: './display-questions.component.html',
})
export class DisplayQuestionsComponent implements OnInit {
  /**
   * this isn't super reusable right now. a new object would have to be setup to support reusing this somewhere else.
   */
  @Input() overrideData: OnboardingSurveyStep;

  @Output() answersSubmitted = new EventEmitter();

  @Input() canBackOut = false;

  config: QuestionsSettings = new QuestionsSettings({
    fbPage: 'page',
    fbCategory: 'consents',

    pageTitle: '',
    pageSubTitle: '',
    pageCtaLabel: 'Continue',
    pageSecondaryCtaLabel: '',

    pageOnForwardCtaPath: [],

    questions: [],
    surveyType: '',
    fbEventName: ''
  });

  questionForm: FormGroup = this.formBuilder.group({});
  questionIndex = 0;
  questionAnswers: { id: string; entered_value: string }[] = [];

  multiAnswerPlaceholder: string[] = [];
  otherAnswerShowIndexes: number[] = [];

  processing: boolean;

  constructor(
    private routingState: RoutingStateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flowManageService: FlowManagerService,
    private elem: ElementRef
  ) {}

  ngOnInit(): void {
    this.checkForConfigViaData();

    this.questionForm = this.formBuilder.group({
      questionAnswer: ['', [Validators.required]],
      questionAnswerOther: ['', []],
    });

  }

  get isChoiceMulti() {
    return this.currentQuestion.field_type === QuestionType.CHOICE_MULTI;
  }

  get blankQuestion(): Question {
    return {
      id: '',
      choices: [],
      field_label: '',
      field_type: QuestionType.CHOICE,
      questionOrder: 0,
      style: {},
    };
  }

  get questions() {
    return this.config.questions;
  }

  get currentQuestion(): Question {
    if (!this.questions) {
      return this.blankQuestion;
    }

    return this.questions[this.questionIndex];
  }

  get questionIsSkippable() {
    if (this.currentQuestion.style.isSkippable === false) {
      this.config.pageSecondaryCtaLabel = '';
      return false;
    } else if (
      Object.keys(this.currentQuestion.style).length === 0 ||
      this.currentQuestion.style.isSkippable ||
      this.config.pageSecondaryCtaLabel !== ''
    ) {
      this.config.pageSecondaryCtaLabel = 'Skip';
      return true;
    }

    return false;
  }

  private checkForConfigViaData() {
    //First check for override data
    if (this.overrideData && this.overrideData.questionSettings) {
      this.config = new QuestionsSettings(this.overrideData.questionSettings);

      this.config.fbEventName = `${this.config.fbPage} ${this.config.fbCategory}`;
      return;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {

      this.config = new QuestionsSettings(this.activatedRoute.snapshot.data.pageConfig);
    }
  }

  private buildAnswer(value: string) {
    return {
      id: this.currentQuestion.id,
      entered_value: value,
    };
  }

  private buildSkippedAnswer() {
    this.questionAnswers.push(this.buildAnswer('SKIPPED'));
  }

  private buildChoiceAnswer() {
    const questionAnswerFormItem = this.questionForm.get('questionAnswer');
    const questionAnswerOther = this.questionForm.get('questionAnswerOther');

    if (questionAnswerFormItem) {
      //Push the selected answer up
      if (questionAnswerFormItem?.value === 'Other') {
        this.questionAnswers.push(this.buildAnswer(`${questionAnswerFormItem.value}: ${questionAnswerOther?.value}`));
      } else {
        this.questionAnswers.push(this.buildAnswer(questionAnswerFormItem.value));
      }

      questionAnswerFormItem.setValue('');
      questionAnswerOther?.setValue('');
    }
  }

  private buildChoiceMultiAnswer() {

    const questionAnswerOther = this.questionForm.get('questionAnswerOther');
    const answerString = this.multiAnswerPlaceholder
      .map((answer) => (answer === 'Other' ? `Other: ${questionAnswerOther?.value}` : answer))
      .join(';');
    if (!answerString) {
      //ToDo: Question - Should we show error?
      return;
    }

    //Push the selected answer up
    this.questionAnswers.push(this.buildAnswer(answerString));
    this.multiAnswerPlaceholder = [];

  }

  private handlePrevQuestion() {
    const questionAnswers = {...this.questionAnswers}[0].entered_value.split(';');
    this.questionAnswers.pop();
    this.questionIndex -= 1;

    if (this.isChoiceMulti) {
      // this is not too pretty, maybe bw-checkbox should accept new input for "selected" so we can just test against title in array
      if (questionAnswers[0].toLowerCase() !== "skipped") {
        questionAnswers.forEach(choice => {
          const choiceId = '#' + choice.replace('&', '').split(' ').join('-').toLowerCase();
          setTimeout(() => {
            const element = this.elem.nativeElement.querySelector(choiceId);
            if (element) {
              element.querySelector('input').click();
            }
            }, 0);
        });
      } else {
        this.multiAnswerPlaceholder = [];
      }
    }
  }

  private handleNextQuestion() {
    // Check if we got to last question
    if (!this.questions[this.questionIndex + 1]) {
      this.handleSubmitQuestions();
      return;
    }

    // Log answer response and move to next question
    this.questionIndex += 1;
    this.questionForm.reset();
  }

  private handleSubmitQuestions() {
    const onSuccess = () => {
      this.answersSubmitted.emit();
    };
    const onFailure = () => {};

    this.flowManageService
      .updateWithPost({ type: this.config.surveyType, answers: this.questionAnswers })
      .subscribe(onSuccess, onFailure);
  }

  handleMoveForward() {
    this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath);
  }

  checkAndToggleOtherRadio(index: number, value: string) {
    const isOther = value.toLowerCase().startsWith('other');
    const isAlreadyShowing = this.otherAnswerShowIndexes.includes(index);

    //If it's not other or is other and already showing we clear it
    if (!isOther || (isOther && isAlreadyShowing)) {
      this.otherAnswerShowIndexes = [];
      return;
    }

    this.otherAnswerShowIndexes.push(index);
  }

  checkAndToggleOtherBox(index: number, value: string) {
    const isOther = value.toLowerCase().startsWith('other');
    const isAlreadyShowing = this.otherAnswerShowIndexes.includes(index);

    if (!isOther) {
      return;
    }

    if (!isAlreadyShowing) {
      this.otherAnswerShowIndexes.push(index);
      return;
    }

    delete this.otherAnswerShowIndexes[this.otherAnswerShowIndexes.indexOf(index)];
  }

  handleCheckboxChanged(evt: any, value: string) {
    if (evt) {
      this.multiAnswerPlaceholder.push(value);
    } else {
      delete this.multiAnswerPlaceholder[this.multiAnswerPlaceholder.indexOf(value)];
    }

    // Filter out bad values
    this.multiAnswerPlaceholder = this.multiAnswerPlaceholder.filter((filterThisValue) => filterThisValue);
  }

  handleBackClick() {
    if (!this.questionIndex) {
      return true;
    }

    this.handlePrevQuestion();
    return false;
  }

  handleCtaClick() {



    switch (this.currentQuestion.field_type) {
      case QuestionType.CHOICE_MULTI:
        this.buildChoiceMultiAnswer();
        break;

      default:
        this.buildChoiceAnswer();
        break;
    }

    this.handleNextQuestion();
  }

  handleSecondaryCtaClick() {
    this.buildSkippedAnswer();
    this.handleNextQuestion();
  }
}
