import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, take, map } from 'rxjs/operators';

import { MicroUtilTranslationService, MicroRouteFindDataByKey } from '@micro-core/utility';

import {
  UiTemplateContent,
  UiTemplateContentGroupCell,
  UiTemplateContentGroupCellItem,
  UiTemplateContentType,
} from '@micro-ui/template/content';
import { UiTemplateFormFlyerInterface, UiTemplateStateHandlerService, UiTemplateStateKey } from '@micro-ui/template/page';

import { SavingsAccount, SavingsPayrollScheduleResponse, SavingsScheduleType } from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService, SavingsPayrollService } from '@brightside-web/desktop/data-access/savings';

import {
  MicroContestFormControlKey,
  MicroContestRouteDataKey,
  MicroContestRouteModalKey,
  MicroContestRouteParamKey,
  MicroContestTranslationKey,
} from '../../../model/contest.enum';
import { MicroContestRemoteConfig } from '../../../model/contest.interface';

type ResolveReturnType = [SavingsAccount | undefined, SavingsPayrollScheduleResponse | undefined] | undefined;
type DynamicTranslation = Partial<Record<MicroContestTranslationKey, string>>;

@Injectable({ providedIn: 'root' })
export class MicroContestEntryAutosaveConfirmModalResolver implements Resolve<ResolveReturnType> {
  private _parentTemplateData: UiTemplateFormFlyerInterface | undefined;
  private _modalTemplateData: UiTemplateFormFlyerInterface | undefined;
  private _remoteConfigData: MicroContestRemoteConfig | undefined;

  private _cacheSavingsAccount: { validTill: number; savingsAccount: SavingsAccount } | undefined;
  private _cachePayrollSchedule: { validTill: number; payrollSchedule: SavingsPayrollScheduleResponse } | undefined;

  private ADD_VALID_TIME = 60000;

  //This state should be tied to the state being used in the parent component
  constructor(
    private currencyPipe: CurrencyPipe,
    private savingsAccountService: SavingsAccountService,
    private savingsPayrollService: SavingsPayrollService,
    private uiTemplateStateHandlerService: UiTemplateStateHandlerService,
    private utilTranslationService: MicroUtilTranslationService
  ) {}

  private reset() {
    this._parentTemplateData = undefined;
    this._modalTemplateData = undefined;
    this._remoteConfigData = undefined;
  }

  private shouldContinue(routeSnapshot: ActivatedRouteSnapshot) {
    const modalId: MicroContestRouteModalKey =
      routeSnapshot.queryParams[MicroContestRouteParamKey.MODAL] || MicroContestRouteModalKey.NONE;

    if (modalId !== MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION) {
      return false;
    }

    return true;
  }

  private get hasConfirmModal() {
    return (
      this._parentTemplateData?.modals &&
      this._parentTemplateData.modals[MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION] &&
      (this._parentTemplateData.modals[MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION].data as UiTemplateFormFlyerInterface)
        .inputs?.content
    );
  }

  private setLocalFromResponse(response: ResolveReturnType) {
    if (!response) {
      this._cacheSavingsAccount = undefined;
      this._cachePayrollSchedule = undefined;
      return;
    }

    const [savingsAccount, payrollSchedule] = response;
    const expireTime = new Date().getTime() + this.ADD_VALID_TIME;

    this._cacheSavingsAccount = { validTill: expireTime, savingsAccount: savingsAccount as SavingsAccount };
    this._cachePayrollSchedule = {
      validTill: expireTime,
      payrollSchedule: payrollSchedule as SavingsPayrollScheduleResponse,
    };
  }

  private setScheduleRequestBodyToState() {
    if (!this._cacheSavingsAccount || !this._modalTemplateData) {
      return;
    }

    const createScheduleRequestBody = {
      source: this._cacheSavingsAccount?.savingsAccount.source,
      amount: this.getScheduleAmount(this._cacheSavingsAccount.savingsAccount),
      schedule_type: SavingsScheduleType.everyPaycheck,
      next_transfer_date: this._cachePayrollSchedule?.payrollSchedule.schedule[0], //Do we need to handle missing? I think so but not sure if I should blow up whole flow
    };

    this.uiTemplateStateHandlerService.updateWithPartial({
      [UiTemplateStateKey.CHILD_MODAL]: {
        createScheduleRequestBody,
        updatedAt: new Date().getTime()
      },
    });
  }

  private setModalTemplateData() {
    if (this.hasConfirmModal && this._parentTemplateData?.modals) {
      this._modalTemplateData = this._parentTemplateData.modals[MicroContestRouteModalKey.AUTOSAVE_CONFIRMATION]
        .data as UiTemplateFormFlyerInterface;

      //Eh.. if we don't do this the data get's out of date since we don't have good pattern for copy/key replace
      if (!(this._modalTemplateData as any)._oldInputs) {
        (this._modalTemplateData as any)._oldInputs = { ...this._modalTemplateData.inputs };
      } else {
        this._modalTemplateData.inputs = { ...(this._modalTemplateData as any)._oldInputs };
      }

      return;
    }

    this._modalTemplateData = undefined;
  }

  private getDailyAmount() {
    const state = this.uiTemplateStateHandlerService.getState();

    if (state?.state_child_modal && state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM]) {
      return Number(state.state_child_modal[MicroContestFormControlKey.AUTOSAVE_AMOUNT_CUSTOM] || 0);
    }

    if (state?.state_form?.values && state.state_form.values[MicroContestFormControlKey.AUTOSAVE_AMOUNT]) {
      return Number(state.state_form.values[MicroContestFormControlKey.AUTOSAVE_AMOUNT] || 0);
    }

    return 0;
  }

  private getScheduleAmount(savingsAccount: SavingsAccount) {
    const dailySavingsAmount = this.getDailyAmount();
    const remoteMultiplierMapping: { [key: string]: number } = this._remoteConfigData?.config?.dailyMultiplier || {
      default: 7,
      weekly: 7,
      biweekly: 14,
      'bi-weekly': 14,
      monthly: 30,
    };
    const scheduleType: string = (savingsAccount.pay_frequency || '').toLowerCase();
    const multiplier: number = remoteMultiplierMapping[scheduleType] || 7;

    return Number(dailySavingsAmount) * multiplier;
  }

  private getDynamicTranslations(): DynamicTranslation {
    const dailyAmount: number = this.getDailyAmount();

    return {
      [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE]: this.utilTranslationService.instant(
        MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE,
        {
          dailySavingsAmount: `${this.currencyPipe.transform(dailyAmount, 'USD', 'symbol', '1.0')}`,
        }
      ),
    };
  }

  private adjustModalContent() {
    if (!this._modalTemplateData || !this._cacheSavingsAccount?.savingsAccount) {
      this.reset();
      return;
    }

    const valueResolverArguments = {
      currencyPipe: this.currencyPipe,
      dailySavingsAmount: this.getDailyAmount(),
      scheduleSavingsAmount: this.getScheduleAmount(this._cacheSavingsAccount?.savingsAccount),
      payrollSchedule: this._cachePayrollSchedule?.payrollSchedule,
    };

    this._modalTemplateData.inputs.content?.forEach((details: UiTemplateContent) => {
      if (details.type === UiTemplateContentType.GROUP_CELL) {
        const data = details.data as UiTemplateContentGroupCell;

        data.options.forEach((option: UiTemplateContentGroupCellItem) => {
          if (option.valueResolver && typeof option.valueResolver === 'function') {
            option.value = option.valueResolver(valueResolverArguments);
          }
        });
      }
    });
  }

  private adjustModalInputs() {
    const dynamicTranslatedValues = this.getDynamicTranslations();

    if (this._modalTemplateData) {
      this._modalTemplateData.inputs = this.utilTranslationService.deepCopyKeySwapForDynamicValue(
        this._modalTemplateData.inputs,
        dynamicTranslatedValues
      );
    }
  }

  private mapAndTapServerData(response: ResolveReturnType): ResolveReturnType {
    this.setLocalFromResponse(response);

    if (!this._cacheSavingsAccount || !this._cachePayrollSchedule) {
      return [undefined, undefined];
    }

    //Try to set modal data before doing anything else
    if (this.hasConfirmModal) {
      this.setModalTemplateData();
      this.setScheduleRequestBodyToState();
    }

    this.adjustModalContent();
    this.adjustModalInputs();

    return response;
  }

  /**
   * Creates the return observable for resolve. It will also
   * map and process the response to modal template data updates.
   *
   * @returns Observable<ResolveReturnType>
   */
  private getServerData(): Observable<ResolveReturnType> {
    const finalizeFn = () => {
      this.reset();
    };

    return this.useCacheOrForkJoin().pipe(
      take(1),
      map(this.mapAndTapServerData.bind(this)),
      catchError((err: unknown) => {
        console.warn('Error in resolver', err);

        return [undefined, undefined];
      }),
      finalize(finalizeFn.bind(this))
    );
  }

  /**
   * Checks if recent request are still valid and cached.
   * If there, will return of(..cache) if not, will return
   * a fork join with the requests needed.
   *
   * Regardless of cache or not the resolver should rebuild
   * any template information off the response of these APIs
   *
   * @returns Observable<ResolveReturnType>
   */
  private useCacheOrForkJoin(): Observable<ResolveReturnType> {
    if (this._cacheSavingsAccount && this._cachePayrollSchedule && this._cacheSavingsAccount.validTill > new Date().getTime()) {
      return of([this._cacheSavingsAccount.savingsAccount, this._cachePayrollSchedule.payrollSchedule]);
    }

    return forkJoin([this.savingsAccountService.getSavingsAccount(), this.savingsPayrollService.getSchedule()]);
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    if (this.shouldContinue(routeSnapshot)) {
      this._parentTemplateData = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
      this._remoteConfigData = MicroRouteFindDataByKey<MicroContestRemoteConfig>(
        routeSnapshot,
        MicroContestRouteDataKey.REMOTE_CONFIG
      );

      return this.getServerData();
    }
  }
}
