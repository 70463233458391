import { Routes } from '@angular/router';

import { UiTemplateFlyerComponent } from '@micro-ui/template/page';

import { MicroContestResultsRevealResolver } from '../resolver/result-reveal.resolver';
import { MicroContestsResolver } from '../resolver/contests.resolver';
import { MicroContestDetailsResolver } from '../resolver/contest-details.resolver';
import { MicroContestResultsResolver } from '../resolver/contest-results.resolver';

import TemplateData_Result from './result.template.data';

import { MicroContestResultResolver } from './reveal/result.resolver';

import { MicroContestRouteDataKey, MicroContestRouteKey, MicroContestRouteParamKey } from '../../model/contest.enum';

/**
 * These routes will fall under the /contest-reveal/result/:id routing.
 *
 * NOTE: In desktop these pages may be shown inside a modal. This means
 * we have to take care in navigation within actions.
 */
export const BaseRoutes: Routes = [
  {
    path: MicroContestRouteKey.RESULT,
    resolve: {
      [MicroContestRouteDataKey.RESPONSE_CONTESTS]: MicroContestsResolver,
    },
    children: [
      {
        path: `:${MicroContestRouteParamKey.CONTEST_ID}`,
        resolve: {
          [MicroContestRouteDataKey.RESPONSE_CONTEST_DETAIL]: MicroContestDetailsResolver,
        },
        children: [
          {
            path: '',
            resolve: {
              [MicroContestRouteDataKey.RESPONSE_CONTEST_RESULTS]: MicroContestResultsResolver,
            },
            children: [
              {
                path: '',
                component: UiTemplateFlyerComponent,
                resolve: {
                  [MicroContestRouteDataKey.TEMPLATE]: MicroContestResultResolver,
                },
                data: {
                  [MicroContestRouteDataKey.TEMPLATE]: TemplateData_Result,
                },
              },
              {
                path: MicroContestRouteKey.REVEAL,
                component: UiTemplateFlyerComponent,
                canActivate: [],
                resolve: {
                  [MicroContestRouteDataKey.TEMPLATE]: MicroContestResultsRevealResolver,
                },
                data: {
                  [MicroContestRouteDataKey.TEMPLATE]: {}, //Will be loaded with resolver depending on path
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
