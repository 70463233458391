import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { Auth, Hub, Logger, Cache } from 'aws-amplify';
import { catchError, map } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { AuthSsoService } from './auth-sso.service';
import { AmplitudeService } from "./amplitude.service";

declare const Sprig:Function;


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoggedIn = new Subject<boolean>();
  redirectUrl: string;

  username: string | undefined;
  password: string | undefined;
  company: string | undefined;

  setRedirectUrl(path: string) {
    this.redirectUrl = path;
  }

  parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  async sso(provider: string, idToken: string, accessToken: string, expiresIn: number) {
    const parsedToken = this.parseJwt(idToken);
    const email = parsedToken['email'];
    const name = parsedToken['cognito:username'];

    const response = {
      token: accessToken,
      identity_id: name,
      expires_at: Date.now() + expiresIn * 1000,
    };

    const user = {
      email,
      name,
    };

    const signInOptions = {
      customProvider: provider,
    };

    Cache.setItem('loggedIntoSSO', true);

    await Auth.federatedSignIn(
      // @ts-ignore
      signInOptions,
      response,
      user
    );
  }

  constructor(private analytics: FirebaseService, private amplitudeService: AmplitudeService) {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => this.userLoggedIn.next(true))
      .catch((err) => this.userLoggedIn.next(false));

    const logger = new Logger('My-Logger');
    const listener = (data: HubCapsule) => {
      switch (data.payload.event) {
        case 'signIn':
          logger.info('user signed in');
          // @ts-ignore
          this.handleSignIn(data.payload.data.attributes['custom:guid']);
          this.setFirebaseProperties(data.payload.data.attributes);
          this.setAmplitudeProperties(data.payload.data.attributes);
          this.setUserLoggedIn(true);
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'oAuthSignOut':
        case 'signOut':
          if ( Sprig ) {
            Sprig('logoutUser');
          }
          Cache.removeItem("GUID");
          logger.info(`user ${data.payload.event}`);
          this.analytics.clearProperties();
          this.amplitudeService.clearProperties();
          this.setUserLoggedIn(false);
          break;
        case 'signIn_failure':
          logger.info('user sign in failed');
          break;
        case 'configured':
          logger.info('the Auth module is configured');
          break;
        default:
          logger.info(`unhandled Auth event: ${data.payload.event}`);
      }
    };

    Hub.listen('auth', listener);
  }



  private handleSignIn(guid: string) {
    Cache.setItem("GUID",guid);

    const now = Date.now();

    const previousSignIn = window.localStorage[guid];
    if (!previousSignIn || previousSignIn === Cache.getItem("SIGN_IN_TIME")) {
      window.localStorage[guid] = now;
      Cache.setItem("SIGN_IN_TIME",now);
      Cache.setItem("FIRST_SIGN_IN",true);
    }
    else {
      Cache.setItem("FIRST_SIGN_IN",false);
    }
  }

  /**
   * want to set more than just guid.
   * @param properties
   */
  setFirebaseProperties(properties: { [key:string]:string }) {
    this.analytics.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.analytics.setProperties(passThrough);

    this.analytics.setupRemoteConfig();
  }

  setAmplitudeProperties(properties: { [key:string]:string }) {
    this.amplitudeService.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.amplitudeService.setUserProperties(passThrough);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  isFromSSO(): Observable<boolean> {
    return from(
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
    ).pipe(
      map(user => user.storage['amplify-signin-with-hostedUI'] === 'true' ? true : false),
      catchError((err) => of(false))
    );
  }

  supportsSso(company: string = ''): boolean {
    if ( !company ) {
      company = AuthSsoService.getSubDomain();
    }
    const idProvider = this.analytics.getValueAsString(`sso_${company}`);
    return !!idProvider;
  }

  getProviderName(company:string=''): string {

    if ( !company ) {
      company = AuthSsoService.getSubDomain();
    }

    const friendlyProviderName = this.analytics.getValueAsString(`sso_${company}_provider`);
    return friendlyProviderName || "SSO";
  }

  hasCurrentUser(): Observable<boolean> {
    return from(
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
    ).pipe(
      map((user) => !!user),
      catchError((err) => of(false))
    );
  }

  clearConfirmationState() {
    this.username = undefined;
    this.password = undefined;
  }
}
