<typography text-medium-semibold color="var(--primary-text)">
  {{ consent.descriptionTitle | translate }}
</typography>
<typography text-medium-regular color="var(--secondary-text)">
  {{ consent.descriptionBeforeLink | translate }}
  <a
    bw-anchor
    class="bs-blue"
    (click)="fetchTerms($event)"
    *ngIf="consent.descriptionLink">
    {{ consent.descriptionLink }}</a>
  {{ consent.descriptionAfterLink | translate }}
</typography>
