import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForgotPasswordResponse, RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, BasicComponentConfig, CompanyService } from '@brightside-web/desktop/data-access/shared';
import { AxiosError } from 'axios';
import { Auth, Cache } from 'aws-amplify';
import { from, Subscription } from 'rxjs';
import {CONTROL_DOB, CONTROL_LNAME, CONTROL_LAST_FOUR} from "../display-form/controls/controls";

@Component({
  selector: 'brightside-web-verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.css']
})
export class VerifyIdentityComponent implements OnInit, OnDestroy {
  verifyForm:FormGroup;
  showFaModal: boolean;
  showHrModal: boolean;
  processing: boolean;
  sub = new Subscription();
  private isRegistration: boolean;
  registerModal:boolean;
  company:string;
  config: BasicComponentConfig;
  errorCount = 0;
  showMaxAttemptsModal: boolean;

  verifyIdentityControls = {
    lastName: CONTROL_LNAME,
    last4ssn: CONTROL_LAST_FOUR,
    dateOfBirth: CONTROL_DOB
  }

  constructor(
    private regSvc:RegistrationService,
    private router:Router,
    private route:ActivatedRoute,
    private companyService: CompanyService) { }



  ngOnInit() {
    this.checkForConfigViaData();

    this.verifyForm = new FormGroup({
      lastName: CONTROL_LNAME.control,
      last4ssn: CONTROL_LAST_FOUR.control,
      dateOfBirth: CONTROL_DOB.control
    });

    this.sub.add(from(Auth.currentAuthenticatedUser()).subscribe(_ => {
    }, ()=>{
      this.isRegistration = true;
    }));

    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          this.company = value;
        }
      )
    );
  }

  private checkForConfigViaData() {
    if (this.route?.snapshot?.data?.pageConfig) {
      this.config = new BasicComponentConfig(this.route.snapshot.data.pageConfig);
      this.config.fbPage = "verify_identity";
    } else {
      this.config = new BasicComponentConfig();
    }
  }

  submit() {
    this.processing = true;
    //https://sourcegraph.insidebrightside.com/android/-/blob/BrightSide/app/src/main/java/com/androidapp/gobrightside/mobile/loginregister/view/VerifyIdentityActivity.java#L108:39
    this.sub.add(this.regSvc.forgotPasswordPrecheck(
      {
            last_four_ssn:this.verifyForm.controls['last4ssn'].value,
            last_name:this.verifyForm.controls['lastName'].value,
            date_of_birth: this.verifyForm.controls['dateOfBirth'].value
        }, this.isRegistration).subscribe((resp:ForgotPasswordResponse)=>{
        Cache.setItem('phoneNumber', resp.phoneNumber );
        Cache.setItem('recoveryToken', resp.recoveryToken);
        this.router.navigate(['../','sign-in-help'], {relativeTo:this.route});
      },
    (err: AxiosError) => {
      this.processing = false;
      // TODO when using angular 9 + ts 3.7 use optional chaining operator
      this.handleResponse(
        err.response && err.response.data && typeof err.response.data === 'object' ? err.response.data : {}
      );
    }));
  }

  handleResponse(resp:ApiResponse){
    this.errorCount++
    if (this.errorCount >= 3) {
      this.showMaxAttemptsModal = true;
    } else if (resp.statusCode === 412){
      this.registerModal = true;
    } else {
      this.showHrModal = true;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  register(){
    Cache.setItem('lastName', this.verifyForm.controls['lastName'].value);
    this.router.navigate(['/registration','sign_up', {prepopulate:true}]);
  }

  closeErrorModal() {
    this.router.navigate(['/']);
  }
}
