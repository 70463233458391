import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RoutingStateService } from '@brightside/brightside-ui-services';
import { isTemplateFullScreen, Settings } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-display-loader',
  templateUrl: './display-loader.component.html',
})
export class DisplayLoaderComponent implements OnInit {
  private filterOutTheseQueryParams = ['pageOnForwardCtaPath'];

  config: Settings = new Settings({
    fbPage: 'page',
    fbCategory: 'consents',

    pageTitle: '',
    pageSubTitle: '',
    pageCtaLabel: 'Continue',
    pageSecondaryCtaLabel: '',

    pageOnForwardCtaPath: [],
  });

  pageIsFullScreen: boolean;

  constructor(private routingState: RoutingStateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkForConfigViaData();
    this.checkForForwardPathInQuery();

    setTimeout(() => this.handleMoveForward(), 500);
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new Settings(this.activatedRoute.snapshot.data.pageConfig);
    }
  }

  /**
   * Method will check for a query param key for pageOnForwardCtaPath
   * this allows the loader to be more generic and have the caller of
   * the navigate to pass in the path to navigate to via params.
   *
   * ?pageOnForwardCtaPath=<routePath>
   *
   * Later the routePath will be converted to route array splitting on /
   */
  private checkForForwardPathInQuery() {
    if (this.activatedRoute.snapshot.queryParams['pageOnForwardCtaPath']) {
      if (this.config.pageOnForwardCtaPath?.length > 0) {
        console.warn(
          'Loading route is NOT using the value for pageOnForwardCtaPath in the data since there is a query param key pageOnForwardCtaPath being passed.'
        );
      }

      this.config.pageOnForwardCtaPath = this.activatedRoute.snapshot.queryParams['pageOnForwardCtaPath'].split('/');
    }
  }

  /**
   * Call this to move any url params from the snapshot into the
   * ForwardCtaPath array. /route/:replace/test -> /route/taco/test
   *
   * Directly adjust config.pageOnForwardCtaPath as applicable
   */
  private checkAndReplaceFragmentParams() {
    //If the route snapshot has param information, see if it needs to be filled in
    if (this.activatedRoute.snapshot.params) {
      this.config.pageOnForwardCtaPath = this.config.pageOnForwardCtaPath.map((routeFragment: string) => {
        if (routeFragment.startsWith(':')) {
          const paramValue = this.activatedRoute.snapshot.params[routeFragment.slice(1)];

          if (!paramValue) {
            console.warn(`Your pageOnForwardCtaPath is expected params in the route with ${routeFragment} prefix.`);
            return routeFragment;
          }

          return paramValue;
        }

        return routeFragment;
      });
    }
  }

  /**
   * Will use filterOutTheseQueryParams string[] to filter out
   * keys that should not be passed along in the query params.
   *
   * @param queryParams Params
   * @returns Params
   */
  private filterQueryParams(queryParams: Params) {
    this.filterOutTheseQueryParams.forEach((key: string) => {
      if (queryParams[key]) {
        delete queryParams[key];
      }
    });

    return queryParams;
  }

  handleMoveForward() {
    const options = { queryParams: this.filterQueryParams({ ...this.activatedRoute?.snapshot?.queryParams }) };

    if (this.config.pageOnForwardCtaData && Object.keys(this.config.pageOnForwardCtaData || {}).length > 0) {
      options.queryParams = this.config.pageOnForwardCtaData;
    }

    this.checkAndReplaceFragmentParams();

    this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath, options);
  }
}
