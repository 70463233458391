import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import {Auth, Cache} from "aws-amplify";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GuidGuard implements CanActivate {
  constructor(private authService: AuthService, private router:Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return from(Auth.currentAuthenticatedUser().then(
      user => {
        if (!user) {
          this.router.navigate(['registration/create-account']);
          return false;
        } else {
          if (state.url.includes('home')) {
            if (user.signInUserSession.idToken.payload['custom:guid']) {
              return true;
            } else {
              this.router.navigate(['registration/select-employer']);
              return false;
            }
          } else {
            // else is meant to cover registration paths
            if (user.signInUserSession.idToken.payload['custom:guid']) {
              this.router.navigate(['/home']);
              return false;
            } else {
              return true;
            }
          }
        }
      }
    ));
  }
}
