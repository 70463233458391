<div class="list-wrapper" [ngClass]="{'block': blockStyle}" [ngStyle]="{'backgroundColor': blockStyle ? bgColor : ''}">
  <div class="flex-row space-between">
    <div *ngIf="hasAvatar" class="mr-sm">
      <bw-avatar [iconName]="avatarIconName" [iconColor]="avatarIconColor" avatarSize="medium"></bw-avatar>
    </div>
    <div class="flex-column flex-item-flexible">
      <typography *ngIf="hasTitle" class="type-sm-bold primary-text">{{listTitle}}</typography>
      <typography *ngIf="hasDetail" class="type-sm primary-text">{{listDetail}}</typography>
    </div>
    <div *ngIf="hasRightIcon" class="align-right ml-sm">
      <bw-icon [iconName]="rightIconName" size="24" [color]="rightIconColor"></bw-icon>
    </div>
  </div>
</div>
<div class="mb-sm"></div>
