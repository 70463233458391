import { Injectable } from '@angular/core';
import {from, Observable, of} from "rxjs";
import {ApiCacheService} from "./api-cache.service";
import {EligibilityFieldsObject} from "./api-contracts";
import {API, Cache} from "aws-amplify";

export interface ProdEligResponseInterface {
  products: ProdEligObjInterface[]
}

export interface ProdEligObjInterface {
  name: string;
  required_fields?: ProdEligReqFieldsInterface[]
}

export interface ProdEligReqFieldsInterface {
  name: string;
  api_parameter_name: string;
  unique_identifier_flag?: boolean;
  sub_fields?: EligibilityFieldsObject[];
  not_replayable?: boolean;
  value?: string;
}

export enum ProductPostIntroMapping {
  '/external-accounts/intro/launch' = 'cashFlow',
  '/home/savings/consents' = 'payrollSavings',
  '/credit-setup/consents' = 'creditScore',
  '/loans/consents' = 'loan'
}

@Injectable({
  providedIn: 'root'
})
export class ProductEligibilityService {

  completedVerifications: string[] = [];
  alreadyCompleted: boolean;


  constructor(
    private apiCache: ApiCacheService,
  ) {
    const routeKeys = Object.keys(ProductPostIntroMapping);
    routeKeys.forEach(cacheKey => {
      if (Cache.getItem(cacheKey)) {
        this.alreadyCompleted = true;
      }
    })
  }

  getProductEligibility() : Observable<ProdEligResponseInterface>{
    const path = '/client/product/data-collect?fieldVersion=1.1';
    return from(API.get('api-mobile', path, {}));
  }

  didAlreadyFinish(): boolean {
    return this.alreadyCompleted;
  }

  getProductCodeRedirect(productCode: string): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const redirectPath = Object.keys(ProductPostIntroMapping)[Object.values(ProductPostIntroMapping).indexOf(productCode)];
    return redirectPath ? redirectPath : '/';
  }

}
