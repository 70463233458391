import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

/**
 * Panel that serves as a container for content
 * Default width is narrow, but can pass in panelSize="large" to increase the width
 */
@Component({
  selector: 'bw-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent  {
  /** The size of the panel. Default is narrow (~360px), but will accept a value of 'large'. More sizes and flexibility to come... */
  @Input() panelSize: string;

  constructor() {}

}
