import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MicroRouteFindDataByKey, MicroUtilTranslationService } from '@micro-core/utility';
import { UiTemplateFlyerInterface } from '@micro-ui/template/page';

import { MicroContestAppResponse, MicroContestAppService } from '../../../service/micro-contest-app.service';

import { MicroContestRouteDataKey, MicroContestTranslationKey } from '../../../model/contest.enum';

type DynamicTranslation = Partial<Record<MicroContestTranslationKey, string>>;

@Injectable()
export class MicroContestResultResolver implements Resolve<UiTemplateFlyerInterface> {
  private _contestDetails: MicroContestAppResponse | undefined;

  constructor(
    private currencyPipe: CurrencyPipe,
    private microContestAppService: MicroContestAppService,
    private utilTranslationService: MicroUtilTranslationService
  ) {}

  private getDynamicTranslations(routeSnapshot: ActivatedRouteSnapshot): DynamicTranslation {
    this._contestDetails = MicroRouteFindDataByKey<MicroContestAppResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_DETAIL
    );

    const messageValues = {
      prizeAmount: `${this.currencyPipe.transform(
        this._contestDetails?.data?.messageValues.prizeAmount || '',
        'USD',
        'symbol',
        '1.0'
      )}`,
      numberOfWinners: this._contestDetails?.data?.messageValues.numberOfWinners,
    };

    return {
      [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE]: this.utilTranslationService.instant(
        MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE,
        messageValues
      ),
    };
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const templateData: UiTemplateFlyerInterface = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
    const dynamicTranslatedValues = this.getDynamicTranslations(routeSnapshot);
    const returnTemplateData: UiTemplateFlyerInterface = {
      ...templateData,
      inputs: {
        ...this.utilTranslationService.deepCopyKeySwapForDynamicValue(templateData.inputs, dynamicTranslatedValues),
      },
    };

    //We are updating contest as viewed here because we know the client is being shown win/lost
    if (this._contestDetails?.data?.id) {
      this.microContestAppService.updateResultsAsViewed({ id: this._contestDetails.data.id });
    }

    return returnTemplateData;
  }
}
