<bw-spinner *ngIf="!formIsReady;"></bw-spinner>
<bw-action-article *ngIf="showElectiveProducts" [brightsideWebFb]="{ event_name: 'program_select' }">
  <bw-back *ngIf="showBackButton"
           header-actions
           [brightsideWebFb]="{ event_name: 'program_select' }"
           [emit]="emitBackButton"
           (back)="backClicked()"
  >
  </bw-back>
  <div header-title>
    <div class="flex-row">
      <typography title-medium class="mr-md">
        {{ 'PROGRAM_SELECT_TITLE' | translate }}
      </typography>
      <ng-container *ngIf="!skipAccountCreation">
        <bw-icon *ngIf="employmentType==='dependent'"
                 iconName="check_badge" color="var(--brand)" size="52"
                 backgroundColor="var(--bg-blue)"
        ></bw-icon>
        <img *ngIf="!displayDefaultIcon && employmentType!=='dependent'" width="52" src="{{ cdnUrl }}images/app/{{company|lowercase}}.png" (error)="displayDefaultIcon = true">
      </ng-container>
    </div>
  </div>
  <div content-section>
    <bw-paragraph>
      {{ 'PROGRAM_SELECT_DESC' | translate:{company:company|titlecase} }}
    </bw-paragraph>
    <section>
      <form [formGroup]="electiveProductsForm" novalidate>
        <div *ngFor="let choice of electiveProductChoices; let i=index">
          <ng-container>
            <bw-radio
              formControlName="selectedElectiveProduct"
              [name]="'electiveProduct'"
              [value]="choice.value"
              [startSelected]="electiveProductsForm.value.selectedElectiveProduct.indexOf(choice.value) > -1"
              [label]="'PROGRAM_SELECT_' + choice.value | translate"
              (radioClick)="productRadioChanged($event, choice.value)">
            </bw-radio>
          </ng-container>
        </div>
      </form>
    </section>
  </div>
  <button
    bw-button
    footer-actions
    (click)="programContinue()"
    [disabled]="electiveProductsForm.invalid"
    [brightsideWebFb]="{event_name: 'program_select', custom: { 'type': selectedProducts }}"
  >{{ 'BUTTON_CONTINUE' | translate }}</button>
</bw-action-article>


<bw-action-article *ngIf="(!showCollectPrimaryForm && !showElectiveProducts) && formIsReady" [brightsideWebFb]="mainAnalytics">
  <bw-back *ngIf="showBackButton && !skipAccountCreation"
    header-actions
    [brightsideWebFb]="{ event_name: analyticName }"
    [emit]="emitBackButton"
     (back)="backClicked()"
  >
  </bw-back>
  <div header-title>
    <div class="flex-row">
      <typography title-medium class="mr-md"><ng-content select="[title-copy]"></ng-content></typography>
      <ng-container *ngIf="!skipAccountCreation">
        <bw-icon *ngIf="employmentType==='dependent'"
                 iconName="check_badge" color="var(--brand)" size="52"
                 backgroundColor="var(--bg-blue)"
        ></bw-icon>
        <img *ngIf="!displayDefaultIcon && employmentType!=='dependent'" width="52" src="{{ cdnUrl }}images/app/{{company|lowercase}}.png" (error)="displayDefaultIcon = true">
      </ng-container>
    </div>
  </div>
  <div content-section>
    <ng-content select="[intro-paragraph]"></ng-content>
    <ng-container>
      <bw-spinner *ngIf="!formIsReady;"></bw-spinner>
      <form *ngIf="formIsReady;" [formGroup]="eligibilityForm" (submit)="onSubmit();">
        <div class="flex-row flex-start flex-wrap mb-sm">
          <div *ngFor="let controlConfig of formControls; let i = index;"
               class="mb-sm flex-item {{controlConfig.classes}}">
            <bw-input
              [isCustomControl]="true"
              [label]="controlConfig.labelKey | translate"
              [inputError]="controlConfig.control.invalid &&
                (
                  controlConfig.control[controlConfig.inputOptions.beginValidationOn] ?
                  controlConfig.control[controlConfig.inputOptions.beginValidationOn] : controlConfig.control.touched
                ) &&
                controlConfig.errorTextKey !== ''"
              [inputErrorText]="controlConfig.errorTextKey | translate"
              [hasInfo]="controlConfig.showInfo"
              (emitInfoClicked)="displayInfo(controlConfig)"
              [isFocused]="controlConfig.hasFocus"
            >
              <div infoCopy [innerHTML]="controlConfig.infoKey | translate"></div>
              <input
                [formControlName]="controlConfig.key"
                label="{{controlConfig.labelKey | translate}}"
                pattern="{{controlConfig.inputOptions?.pattern}}"
                minlength="{{controlConfig.inputOptions?.minlength}}"
                maxlength="{{controlConfig.inputOptions?.maxlength}}"
                type="{{controlConfig.type}}"
                (focus)="controlConfig.hasFocus = true;"
                (blur)="controlConfig.hasFocus  = false;"
              />
            </bw-input>
            <ng-container *ngIf="controlConfig.key === 'workState'"
                          [ngTemplateOutlet]="statesDropdown"
                          [ngTemplateOutletContext]="{controlConfig:controlConfig}">
            </ng-container>
          </div>
          <ng-container *ngIf="hasConsent">
            <bw-checkbox formControlName="consentItem">
              <typography text-medium-semibold color="var(--primary-text)">
                {{ 'ALIAS_CONSENT_TITLE' | translate }}
              </typography>
              <typography text-medium-regular color="var(--secondary-text)"
                          [innerHTML]="'ALIAS_CONSENT_DESC' | translate:{linkPath: consentPath}"></typography>
            </bw-checkbox>
          </ng-container>
          <button
            hidden
            type="submit"
            [brightsideWebFb]="{event_name: analyticName}"
            [disabled]="eligibilityForm.invalid">
          </button>
        </div>
      </form>
    </ng-container>
  </div>
  <button
    *ngIf="eligibilityForm"
    bw-button
    footer-actions
    (click)="onSubmit()"
    [disabled]="eligibilityForm.invalid"
    [processing]="processing"
    [brightsideWebFb]="{event_name: analyticName}"
  >{{ 'SUBMIT' | translate }}</button>
</bw-action-article>

<bw-action-article *ngIf="showCollectPrimaryForm" [brightsideWebFb]="{event_name: 'verify_primary'}">
  <bw-back *ngIf="showBackButton"
           header-actions
           [brightsideWebFb]="{ event_name: 'verify_primary' }"
           [emit]="true"
           (back)="showCollectPrimaryForm = false"
  >
  </bw-back>
  <div header-title>
    <div class="flex-row">
      <typography title-medium class="mr-md">
        {{ 'PRIMARY_VERIFICATION_TITLE' | translate }}
      </typography>
      <bw-icon iconName="check_badge" color="var(--brand)" size="52"
               backgroundColor="var(--bg-blue)"
      ></bw-icon>
<!--      <img *ngIf="!displayDefaultIcon" width="52" src="{{ cdnUrl }}images/app/{{company|lowercase}}.png" (error)="displayDefaultIcon = true">-->
    </div>
  </div>
  <div content-section>
    <bw-paragraph>
      {{ 'PRIMARY_VERIFICATION_DESC' | translate:{company:company|titlecase} }}
    </bw-paragraph>
    <ng-container>
      <bw-spinner *ngIf="!formIsReady;"></bw-spinner>
      <form [formGroup]="primaryEmployeeForm" >
        <div class="flex-row flex-start flex-wrap mb-sm">
          <div *ngFor="let controlConfig of primaryEmployeeFormControls; let i = index;"
               class="mb-sm flex-item {{controlConfig.classes}}">
            <bw-input
              [isCustomControl]="true"
              [label]="controlConfig.labelKey | translate"
              [inputError]="controlConfig.control.invalid &&
                (
                  controlConfig.control[controlConfig.inputOptions.beginValidationOn] ?
                  controlConfig.control[controlConfig.inputOptions.beginValidationOn] : controlConfig.control.touched
                ) &&
                controlConfig.errorTextKey !== ''"
              [inputErrorText]="controlConfig.errorTextKey | translate"
              [hasInfo]="controlConfig.showInfo"
              (emitInfoClicked)="displayInfo(controlConfig)"
              [isFocused]="controlConfig.hasFocus"
            >
              <input
                [formControlName]="controlConfig.key"
                label="{{controlConfig.labelKey | translate}}"
                pattern="{{controlConfig.inputOptions?.pattern}}"
                minlength="{{controlConfig.inputOptions?.minlength}}"
                maxlength="{{controlConfig.inputOptions?.maxlength}}"
                type="{{controlConfig.type}}"
                (focus)="controlConfig.hasFocus = true;"
                (blur)="controlConfig.hasFocus  = false;"
              />
            </bw-input>
          </div>
          <button
            hidden
            type="submit"
            (click)="onSubmit()"
            [brightsideWebFb]="{event_name: 'verify_primary'}"
            [disabled]="primaryEmployeeForm.invalid">
          </button>
        </div>
      </form>

    </ng-container>
  </div>
  <button
    *ngIf="primaryEmployeeForm"
    bw-button
    footer-actions
    (click)="onSubmit()"
    [disabled]="primaryEmployeeForm.invalid"
    [processing]="processing"
    [brightsideWebFb]="{event_name: 'verify_primary'}"
  >{{ 'SUBMIT' | translate }}</button>
</bw-action-article>




<bw-modal *ngIf="showInfoModal"
          (closeModal)="showInfoModal = false; analytics.logEvent(displayModal.eventName)"
          [brightsideWebFb]="{ event_name: displayModal.eventName }">
  <typography title-medium color="var(--gray-70)">{{ displayModal.titleKey | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ displayModal.bodykey | translate }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [autofocus]="true"
        (click)="showInfoModal = false"
        [brightsideWebFb]="{ event_name: displayModal.eventName }">
        {{ displayModal.ctaKey | translate }}
      </button>
    </div>
  </footer>
</bw-modal>


<bw-modal
  *ngIf="showErrorModal"
  (closeModal)="closeErrorModal();"
  [brightsideWebFb]="{event_name: 'error_shown', custom: {'error id': displayError.errorId }}"
  [error_view]="true"
  [showCloseButton]="showErrorModalCloseBtn">
  <typography title-medium color="var(--gray-70)">
    <ng-container [ngSwitch]="displayError.errorId">
      <ng-container *ngSwitchCase="'out_of_pilot'">
        {{ displayError.errorTitleKey | translate:{state:selectedState} }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ displayError.errorTitleKey | translate }}
      </ng-container>
    </ng-container>
  </typography>
  <section class="body">
    <typography text-medium-regular class="mb-sm" color="var(--gray-50)">
      <ng-container [ngSwitch]="displayError.errorId">
        <ng-container *ngSwitchCase="'client_already_exist'">
          <div [innerHTML]="displayError.errorDescKey | translate:{phone:userNumber}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'out_of_pilot'">
          <div [innerHTML]="displayError.errorDescKey | translate"></div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div [innerHTML]="displayError.errorDescKey | translate:{phone:supportNumber|phone, company:company|titlecase, hours:'(' + faHours + ')'}"></div>
        </ng-container>
      </ng-container>
    </typography>
    <ng-container *ngIf="displayError.faqCount">
      <typography text-medium-regular class="mb-sm" color="var(--gray-50)">
        {{ 'FAQ' | translate }}
      </typography>
      <div class="mb-sm" *ngFor="let _ of [].constructor(displayError.faqCount); let i = index">
        <typography text-small-semibold color="var(--gray-50)">
            {{ displayError.errorId.toUpperCase() + '_FAQ_' + (i+1) | translate }}
        </typography>
        <typography text-small-regular color="var(--gray-50)">
            {{ displayError.errorId.toUpperCase() + '_FAQ_' + (i+1) + '_DESC' | translate }}
        </typography>
      </div>
    </ng-container>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse" *ngIf="displayError.errorId !== 'out_of_pilot'">
      <button
        bw-button
        (click)="closeErrorModal(true);"
        [brightsideWebFb]="{ event_name: displayError.errorId }">
        {{ displayError.ctaKey | translate }}
      </button>
      <a *ngIf="displayError.ctaKey2" class="mr-md" href="" >
        {{ displayError.ctaKey2 | translate }}
      </a>
    </div>
  </footer>
</bw-modal>

<ng-template #statesDropdown let-controlConfig="controlConfig">
  <div class="list-container state-list">
    <div [@slideInOut]="controlConfig.hasFocus === true ? 'in' : 'out'"
      class="list-display-container">
      <ul>
        <li *ngFor="let state of statesToDisplayArray; let idx = index" class="item">
          <bw-cell class="address-cell"
                   [iconNameRight]="'chevron_right'"
                   [contentLeft]="true"
                   separatorType="full"
                   (clicked)="stateClicked(state, controlConfig)">
            <ng-container content-left>
              <typography text-medium-regular>
                <p>{{ state }}</p>
              </typography>
            </ng-container>
          </bw-cell>
        </li>
        <li *ngIf="statesToDisplayArray.length === 0">
          <bw-cell class="address-cell"
                   [contentLeft]="true"
                   separatorType="none">
            <ng-container content-left>
              <typography text-medium-regular>
                <p>No states found...</p>
              </typography>
            </ng-container>
          </bw-cell>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
