import { Injectable } from '@angular/core';
import {
  ApiCacheService,
  ApiResponse,
  DesktopCard,
  PayrollAccountResponse,
  PayrollScheduleResponse,
} from '@brightside-web/desktop/data-access/shared';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API, Cache, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';


@Injectable({
  providedIn: 'root',
})
export class LoansService {
  constructor(private apiCache: ApiCacheService) {
    Hub.listen('LoansChannel', (data: HubCapsule) => {
      if (data.payload.event === 'refreshStatus') {
        this.getPayrollAccountStatus(true).subscribe((response) =>
          Hub.dispatch('LoansChannel', { event: 'accountRefresh', data: response })
        );
      }
    });
  }

  getLoanState(cardType?: string): LoanState {
    if (cardType) {
      return Cache.getItem(`loanState${cardType}`);
    } else {
      return Cache.getItem(`loanState${Cache.getItem('activeCaseType')}`);
    }
  }

  async groomCardData(startingData: DesktopCard): Promise<DesktopCard> {
    const pathPieces = startingData.ctaPath?.split(';') || [];
    const keyvalpairs = pathPieces.slice(1);
    const kvmap = keyvalpairs.reduce((p, c) => {
      const keyval: string[] = c.split('=');
      p[keyval[0]] = keyval[1];
      return p;
    }, {} as Record<string, string>);
    const loanState: LoanState = {
      type: kvmap['type'] as LoanFundingType,
      vendor: kvmap['vendor'],
      consent: kvmap['consent']?.split(','),
      cardType: kvmap['cardtype']
    };
    Cache.setItem(`loanState${kvmap['cardtype']}`, loanState);


    startingData.ctaPath = '/loans/landing';
    return startingData;
  }

  // MW payroll-loan.service getLoanRedirectUrl
  /**
   * does not throw API errors, it maps them to a LoanLinkResponse with errorMessage populated
   * @param vendor
   */
  getLoanLink(vendor: string): Observable<LoanLinkResponse> {
    return this.apiCache.get<LoanLinkResponse>('api-mobile', `/loans/payrollbased?provider=${vendor}`).pipe(
      // apiCache does not cache an error response (how could we both cache it and return cached response as an error)
      catchError((err) => of(err.response.data))
    );
  }

  // MW payroll-loan.service createPayrollCustodialAccount
  createPayrollCustodialAccount(): Observable<ApiResponse> {
    this.apiCache.refreshItem(`/loans/payrollbased?provider=${this.getLoanState().vendor}`);
    this.apiCache.refreshItem(`/payroll/account`);
    return from(API.post('api-mobile', '/payroll', { headers: { 'Content-Type': 'application/json' } }));
  }

  // MW payroll-loan.service fetchPayrollCustodialAccount
  getPayrollAccountStatus(force = false): Observable<string> {
    if (force) {
      this.apiCache.refreshItem('/payroll/account');
    }
    return this.apiCache.get<PayrollAccountResponse>('api-mobile', '/payroll/account').pipe(map((response) => response.status));
  }

  //MW finsol shared payroll.service getPayrollSchedule
  getPayrollSchedule(): Observable<PayrollScheduleResponse> {
    return this.apiCache.get<PayrollScheduleResponse>('api-mobile', '/payroll/schedule');
  }
}
export enum LoanFundingType {
  ACH = 'ach' /** we do not support ach loans at this time **/,
  PAYROLL = 'payroll',
}
export interface LoanState {
  type: LoanFundingType;
  vendor: string;
  consent: string[];
  cardType?: string;
}
export interface LoanLinkResponse {
  link?: string;
  errorMessage?: string;
}

export interface LoanObject {
  [key: string]: any;
}
