import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { finalize } from 'rxjs/operators';

import {
  MicroContestAppService,
  MicroContestAppResponse,
  MicroContestsAppResponse,
} from '../../service/micro-contest-app.service';

import { MicroContestRouteParamKey } from '../../model/contest.enum';

type ReturnResolveType = MicroContestAppResponse;

@Injectable()
export class MicroContestDetailsResolver implements Resolve<ReturnResolveType> {
  private _contests: MicroContestsAppResponse | undefined;

  constructor(private microContestAppService: MicroContestAppService) {}

  private reset() {
    this._contests = undefined;
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const contestId: number =
      routeSnapshot.queryParams[MicroContestRouteParamKey.CONTEST_ID] ||
      routeSnapshot.params[MicroContestRouteParamKey.CONTEST_ID] ||
      1;

    return this.microContestAppService.getDetails({ id: contestId }).pipe(finalize(() => this.reset()));
  }
}
