import { Injectable } from '@angular/core';
import { defer, forkJoin, from, Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { Environment } from '@brightside-web/micro/core/environment';
import {
  ApiCacheService,
  ApiResponse,
  LinkedBank,
  LinkedBankStatus,
  SavingsAccount,
  SavingsAch,
  SavingsTransaction, SavingsTransactionCategory,
} from '@brightside-web/desktop/data-access/shared';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LinkedAccountService } from '@brightside-web/desktop/data-access/account-linking';
import { API } from 'aws-amplify';
import { SavingsTestData } from './savings-test-data';

export const BRIGHTSIDE_SAVINGS_ACCOUNT_ID = 'BRIGHTSIDESAVINGS';
export const BRIGHTSIDE_SAVINGS_INSTITUTION_NAME = 'Brightside';
export const BRIGHTSIDE_SAVINGS_ICON = 'brightside-logo';
export const EXTERNAL_SAVINGS_ICON = 'fi-icon';
export const TRANSFER_FUNDS_ACCOUNT_LIMIT = 1;
// this is just for local testing
const useRealData = true;

@Injectable({
  providedIn: 'root',
})
export class ExternalSavingsService {
  constructor(
    private env: Environment,
    private savingsAccountService: SavingsAccountService,
    private apiCache: ApiCacheService,
    private linkedAccountService: LinkedAccountService
  ) {}

  static hasBrightsideSavingsAccountInAccounts(accounts: InternalOrExternalSavingsAccount[]): boolean {
    return Boolean(accounts.find((account) => account.type === SavingsAccountType.BRIGHTSIDE));
  }

  /**
   * TODO can delete this if nothing is going to use it
   * @param accounts
   */
  static getBrightsideAccountFromAccounts(
    accounts: InternalOrExternalSavingsAccount[]
  ): InternalOrExternalSavingsAccount | undefined {
    const match = accounts.filter((acct) => acct.type === SavingsAccountType.BRIGHTSIDE);
    return match.length ? match[0] : undefined;
  }

  static getSavingsTotalBalanceFromAccounts(accounts: InternalOrExternalSavingsAccount[]): number {
    return accounts.reduce((prev, curr) => prev + curr.balance, 0);
  }

  /**
   * basing status on label may be brittle, we may want to use the status instead
   * Canceled:
   * Status: ‘CANCELED’
   * Pending/Scheduled:
   * Status: ‘PLANNED’, ‘SENT_TO_PAYROLL’, ‘PENDING’, ‘DEDUCTION_TAKEN’
   * @param label
   */
  static _getBrightsideTxStatusFromLabel(label: string): SavingsTransactionStatus {
    if (label.includes(SavingsTransactionStatus.PENDING)) {
      return SavingsTransactionStatus.PENDING;
    } else if (label.includes(SavingsTransactionStatus.CANCELED)) {
      return SavingsTransactionStatus.CANCELED;
    } else if (label.includes(SavingsTransactionStatus.SCHEDULED)) {
      return SavingsTransactionStatus.SCHEDULED;
    }
    return SavingsTransactionStatus.POSTED;
  }

  static _getExternalTxStatusFromStatus(status: string): SavingsTransactionStatus {
    if (status.includes(FinicityTransactionStatus.PENDING)) {
      return SavingsTransactionStatus.PENDING;
    } else if (status.includes(FinicityTransactionStatus.SHADOW)) {
      return SavingsTransactionStatus.PENDING;
    }
    return SavingsTransactionStatus.POSTED;
  }

  /**
   * TODO: desktop is not handling the locked/invalid statuses at this time
   * @param bank
   */
  static _getLinkedStatusFromLinkedBank(bank: LinkedBank): LinkedAccountStatus {
    if (bank.status === LinkedBankStatus.LINKING || bank.status === LinkedBankStatus.LINK_VERIFY_DEPOSIT) {
      return LinkedAccountService.isLinkedAccountVerifiable(bank)
        ? LinkedAccountStatus.UNVERIFIED_ELIGIBLE
        : LinkedAccountStatus.UNVERIFIED_NEW;
    }
    return LinkedAccountStatus.ACTIVE;
  }

  /**
   * TODO: desktop does not implement connect fix session or have UX for showing broken institution logins
   * at that time it would make sense to have a mapping here for LinkedAccountStatus.FIX_LOGIN
   */
  static _getLinkedStatusFromFinicityStatus(): LinkedAccountStatus {
    return LinkedAccountStatus.ACTIVE;
  }

  static allowAddingMoreTransferFundsAccounts(groups: LinkedAccountGroup[]): boolean {
    const tfgroup = groups.find((group) => group.type === LinkedAccountType.TRANSFER_FUNDS);
    if (!tfgroup) {
      return true;
    }
    return tfgroup.banks.length < TRANSFER_FUNDS_ACCOUNT_LIMIT;
  }

  getSavingsAccounts(includeAccountNumber: boolean = false): Observable<InternalOrExternalSavingsAccount[]> {
    const allAccounts: InternalOrExternalSavingsAccount[] = [];
    const observables = [];
    observables.push(this.getBrightsideAccount(includeAccountNumber));
    observables.push(this.getExternalAccounts());
    return forkJoin(observables).pipe(
      switchMap(([bsAccount, extAccts]) => {
        if (bsAccount) {
          allAccounts.push(bsAccount as InternalOrExternalSavingsAccount);
        }
        allAccounts.push(...(extAccts as InternalOrExternalSavingsAccount[]));
        return of(allAccounts);
      })
    );
  }

  _getBrightsideAccount(): Observable<SavingsAccount | undefined> {
    if (useRealData) {
      return this.savingsAccountService.getSavingsAccount();
    }
    return of(SavingsTestData.generateTestObject<SavingsAccount>('SavingsAccount', {}));
  }

  _getExternalAccounts(): Observable<ExternalFinicityAccount[]> {
    if (useRealData) {
      return this.apiCache.get<ExternalFinicityAccount[]>(
        'api-mobile',
        '/cashflow/accounts?feature=externalSavings&excludeStatus=deleted'
      );
    }
    return of([SavingsTestData.generateTestObject<ExternalFinicityAccount>('ExternalFinicityAccount', {})]);
  }

  /**
   *
   * @param includeAccountNumber false if you don't care about the account number (saves api call)
   */
  getBrightsideAccount(includeAccountNumber = true): Observable<InternalOrExternalSavingsAccount | undefined> {
    const observables: Observable<SavingsAccount | SavingsAch | undefined>[] = [this._getBrightsideAccount()];
    if (includeAccountNumber) {
      if (useRealData) {
        observables.push(this.savingsAccountService.getSavingsAch());
      } else {
        observables.push(of(SavingsTestData.generateTestObject<SavingsAch>('SavingsAch', {})));
      }
    }
    return forkJoin(observables).pipe(
      switchMap(([account, achDetails]) => {
        if (account) {
          return of({
            type: SavingsAccountType.BRIGHTSIDE,
            id: BRIGHTSIDE_SAVINGS_ACCOUNT_ID,
            accountName: (account as SavingsAccount).name,
            institutionName: BRIGHTSIDE_SAVINGS_INSTITUTION_NAME,
            balance: account.balance,
            lastFourAccountNumber: (achDetails as SavingsAch)?.account_number?.substr(-4) ?? (account as SavingsAccount).last_four_accnum,
            icon: BRIGHTSIDE_SAVINGS_ICON,
          });
        } else {
          return of(undefined);
        }
      })
    );
  }

  getExternalAccounts(): Observable<InternalOrExternalSavingsAccount[]> {
    return this._getExternalAccounts().pipe(
      switchMap((accounts) => {
        const mapped = accounts.map((account) => ({
          type: SavingsAccountType.EXTERNAL,
          id: account.id,
          accountName: account.name,
          institutionName: account.institutionName,
          balance: account.balance,
          lastFourAccountNumber: account.number,
          icon: account?.branding?.icon || EXTERNAL_SAVINGS_ICON,
        }));
        return of(mapped);
      })
    );
  }

  getAccountFromTypeAndId(type: SavingsAccountType, id: string): Observable<InternalOrExternalSavingsAccount | undefined> {
    if (type === SavingsAccountType.BRIGHTSIDE) {
      return this.getBrightsideAccount(false);
    } else {
      return this.getExternalAccounts().pipe(switchMap((accounts) => of(accounts.find((acct) => acct.id === id))));
    }
  }

  /**
   * TODO remove this if nothing uses it
   */
  getSavingsTotalBalance(): Observable<number> {
    return this.getSavingsAccounts(false).pipe(
      switchMap((accounts) => of(ExternalSavingsService.getSavingsTotalBalanceFromAccounts(accounts)))
    );
  }

  hasBrightsideSavingsAccount(): Observable<boolean> {
    if (useRealData) {
      return this.savingsAccountService.hasFunctionalSavingsAccount();
    }
    return of(true);
  }

  hasExternalSavingsAccount(): Observable<boolean> {
    return this._getExternalAccounts().pipe(switchMap((accounts) => of(Boolean(accounts.length))));
  }

  hasInternalOrExternalSavingsAccount(): Observable<boolean> {
    return this.hasBrightsideSavingsAccount().pipe(mergeMap((v) => defer(() => (v ? of(v) : this.hasExternalSavingsAccount()))));
  }

  getAccountTransactions(type: SavingsAccountType, id: string): Observable<InternalOrExternalSavingsTransaction[]> {
    if (type === SavingsAccountType.BRIGHTSIDE) {
      return this.getBrightsideSavingsTransactions();
    } else {
      return this.getExternalSavingsTransactions(id);
    }
  }

  _getBrightsideSavingsTransactions(filterCategory?: SavingsTransactionCategory): Observable<SavingsTransaction[]> {
    if (useRealData) {
      return this.savingsAccountService.getSavingsTransactions(filterCategory);
    }
    return of([SavingsTestData.generateTestObject<SavingsTransaction>('SavingsTransaction', {})]);
  }

  _getExternalSavingsTransactions(id: string): Observable<ExternalFinicityTransaction[]> {
    if (useRealData) {
      return this.apiCache.get<ExternalFinicityTransaction[]>('api-mobile', `/cashflow/accounts/${id}/transactions`);
    }
    return of([SavingsTestData.generateTestObject<ExternalFinicityTransaction>('ExternalFinicityTransaction', {})]);
  }

  getBrightsideSavingsTransactions(
    filterCategory?: SavingsTransactionCategory
  ): Observable<InternalOrExternalSavingsTransaction[]> {
    return this._getBrightsideSavingsTransactions(filterCategory).pipe(
      switchMap((transactions) => {
        const mapped = transactions.map((tx) => ({
          id: String(tx.id),
          label: tx.label || '',
          amount: tx.is_credit ? tx.amount : -tx.amount,
          createdDate: tx.transaction_date || '',
          status: ExternalSavingsService._getBrightsideTxStatusFromLabel(tx.label || ''),
        }));
        return of(mapped);
      })
    );
  }

  getExternalSavingsTransactions(id: string): Observable<InternalOrExternalSavingsTransaction[]> {
    return this._getExternalSavingsTransactions(id).pipe(
      switchMap((transactions) => {
        const mapped = transactions.map((tx) => ({
          id: tx.transactionId,
          label: tx.merchant,
          amount: tx.amount,
          createdDate: tx.transactionDateStr,
          status: ExternalSavingsService._getExternalTxStatusFromStatus(tx.status),
        }));
        return of(mapped);
      })
    );
  }

  /**
   * TODO remove if nothing uses this
   * @param id
   */
  getAccountBalance(id: string): Observable<number> {
    return this.getSavingsAccounts(false).pipe(
      switchMap((accounts) => of(Number(accounts.find((acct) => acct.id === id)?.balance)))
    );
  }

  _getAchLinkedBanks(): Observable<LinkedBank[]> {
    if (useRealData) {
      return this.savingsAccountService.getLinkedBanks();
    }
    return of([]);
  }

  getAchLinkedBanks(): Observable<InternalOrExternalLinkedAccount[]> {
    return this._getAchLinkedBanks().pipe(
      switchMap((banks) => {
        const mapped = banks.map((bank) => ({
          id: String(bank.id),
          type: LinkedAccountType.TRANSFER_FUNDS,
          institutionName: bank.bank_name,
          accountType: bank.account_class,
          accountName: bank.account_class,
          lastFourAccountNumber: bank.last_four,
          institutionLogo: bank.bank_logo || EXTERNAL_SAVINGS_ICON,
          status: ExternalSavingsService._getLinkedStatusFromLinkedBank(bank),
        }));
        return of(mapped);
      })
    );
  }

  _getExternalAccountsAsLinked(): Observable<InternalOrExternalLinkedAccount[]> {
    return this._getExternalAccounts().pipe(
      switchMap((accounts) => {
        const mapped = accounts.map((account) => ({
          id: account.id,
          type: LinkedAccountType.TRACK_BALANCE,
          institutionLoginId: account.institutionLoginId,
          institutionName: account.institutionName,
          accountType: account.type,
          accountName: account.name,
          lastFourAccountNumber: account.number,
          institutionLogo: account?.branding?.icon || EXTERNAL_SAVINGS_ICON,
          status: ExternalSavingsService._getLinkedStatusFromFinicityStatus(),
        }));
        return of(mapped);
      })
    );
  }

  getLinkedAccountGroups(): Observable<LinkedAccountGroup[]> {
    return this.savingsAccountService.hasFunctionalSavingsAccount().pipe(
      switchMap((hasAccount) => {
        const observables = [];
        if (hasAccount) {
          observables.push(this.getAchLinkedBanks());
        } else {
          observables.push(of([]));
        }
        observables.push(this._getExternalAccountsAsLinked());
        return forkJoin(observables).pipe(
          switchMap(([achAccts, extAccts]) => {
            const groups: LinkedAccountGroup[] = [];
            if (achAccts.length) {
              groups.push({
                type: LinkedAccountType.TRANSFER_FUNDS,
                banks: achAccts,
              });
            }
            if (extAccts.length) {
              groups.push({
                type: LinkedAccountType.TRACK_BALANCE,
                banks: extAccts,
              });
            }
            return of(groups);
          })
        );
      })
    );
  }

  /**
   * NOTE we can only unlink entire institutions, Finicity does not give us API to unlink single accounts
   * @param institutionId
   */
  _deleteExternalAccount(institutionId: string): Observable<ApiResponse> {
    return from(
      API.del('api-mobile', `/cashflow/institutions/${institutionId}`, { headers: { 'Content-Type': 'application/json' } })
    ).pipe(
      tap(() => {
        // clearing the cache is not helpful because the deletion is async
        // instead we will manipulate the cached data to remove the item that should get deleted
        // but ONLY on success
        const accounts = this.apiCache.getItem<ExternalFinicityAccount[]>(
          '/cashflow/accounts?feature=externalSavings&excludeStatus=deleted'
        );
        this.apiCache.setItem(
          '/cashflow/accounts?feature=externalSavings&excludeStatus=deleted',
          accounts.filter((account) => account.institutionId !== institutionId)
        );
      })
    );
  }

  unlinkExternalAccount(type: LinkedAccountType, id: string): Observable<ApiResponse> {
    if (type === LinkedAccountType.TRANSFER_FUNDS) {
      return this.linkedAccountService.unlinkAccount(Number(id));
    } else {
      return this._getExternalAccounts().pipe(
        switchMap((accounts) => {
          const acctToDelete = accounts.find((acct) => acct.id === id);
          if (!acctToDelete) {
            throw Error('account to delete not found');
          }
          return this._deleteExternalAccount(acctToDelete.institutionId);
        })
      );
    }
  }
}

export enum SavingsAccountType {
  BRIGHTSIDE = 'Brightside',
  EXTERNAL = 'External',
}

export enum LinkedAccountType {
  TRANSFER_FUNDS = 'Transfer funds',
  TRACK_BALANCE = 'Track balances',
}

export enum SavingsAccountAction {
  AUTOSAVE = 'Autosave',
  TRANSFER = 'Transfer',
  MANAGE_LINKED_ACCOUNT = 'Manage accounts',
  ACCOUNT_DETAILS = 'Account details',
  CONTACT_DETAILS = 'Contact details',
  VIEW_STATEMENTS = 'View statements',
  VIEW_GOALS = 'View goals',
  VIEW_REWARDS = 'View rewards',
  DELETE_ACCOUNT = 'Close account',
}

export interface InternalOrExternalSavingsAccount {
  id: string;
  accountName: string;
  institutionName: string;
  lastFourAccountNumber: string;
  balance: number;
  type: SavingsAccountType;
  icon: string;
}

export enum FinicityAccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export interface ExternalFinicityAccount {
  id: string;
  name: string;
  institutionName: string;
  number: string;
  balance: number;
  institutionId: string;
  institutionLoginId?: string;
  type: FinicityAccountType;
  branding?: { icon: string };
}

export interface ExternalFinicityTransaction {
  transactionId: string;
  amount: number;
  merchant: string;
  transactionDateStr: string;
  status: FinicityTransactionStatus;
}

export enum FinicityTransactionStatus {
  ACTIVE = 'active',
  SHADOW = 'shadow',
  PENDING = 'pending',
}

export enum SavingsTransactionStatus {
  PENDING = 'Pending',
  CANCELED = 'Canceled',
  SCHEDULED = 'Scheduled',
  POSTED = 'Posted',
}

export interface InternalOrExternalSavingsTransaction {
  id: string;
  label: string;
  createdDate: string;
  amount: number;
  status: SavingsTransactionStatus;
  // NOTE these fields are for details but desktop does not support details yet
  // payee: string; // maybe an object?
  // postedDate: string; // can also contain the estimated date (if not yet posted)
}

export interface LinkedAccountGroup {
  type: LinkedAccountType;
  banks: InternalOrExternalLinkedAccount[];
}

export enum LinkedAccountStatus {
  ACTIVE = 'active',
  UNVERIFIED_NEW = 'unverified-new', // for ach transfer funds before deposit verification eligible
  UNVERIFIED_ELIGIBLE = 'verify-deposit', // for ach transfer funds deposit verification eligible
  FIX_LOGIN = 'fix-login', // FUTURE: for finicity institution login error codes
}

export interface InternalOrExternalLinkedAccount {
  id: string;
  type: LinkedAccountType;
  institutionLoginId?: string;
  institutionName: string;
  accountType: string;
  accountName: string;
  lastFourAccountNumber: string;
  institutionLogo: string;
  status: LinkedAccountStatus;
}
