import { Hub } from 'aws-amplify';

import { MessageBusIncomingEventKey, MessageBusOutgoingEventKey, MessageBusEventChannel } from '../../models/message-bus.enum';
import { MessageBusOutgoingEvent, HubPayload } from '../../models/message-bus.interface';

import { MessageBusInternalService } from '../../internal/message-bus-internal.service';
import { MessageBusPlatformBaseMessageHandler } from './platform-base.service';

/**
 * jsHandler = How Android application can speak with this application when deployed inside their webview
 */
// eslint-disable-next-line no-var
declare var jsHandler: any;

const IsRunningAndroid = () => typeof jsHandler !== 'undefined';

/**
 * @description
 * Android specific service
 */
class MessageBusPlatformAndroidMessageHandler extends MessageBusPlatformBaseMessageHandler {
  static TriggerCameraEvent() {
    Hub.dispatch(MessageBusEventChannel.OUTGOING, {
      event: MessageBusOutgoingEventKey.SHOW_CAMERA,
      data: {
        body: {},
      },
    });
  }

  static SetupCameraEventWatcher(element: HTMLInputElement) {
    /**
     * This watcher will wait for events from the camera
     */
    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.CAMERA_RESPONSE],
      take: 1,
      callbackListener: (payload: HubPayload) => {
        const imageSrc = payload.data.body.rawMessage;

        (element.onchange as any)(new Event('fileChange'), imageSrc);
      },
    });
  }

  static TriggerPickerEvent() {
    Hub.dispatch(MessageBusEventChannel.OUTGOING, {
      event: MessageBusOutgoingEventKey.SHOW_PHOTO_PICKER,
      data: {
        body: {},
      },
    });
  }

  static SetupPickerEventWatcher(element: HTMLInputElement) {
    /**
     * This watcher will wait for events from the camera
     */
    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.CAMERA_RESPONSE],
      take: 1,
      callbackListener: (payload: HubPayload) => {
        const imageSrc = payload.data.body.rawMessage;

        (element.onchange as any)(new Event('fileChange'), imageSrc);
      },
    });
  }

  constructor() {
    super();

    HTMLInputElement.prototype.click = new Proxy(HTMLInputElement.prototype.click, {
      apply: function (target, element: HTMLInputElement, receiver) {
        //If we are an input file type with camera set up, trigger show camera
        if (element.type === 'file') {
          if (element.getAttribute('capture') === 'camera') {
            MessageBusPlatformAndroidMessageHandler.TriggerCameraEvent();
            MessageBusPlatformAndroidMessageHandler.SetupCameraEventWatcher(element);
          } else {
            MessageBusPlatformAndroidMessageHandler.TriggerPickerEvent();
            MessageBusPlatformAndroidMessageHandler.SetupPickerEventWatcher(element);
          }
        } else {
          //This calls original click method
          Reflect.apply(target, element, receiver);
        }
      },
    });
  }

  override get messageReceiver() {
    return (event: MessageBusOutgoingEvent) => jsHandler.postMessage(this.prepareNativeEvent(event, true).stringify());
  }
}

export { IsRunningAndroid, MessageBusPlatformAndroidMessageHandler };
