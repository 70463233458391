import { Injectable } from '@angular/core';
import {
  ActivationEnd,
  ActivationStart,
  ChildActivationEnd,
  ChildActivationStart,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized,
  Scroll,
} from '@angular/router';

import { Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  MessageBusEventUtil,
  MessageBusInternalEventKey,
  MessageBusInternalService,
} from '@brightside-web/micro/core/message-bus';

declare global {
  interface Window {
    gTag: Function;
  }
}

interface TempRoutingDetail {
  routeURL: string;
  skipThesePaths: (string | null)[];
}

interface LayoutDetail {
  style: LayoutStyle;
  lastPathKey: string;
  mapping: { [key: string]: LayoutStyle };
}

export type RouteEvent =
  | NavigationStart
  | NavigationEnd
  | NavigationCancel
  | RouteConfigLoadStart
  | RouteConfigLoadEnd
  | RoutesRecognized
  | GuardsCheckStart
  | GuardsCheckEnd
  | ActivationStart
  | ActivationEnd
  | ChildActivationStart
  | ChildActivationEnd
  | ResolveStart
  | ResolveEnd
  | Scroll;

export enum EVENT_METHOD_NAME {
  UnKnown = 'onUnknown',
  NavigationStart = 'onNavigationStart',
  NavigationEnd = 'onNavigationEnd',
  NavigationCancel = 'onNavigationCancel',
  RouteConfigLoadStart = 'onRouteConfigLoadStart',
  RouteConfigLoadEnd = 'onRouteConfigLoadEnd',
  RoutesRecognized = 'onRoutesRecognized',
  GuardsCheckStart = 'onGuardsCheckStart',
  GuardsCheckEnd = 'onGuardsCheckEnd',
  ActivationStart = 'onActivationStart',
  ActivationEnd = 'onActivationEnd',
  ChildActivationStart = 'onChildActivationStart',
  ChildActivationEnd = 'onChildActivationEnd',
  ResolveStart = 'onResolveStart',
  ResolveEnd = 'onResolveEnd',
  Scroll = 'onScroll',
}

export enum LayoutStyle {
  DEFAULT = 'default',
  FULL_SCREEN = 'fullScreen',
  PUBLIC_ACCESS = 'publicAccess',
}

export enum RoutingServiceDispatchEvent {
  ROUTE_BY_URL = 'ROUTE_BY_URL',
  LAYOUT_CHANGE = 'LAYOUT_CHANGE',
}

@Injectable()
export class RoutingService {
  static readonly DISPATCH_KEY = 'RoutingServiceChannel';

  protected googleTrackingId = '';
  protected isProduction = false;

  protected executeAllOnEvent: Function[] = [];

  protected lastEvent: RouteEvent;
  protected lastLayout: LayoutDetail = {
    style: LayoutStyle.DEFAULT,
    lastPathKey: '',
    mapping: {},
  };

  // This is used to make sure we don't send unneeded layout events
  protected lastBroadcastedLayoutStyle: LayoutStyle | null = null;

  protected tempRouteDetails: TempRoutingDetail = {
    routeURL: '',
    skipThesePaths: [null, '', 'registration'],
  };

  protected layoutLifeCycleEvents = {
    [EVENT_METHOD_NAME.ActivationStart]: (event: ActivationStart) => {
      const path = this.tempRouteDetails.routeURL;

      if (this.tempRouteDetails.skipThesePaths.includes(path)) {
        return;
      }

      if (event.snapshot?.data?.appDisplayStyle) {
        this.lastLayout.mapping[path] = event.snapshot?.data?.appDisplayStyle || LayoutStyle.DEFAULT;

        this.runLayoutCheck(this.lastLayout.mapping[path]);
      }
    },
    [EVENT_METHOD_NAME.ChildActivationStart]: (event: ChildActivationStart) => {
      const path = this.tempRouteDetails.routeURL;

      if (this.tempRouteDetails.skipThesePaths.includes(path)) {
        return;
      }

      //First check snapshot has data fallback towards array lookup
      if (event.snapshot.data?.appDisplayStyle) {
        this.lastLayout.mapping[path] = event.snapshot.data.appDisplayStyle;
        this.runLayoutCheck(this.lastLayout.mapping[path]);
      } else {
        this.lastLayout.lastPathKey = path;
        this.lastLayout.style =
          this.lastLayout.mapping[this.lastLayout.lastPathKey] || this.lastLayout.style || LayoutStyle.DEFAULT;
      }
    },
    [EVENT_METHOD_NAME.ResolveEnd]: (event: ResolveEnd) => {
      this.checkAndDispatchLayoutChange();
    },
    [EVENT_METHOD_NAME.ActivationEnd]: (
      event: ActivationEnd | ChildActivationEnd,
      styleFromSnapshotOverride?: LayoutStyle | null
    ) => {
      const styleFromSnapshot = styleFromSnapshotOverride || event.snapshot?.firstChild?.data?.appDisplayStyle;
      const styleFromPath = this.lastLayout.mapping[this.tempRouteDetails.routeURL];

      const styleArrays = [styleFromSnapshot, styleFromPath, this.lastLayout.style];
      const theOneTrueStyle = styleArrays.filter((style) => style && style !== 'default')[0] || 'default';

      //If we have anything left in the styles we should use it
      if (theOneTrueStyle) {
        this.lastLayout.style = theOneTrueStyle;
      }

      this.checkAndDispatchLayoutChange();
    },
    // Child activation should just flow to activation end with styleOverride from snapshot
    [EVENT_METHOD_NAME.ChildActivationEnd]: (event: ChildActivationEnd) =>
      this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationEnd](event, event.snapshot.data?.appDisplayStyle || null),
  };

  constructor(private router: Router, isProduction: boolean = false, googleTrackingId: string = '') {
    this.isProduction = isProduction;
    this.googleTrackingId = googleTrackingId;

    this.watchForRoutingEvents();
    this.listenForRouteByUrl();
  }

  protected consoleLog(...rest: any) {
    if (!this.isProduction) {
      console.log('RoutingService', { ...rest });
    }
  }

  protected getMethodOrNoop(methodName: EVENT_METHOD_NAME = EVENT_METHOD_NAME.UnKnown) {
    if (RoutingService.prototype[methodName]) {
      return this[methodName].bind(this);
    }

    return () => false;
  }

  protected getEventMethod(event: RouteEvent): EVENT_METHOD_NAME {
    this.lastEvent = event;

    switch (true) {
      case event instanceof NavigationStart:
        return EVENT_METHOD_NAME.NavigationStart;
      case event instanceof NavigationEnd:
        return EVENT_METHOD_NAME.NavigationEnd;
      case event instanceof NavigationCancel:
        return EVENT_METHOD_NAME.NavigationCancel;
      case event instanceof RouteConfigLoadStart:
        return EVENT_METHOD_NAME.RouteConfigLoadStart;
      case event instanceof RouteConfigLoadEnd:
        return EVENT_METHOD_NAME.RouteConfigLoadEnd;
      case event instanceof RoutesRecognized:
        return EVENT_METHOD_NAME.RoutesRecognized;
      case event instanceof GuardsCheckStart:
        return EVENT_METHOD_NAME.GuardsCheckStart;
      case event instanceof GuardsCheckEnd:
        return EVENT_METHOD_NAME.GuardsCheckEnd;
      case event instanceof ActivationStart:
        return EVENT_METHOD_NAME.ActivationStart;
      case event instanceof ActivationEnd:
        return EVENT_METHOD_NAME.ActivationEnd;
      case event instanceof ChildActivationStart:
        return EVENT_METHOD_NAME.ChildActivationStart;
      case event instanceof ChildActivationEnd:
        return EVENT_METHOD_NAME.ChildActivationEnd;
      case event instanceof ResolveStart:
        return EVENT_METHOD_NAME.ResolveStart;
      case event instanceof ResolveEnd:
        return EVENT_METHOD_NAME.ResolveEnd;
      case event instanceof Scroll:
        return EVENT_METHOD_NAME.Scroll;

      default:
        return EVENT_METHOD_NAME.UnKnown;
    }
  }

  protected runAllOnEvents() {
    if (!this.executeAllOnEvent) {
      return;
    }

    this.executeAllOnEvent.forEach((fn) => fn());
  }

  protected runLayoutCheck(checkThisPath: string) {}

  protected watchForRoutingEvents() {
    this.router.events.subscribe((event) => {
      const routeMethod = this.getEventMethod(event);

      //Uncomment to get console logs for debugging
      //this.consoleLog('route event', routeMethod, event);

      this.getMethodOrNoop(routeMethod)(this.lastEvent as any);
    });
  }

  protected checkAndDispatchLayoutChange() {
    this.dispatchLayoutChange();
  }

  protected resetLastLayout() {
    //We need to reset the layout stuff after dispatch
    this.lastLayout.style = LayoutStyle.DEFAULT;
    this.lastLayout.lastPathKey = '';
  }

  protected updateLastBroadcastedAndTimer() {
    this.lastBroadcastedLayoutStyle = this.lastLayout.style;

    //Clear this.lastBroadcastedLayoutStyle so events that happen on further events still trigger
    setTimeout(() => {
      this.lastBroadcastedLayoutStyle = null;
    }, 500);
  }

  protected listenForRouteByUrl() {
    Hub.listen(RoutingService.DISPATCH_KEY, (data: HubCapsule) => {
      if (data.payload.event === RoutingServiceDispatchEvent.ROUTE_BY_URL && data.payload.data) {
        this.router.navigateByUrl(data.payload.data.routeToUrl || '');
      }
    });
  }

  protected dispatchLayoutChange() {
    if (this.lastBroadcastedLayoutStyle === this.lastLayout.style) {
      return;
    }

    Hub.dispatch(RoutingService.DISPATCH_KEY, {
      event: RoutingServiceDispatchEvent.LAYOUT_CHANGE,
      message: this.lastLayout.style || LayoutStyle.DEFAULT,
    });

    this.consoleLog('Layout Change', this.lastLayout.style);
    this.updateLastBroadcastedAndTimer();
    this.resetLastLayout();
  }

  addOnEventFunction(fn: Function) {
    this.executeAllOnEvent.push(fn);
  }

  [EVENT_METHOD_NAME.UnKnown](event: RouteEvent) {
    this.consoleLog('Unknown routing event occurred', this.lastEvent);
  }

  [EVENT_METHOD_NAME.NavigationStart](event: NavigationStart) {
    this.runAllOnEvents();
  }
  [EVENT_METHOD_NAME.NavigationEnd](event: NavigationEnd) {
    if ((window as any).gTag) {
      window.gTag('config', this.googleTrackingId, {
        page_path: event.urlAfterRedirects,
      });
    }

    MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.hideLoadingSpinner);
    MessageBusInternalService.sendInternalHubEvent({
      event: MessageBusInternalEventKey.NAVIGATION_END,
      data: {},
    });
  }
  [EVENT_METHOD_NAME.NavigationCancel](event: NavigationCancel) {
    this.dispatchLayoutChange();
  }
  [EVENT_METHOD_NAME.RouteConfigLoadStart](event: RouteConfigLoadStart) {
    this.tempRouteDetails.routeURL = event.route.path || '';
  }
  [EVENT_METHOD_NAME.RouteConfigLoadEnd](event: RouteConfigLoadEnd) {}
  [EVENT_METHOD_NAME.RoutesRecognized](event: RoutesRecognized) {}
  [EVENT_METHOD_NAME.GuardsCheckStart](event: GuardsCheckStart) {}
  [EVENT_METHOD_NAME.GuardsCheckEnd](event: GuardsCheckEnd) {
    this.consoleLog('GuardCheckEnd', event);
  }
  [EVENT_METHOD_NAME.ActivationStart](event: ActivationStart) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationStart](event);
  }
  [EVENT_METHOD_NAME.ActivationEnd](event: ActivationEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationEnd](event);
  }
  [EVENT_METHOD_NAME.ChildActivationStart](event: ChildActivationStart) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ChildActivationStart](event);
  }
  [EVENT_METHOD_NAME.ChildActivationEnd](event: ChildActivationEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ChildActivationEnd](event);
  }
  [EVENT_METHOD_NAME.ResolveStart](event: ResolveStart) {}
  [EVENT_METHOD_NAME.ResolveEnd](event: ResolveEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ResolveEnd](event);
  }
  [EVENT_METHOD_NAME.Scroll](event: Scroll) {}
}
