<section>
  <header>
    <typography headline-semibold>{{ data.inputs.header?.title || '' | translate }}</typography>

    <p
      *ngIf="data.inputs.header?.subTitle"
      class="text-medium-regular secondary-text mt-sm mb-sm pb-xs"
      [innerHTML]="data.inputs.header?.subTitle || '' | translate"
    ></p>
  </header>

  <ui-content-display *ngIf="data.inputs.content" [data]="data.inputs.content"></ui-content-display>
  <ui-content-display-form *ngIf="data.inputs.formContent" [data]="data.inputs.formContent" (formValuesChanged)="formValueChanged($event)"> </ui-content-display-form>

  <footer class="mt-md">
    <p *ngIf="fromDataFooter.inputs.infoText" class="text-small-regular secondary-text mb-sm pb-xs center">
      {{ fromDataFooter.inputs.infoText | translate }}
    </p>

    <ui-footer-default
      [data]="fromDataFooter"
      (cta1ActionHandler)="fromDataAction.executeAction(action.cta1)"
      (cta2ActionHandler)="fromDataAction.executeAction(action.cta2)"
    ></ui-footer-default>
  </footer>
</section>
