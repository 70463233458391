<div class="content-layout" [brightsideWebFb]="{ event_name: config.fbEventName }">
  <bw-action-article>
    <bw-back header-actions [brightsideWebFb]="{ event_name: config.fbEventName }" (back)="handleBackNavigation()"></bw-back>
    <div pre-title>
      <bw-steps
        *ngIf="showStepper"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="hasCurrentAutosaveSchedule ? config.pageStepperActiveIndexForSkipped : config.pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>

    <div content-section>
      <form [formGroup]="autosaveForm">
        <p class="secondary-text mb-md">{{ pageSubTitle }}</p>

        <ng-container *ngIf="!isCurrentlyPaused">
          <brightside-web-autosave-selector [formGroup]="autosaveForm"></brightside-web-autosave-selector>
          <brightside-web-autosave-frequency [formGroup]="autosaveForm"></brightside-web-autosave-frequency>
        </ng-container>

        <brightside-web-autosave-pause (pauseStatusChanged)="handlePauseStatusChange($event)"></brightside-web-autosave-pause>
      </form>
    </div>

    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="pageCtaLabel && !isCurrentlyPaused"
        bw-button
        type="submit"
        [brightsideWebFb]="{ event_name: config.fbEventName }"
        [disabled]="autosaveForm.invalid"
        [processing]="processing"
        (click)="checkForConfirm()"
      >
        {{ pageCtaLabel }}
      </button>

      <typography text-large-semibold>
        <button *ngIf="pageSecondaryCtaLabel"
           [brightsideWebFb]="{ event_name: config.fbEventName }"
           [secondaryCta]="true"
           bw-link-button class="bs-blue brand pr-xl" (click)="handleSecondaryCtaClick()">{{
          pageSecondaryCtaLabel
        }}</button>
      </typography>
    </div>
  </bw-action-article>
</div>
<bw-modal *ngIf="showConfirm" modalWidth="medium" (closeModal)="showConfirm = false">
  <typography title-medium>{{ 'VERIFY_AUTOSAVE_TITLE' | translate }}</typography>
  <ng-container class="body">
    <div>
      <typography text-large-regular color="var(--secondary-text)" [innerHTML]="'VERIFY_AUTOSAVE_DESC'|translate:{autosaveAmount:selectedAmount.value}">
      </typography>
    </div>
  </ng-container>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="showConfirm=false;handleCtaClick()">
        <typography text-large-semibold>
          {{ 'VERIFY_AUTOSAVE_PRI_CTA' | translate }}
        </typography>
      </button>
      <button bw-link-button class="bs-blue pr-xl" (click)="showConfirm=false;">
        {{ 'VERIFY_AUTOSAVE_SEC_CTA' | translate }}
      </button>
    </div>
  </div>
</bw-modal>
