import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoutingStateService } from '@brightside/brightside-ui-services';

import { MicroCoreUtilRemoteConfigService } from '@micro-core/utility';
import { PageTransitionAnimationTriggers } from '@micro-ui/template/page';

import { AmplitudeService, SavingsAccount, SavingsScheduleType, Settings } from '@brightside-web/desktop/data-access/shared';
import {
  SavingsCompositeService,
  SavingsCompositeAccount,
  SavingsAccountService,
} from '@brightside-web/desktop/data-access/savings';

import { Observable, Subscription } from 'rxjs';

export class DisplayAutosaveSettings extends Settings {
  constructor(config: any = {}) {
    super(config);

    this.pagePieces = config.pagePieces || null;
    this.pageDefaultAmount = config.pageDefaultAmount || 0;
    this.cadence = config.cadence || null;
    this.relativeStart = config.relativeStart || 1;
    this.hasCurrentAutosaveSchedule = config.hasCurrentAutosaveSchedule || false;
    this.templateValues = config.templateValues || {};
    this.processUpdater = config.processUpdater || null;
  }
  pagePieces: DisplayAutosavePieces[];
  pageDefaultAmount: number;

  cadence?: DisplayAutosavePayFrequency;
  relativeStart?: number;
  hasCurrentAutosaveSchedule?: boolean;
  templateValues?: { [key in DisplayAutosaveTemplateKey]: Function };

  processUpdater?: (
    autosaveComponent: DisplayAutosaveComponent,
    details: DisplayAutosaveProcessArgument
  ) => Observable<DisplayAutosaveProcessResponse>;
}

export interface DisplayAutosaveProcessArgument {
  schedule_type: string;
  next_transfer_date: string;
  amount: number;
  source: string | null;
}

export interface DisplayAutosaveProcessResponse {
  status: string;
  errorMessage?: string;
  errorMessageTitle?: string;
}

export enum DisplayAutosavePieces {
  SELECTOR = 'selector',
  FREQUENCY = 'frequency',
  PROJECTIONS = 'projections',
  PAUSE = 'pause_control',
}

export enum DisplayAutosavePayFrequency {
  EVERY = 'everyPaycheck',
  ALTERNATE = 'alternatePaycheck',
}

export enum DisplayAutosaveTemplateKey {
  CADENCE = '$SCHEDULE_CADENCE',
  DATE = '$SCHEDULE_DATE',
}

export const DisplayAutosaveFrequencyCopy = {
  [DisplayAutosavePayFrequency.EVERY]: 'each paycheck',
  [DisplayAutosavePayFrequency.ALTERNATE]: 'every other paycheck',
};

@Component({
  animations: PageTransitionAnimationTriggers,
  selector: 'brightside-web-display-autosave',
  templateUrl: './display-autosave.component.html',
})
export class DisplayAutosaveComponent implements OnInit {
  private sub = new Subscription();
  private hasAPIReturned = false;

  processing: boolean;

  shouldReverseExitAnimation = false;
  hasTransferToSource = false;
  hasCurrentAutosaveSchedule = false;
  hasBeenCurrentlyPaused = false;

  config: DisplayAutosaveSettings;

  account: SavingsAccount;
  scheduleList: string[];

  displayForm: FormGroup = this.formBuilder.group({
    selectedAmount: [0, [Validators.required, Validators.min(1), Validators.max(9999.99)]],
  });
  displayTypes = DisplayAutosavePieces;

  pageIsFullScreen: boolean;

  processErrorMessage: string;
  processErrorMessageTitle: string;

  showProcessError: boolean;
  showConfirm: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private routingState: RoutingStateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public savingsCompositeService: SavingsCompositeService,
    public savingsService: SavingsAccountService,
    public amplitudeService: AmplitudeService,
    public microCoreUtilRemoteConfigService: MicroCoreUtilRemoteConfigService
  ) {
    this.config = new DisplayAutosaveSettings({
      fbPage: 'display',
      fbCategory: 'cards',
      fbEventName: '',
      pageTitle: '',
      pageSubTitle: '',
      pageCtaLabel: 'Continue',
      pageSecondaryCtaLabel: '',

      pagePieces: [],
      pageDefaultAmount: SavingsAccountService.AutosaveInitialAmount(this.microCoreUtilRemoteConfigService),

      pageOnForwardCtaPath: [],
    });

    this.subscribeAndLoadSavings();
  }

  ngOnInit(): void {
    this.clearForm();

    this.checkForConfigViaData();
    this.checkForParams();
  }

  getFbInfo(extendWith?: any) {
    return { page: '', category: '', ...extendWith };
  }

  get pageTitle() {
    this.config.hasCurrentAutosaveSchedule = this.hasCurrentAutosaveSchedule;
    return this.config.pageTitle;
  }

  get pageSubTitle() {
    //ensure the config has the value.
    this.config.hasCurrentAutosaveSchedule = this.hasCurrentAutosaveSchedule;
    let tmplSubTitle = this.config.pageSubTitle || '';
    /*
      typeof this.config.pageSubTitle === 'function'
        ? this.config.pageSubTitle(this.hasCurrentAutosaveSchedule)
        : this.config.pageSubTitle;
    */
    //ToDo: We need a global way to handle string templates
    for (const templateKey in this.config.templateValues) {
      if (tmplSubTitle.indexOf(templateKey) !== -1) {
        const key: DisplayAutosaveTemplateKey = templateKey as DisplayAutosaveTemplateKey;

        tmplSubTitle = tmplSubTitle.replace(templateKey, this.config.templateValues[key](this));
      }
    }

    return tmplSubTitle;
  }

  get selectedAmount() {
    return this.displayForm.get('selectedAmount');
  }

  get payFrequency() {
    if (this.account?.pay_frequency) {
      return this.account.pay_frequency;
    }

    //ToDo: should we default to this?
    return 'Bi-Weekly';
  }

  get nextTransferDate() {
    if (!this.scheduleList || !this.scheduleList[this.config.relativeStart || 0]) {
      return '';
    }

    return this.scheduleList[this.config.relativeStart || 0];
  }

  private checkForConfigViaData() {
    const buildConfig = (newConfig: DisplayAutosaveSettings) => {
      this.config = newConfig;

      this.selectedAmount?.setValue(this.config.pageDefaultAmount);
    };

    buildConfig(new DisplayAutosaveSettings(this.activatedRoute.snapshot?.data?.pageConfig));
  }

  private checkForParams() {
    const updateFromParams = (queryParamMap: ParamMap) => {
      this.selectedAmount?.setValue(+(queryParamMap.get('amount') || this.config.pageDefaultAmount));
      this.config.cadence = queryParamMap.get('cadence') as DisplayAutosavePayFrequency;
      this.config.relativeStart = +(queryParamMap.get('relativeStart') || 0);
    };

    if (this.activatedRoute?.snapshot?.queryParams) {
      updateFromParams(this.activatedRoute.snapshot.queryParamMap);
    }

    this.activatedRoute.queryParamMap.subscribe((paramMap) => updateFromParams(paramMap));
  }

  private clearForm() {
    this.displayForm.reset();
  }

  private subscribeAndLoadSavings() {
    const handleSuccess = (responseDetails: SavingsCompositeAccount) => {
      if (responseDetails.account) {
        this.account = responseDetails.account;
      }

      if (responseDetails.payrollSchedule) {
        this.scheduleList = responseDetails.payrollSchedule;
      }

      //Side affects
      this.checkForPausedSchedule();
      this.checkAndSetUpForCurrentAutosaveSchedule();
      this.checkForLinkedBankOrSpendingAccount();

      this.hasAPIReturned = true;
    };
    const handleFailure = () => {
      this.hasAPIReturned = true;
    };

    this.sub.add(this.savingsCompositeService.getAccountWithPayroll().subscribe(handleSuccess, handleFailure));
  }

  private checkForLinkedBankOrSpendingAccount() {
    if (this.account) {
      this.hasTransferToSource = SavingsAccountService.isLinkedBankPresent(this.account);
    }
  }

  private checkAndSetUpForCurrentAutosaveSchedule() {
    // Must not be one click and have account/prev autosave
    if (this.account && this.account.next_deposit_amount) {
      this.hasCurrentAutosaveSchedule = true;

      if (!this.config.relativeStart) {
        this.selectedAmount?.setValue(this.account.next_deposit_amount);
      }
    }
  }

  private checkForPausedSchedule() {
    if (this.account) {
      this.hasBeenCurrentlyPaused = !!this.account.schedule_paused;
    }
  }

  private handleMoveBackward() {
    this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath || []);
  }

  private handleMoveForward() {
    this.routingState.navigateTo(this.config.pageOnForwardCtaPath || []);
  }

  private handleMoveForwardSecondary() {
    this.routingState.navigateTo(this.config.pageOnForwardSecondaryCtaPath || []);
  }

  private processUpdate() {
    //We need these checks to make sure we correctly direct the client
    if (!this.hasAPIReturned) {
      setTimeout(this.processUpdate, 1000);
      return;
    }

    if (this.config.processUpdater) {
      const results = this.config.processUpdater(this, {
        schedule_type: this.config.cadence || SavingsScheduleType.everyPaycheck,
        next_transfer_date: this.nextTransferDate,
        amount: this.selectedAmount?.value || 0,
        source: null,
      });

      results.subscribe(
        (result: DisplayAutosaveProcessResponse) => {
          if (result.status === 'success') {
            this.amplitudeService.logEvent('savings_setup_completed');
            this.handleMoveForward();
          } else {
            this.showProcessError = true;
            this.processErrorMessage = result.errorMessage || '';
            this.processErrorMessageTitle = result.errorMessageTitle || 'Something went wrong';
            this.processing = false;
          }
        },
        () => {
          this.showProcessError = true;
          this.processErrorMessage = '';
          this.processErrorMessageTitle = '';
          this.processing = false;
        }
      );
    } else {
      this.handleMoveForward();
    }
  }

  doesControlHaveError(key: string): boolean {
    const control = this.displayForm.get(key);

    if (control) {
      return control.touched && control.invalid;
    }

    return false;
  }

  handlePauseStatusChange(statusDetail: { status: string }) {
    this.hasBeenCurrentlyPaused = statusDetail.status === 'pause';
  }

  handleBackClick() {
    //We need to change the flag and the force detect changes to ensure
    //the animation type gets changed
    this.shouldReverseExitAnimation = true;
    this.changeDetectorRef.detectChanges();

    this.handleMoveBackward();
    return false;
  }

  handleCtaClick() {
    if (this.processing) {
      return;
    }

    this.processing = true;
    this.showProcessError = false;
    this.processErrorMessage = '';

    this.processUpdate();
  }

  handleSecondaryCtaClick() {
    // We do this to skip linked account stage
    if (this.hasTransferToSource) {
      this.handleMoveBackward();
    } else if (this.config.pageOnForwardSecondaryCtaPath && this.config.pageOnForwardSecondaryCtaPath.length > 0) {
      this.handleMoveForwardSecondary();
    } else {
      this.handleMoveForward();
    }
  }

  handleLinkClick(path: string[]) {
    this.routingState.navigateTo(path);
  }

  checkForConfirm() {
    if (this.selectedAmount?.value > 199) {
      this.showConfirm = true;
    } else {
      this.handleCtaClick();
    }
  }
}
