const BASE_EVENT_NAME = 'pls_';
const MicroContestAnalyticsEventName = {
  ENTRY_INTRO: `${BASE_EVENT_NAME}promo_autosave`,
  ENTRY_RULES: `${BASE_EVENT_NAME}rules`,
  ENTRY_AUTOSAVE: `${BASE_EVENT_NAME}autosave_amount`,
  ENTRY_AUTOSAVE_CONFIRM: `${BASE_EVENT_NAME}autosave_confirm`,
  ENTRY_AUTOSAVE_CUSTOM: `${BASE_EVENT_NAME}autosave_custom`,
  ENTRY_COMPLETE: `${BASE_EVENT_NAME}autosave_complete`,
  RESULT: `${BASE_EVENT_NAME}reveal`,
  RESULT_WON: `${BASE_EVENT_NAME}reveal_winner`,
  RESULT_LOST: `${BASE_EVENT_NAME}reveal_loser`,
  API_ERROR_MISSING_ID: `${BASE_EVENT_NAME}contest_app_missing_id`,
  API_ERROR_GET: `${BASE_EVENT_NAME}contest_app_get_failed`,
};

export { MicroContestAnalyticsEventName };
