import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MicroCoreUtilRemoteConfigService, RemoteConfigKey } from '@micro-core/utility';
import { DeepPartial } from '@micro-ui/template/page';

import { MicroContestRemoteConfig } from '../../model/contest.interface';

type ReturnResolveType = DeepPartial<MicroContestRemoteConfig>;

@Injectable()
export class MicroContestEntryRemoteConfigResolver implements Resolve<ReturnResolveType> {
  constructor(private microCoreUtilRemoteConfigService: MicroCoreUtilRemoteConfigService) {}

  resolve() {
    return new Observable<ReturnResolveType>((responseSubscriber) => {
      this.microCoreUtilRemoteConfigService
        .configAsObjectObservable<MicroContestRemoteConfig>(RemoteConfigKey.MICRO_CONTEST)
        .pipe(take(1))
        .subscribe((settings: MicroContestRemoteConfig | undefined) => {
          responseSubscriber.next(settings || {});
          responseSubscriber.complete();
        });
    });
  }
}
