<div class="content-layout" [brightsideWebFb]="fbShownEventObject">
  <bw-action-article>
    <bw-back header-actions [brightsideWebFb]="fbDismissedEventObject"></bw-back>
    <div pre-title>
     <bw-steps
        *ngIf="pageStepperActiveIndex >= 0"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ 'SAVINGS_INTRO_TITLE' | translate }}</typography>
    </div>
    <div content-section>
      <p text-large-regular class="secondary-text mb-md">
        {{ 'SAVINGS_INTRO_DESC' | translate }}
      </p>
      <typography text-large-semibold class="mb-smd">{{ 'SAVINGS_INTRO_SUBHEADER' | translate }}</typography>
      <div class="flex-row mb-sm">
        <bw-icon iconName="checkmark" horizontalSpace="mr-sm" color="var(--brand)"></bw-icon>
        <p>{{ 'SAVINGS_INTRO_ITEM_1' | translate }}</p>
      </div>
      <div class="flex-row mb-sm">
        <bw-icon iconName="checkmark" horizontalSpace="mr-sm" color="var(--brand)"></bw-icon>
        <p>{{ 'SAVINGS_INTRO_ITEM_2' | translate }}</p>
      </div>
      <div class="flex-row mb-sm">
        <bw-icon iconName="checkmark" horizontalSpace="mr-sm" color="var(--brand)"></bw-icon>
        <p>{{ 'SAVINGS_INTRO_ITEM_3' | translate }}</p>
      </div>
      <div class="flex-row mb-md">
        <bw-icon iconName="checkmark" horizontalSpace="mr-sm" color="var(--brand)"></bw-icon>
        <p>{{ 'SAVINGS_INTRO_ITEM_4' | translate }}</p>
      </div>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        bw-button
        type="submit"
        [brightsideWebFb]="fbTappedEventObject"
        [processing]="processing"
        (click)="handleCtaClick()">
        {{ 'CREATE_ACCOUNT' | translate }}
      </button>

        <button bw-link-button class="bs-blue brand pr-xl" (click)="faqVisible = true;">
          {{ 'LEARN_MORE' | translate }}
        </button>
    </div>
  </bw-action-article>
</div>
<brightside-web-intro-faq *ngIf="faqVisible" (dismissEvent)="faqVisible=false;"></brightside-web-intro-faq>
