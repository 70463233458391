<ng-container *ngIf="cards && cards.length > 0">
  <div
    class="flex-row space-between"
    [ngClass]="{ 'single-line': isSingleLineStyle }"
  >
    <typography *ngIf="title" [ngClass]="titleSize" [ngStyle]="{ 'margin-top.px': verticalSpaceTopTitle, 'margin-bottom.px': verticalSpaceTitle }">
      {{ title | translate }}
    </typography>

    <a *ngIf="isSingleLineStyle" bw-anchor class="bs-blue brand" (click)="clickedTitleLink()" [ngStyle]="{ 'margin-top.px': verticalSpaceTopTitle, 'margin-bottom.px': verticalSpaceTitle }">View all</a>
  </div>

  <div class="cards flex-row flex-wrap" [ngClass]="{ 'single-line': isSingleLineStyle }">
    <ng-container *ngFor="let card of cards">
      <bw-block
        *ngIf="isSingleLineStyle; else defaultStyle"
        (blockAction)="clickedPassthrough(card)"
        [secondaryStyle]="true"
      >
        <bw-icon [iconName]="card.icon" horizontalSpace="mb-sm" color="var(--brand)" size="40"></bw-icon>
        <typography text-small-semibold>{{ card.title | translate }}</typography>
      </bw-block>

      <ng-template #defaultStyle>
        <brightside-web-desktop-card
          [card]="card"
          (clicked)="clickedPassthrough($event)"
          (closed)="closedPassthrough($event)"
        ></brightside-web-desktop-card>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
