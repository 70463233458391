<bw-modal
  (closeModal)="ctaClick.emit('close')"
  [brightsideWebFb]="{ event_name: 'magic_link_modal_shown' }"
>
  <typography title-medium color="var(--gray-70)">Please open this in our mobile app</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)"
      >Features like the one you’re trying to use are available only in our mobile app. Please open the app to
      continue.</bw-paragraph
    >
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">Don’t have the app? Click below to get started.</bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="handleTextMobileLink()"
        [brightsideWebFb]="{ event_name: 'magic_link_modal_tapped', action: 'click' }"
        [processing]="processing"
      >
        Text me
      </button>
      <a
        bw-anchor
        (click)="ctaClick.emit('cancel')"
        style="margin: 0 16px;"
        [brightsideWebFb]="{ event_name: 'magic_link_modal_dismissed', action: 'click' }"
        [dismissedCta]="true">
        Cancel</a>
    </div>
  </footer>
</bw-modal>
