import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MicroRouteFindDataByKey, RemoteConfigKey, MicroCoreUtilRemoteConfigService } from '@micro-core/utility';

import { MicroContestAppResultsResponse } from '../../service/micro-contest-app.service';

import { MicroContestRouteDataKey } from '../../model/contest.enum';
import { MicroContestRemoteConfig, MicroContestDetails, MicroContestDetail } from '../../model/contest.interface';

type ReturnResolveType = MicroContestDetail | undefined;

@Injectable()
export class MicroContestEntryRemoteContestDetailsResolver implements Resolve<ReturnResolveType> {
  constructor(private microCoreUtilRemoteConfigService: MicroCoreUtilRemoteConfigService) {}

  private getContestDetail(contestId: number, remoteConfig: MicroContestDetails): ReturnResolveType {
    return (remoteConfig || {})[`${contestId}`] || undefined;
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const contestDetails: MicroContestAppResultsResponse | undefined = MicroRouteFindDataByKey<MicroContestAppResultsResponse>(
      routeSnapshot,
      MicroContestRouteDataKey.RESPONSE_CONTEST_RESULTS
    );
    const contestId = contestDetails?.data?.id || 0;
    const remoteConfigData: MicroContestRemoteConfig | undefined = MicroRouteFindDataByKey<MicroContestRemoteConfig>(
      routeSnapshot,
      MicroContestRouteDataKey.REMOTE_CONFIG
    );

    //Look to the remote config passed down from data first.. if missing,
    //get remote config and return it.
    if (remoteConfigData && remoteConfigData?.config?.contestDetails) {
      return this.getContestDetail(contestId, remoteConfigData.config.contestDetails);
    }

    return new Observable<MicroContestDetail | undefined>((responseSubscriber) => {
      this.microCoreUtilRemoteConfigService
        .configAsObjectObservable<MicroContestRemoteConfig>(RemoteConfigKey.MICRO_CONTEST)
        .pipe(take(1))
        .subscribe((settings: MicroContestRemoteConfig | undefined) => {
          responseSubscriber.next(this.getContestDetail(contestId, settings?.config?.contestDetails || {}));
          responseSubscriber.complete();
        });
    });
  }
}
