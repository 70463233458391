import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'brightside-web-autosave-selector',
  templateUrl: './autosave-selector.component.html',
  styleUrls: ['./autosave-selector.component.scss'],
})
export class AutosaveSelectorComponent {
  @Input() formGroup: FormGroup;
}
