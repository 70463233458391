import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileAppModalComponent } from './mobile-app-modal/mobile-app-modal.component';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';

@NgModule({
  imports: [CommonModule, BrightsideUiModule, SharedDesktopModule],
  declarations: [MobileAppModalComponent],
  exports: [MobileAppModalComponent],
})
export class SharedMobileModule {}
