import { Injectable } from '@angular/core';
import { Cache } from 'aws-amplify';
import { ICache } from '@aws-amplify/cache/src/types/Cache'
export { ICache as CacheInterface, CacheItemOptions, CacheConfig, CacheItem} from '@aws-amplify/cache/src/types/Cache'

function getCacheStorage(): ICache {
  return Cache;
}

@Injectable({
  providedIn: 'root'
})
export class CacheStorageRefService {
  get cacheStorage(): ICache {
    return getCacheStorage();
  }
}
