import { Injectable } from '@angular/core';
import {
  ApiResponse,
  LinkedAccountRequestBody,
  LinkedAccountResponse,
  LinkedAccountVerifyRequestBody,
  LinkedAccountVerifyResponse,
  LinkedBank,
  LinkedBankStatus,
  SavingsAccount,
} from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';

import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';

import * as moment from 'moment-business-days';

@Injectable({
  providedIn: 'root',
})
export class LinkedAccountService {
  constructor(private savingsService: SavingsAccountService) {}

  static getAccountIdPendingDeposits(account: SavingsAccount): false | number {
    const verifiableBank = account.banks.find((bank) => LinkedAccountService.isLinkedAccountVerifiable(bank));
    return verifiableBank ? verifiableBank.id : false;
  }

  static isLinkedAccountVerifiable(account: LinkedBank): boolean {
    return Boolean(
      account.status === LinkedBankStatus.LINK_VERIFY_DEPOSIT &&
        moment().isSameOrAfter(moment(account?.micro_meta?.micro_deposit_initiated_ts).local().businessAdd(1).startOf('day'))
    );
  }

  createAccount(requestBody: LinkedAccountRequestBody): Observable<LinkedAccountResponse> {
    this.savingsService.clearSavingsCache();

    return from(
      API.post('api-mobile', '/deposit/link/routing', { headers: { 'Content-Type': 'application/json' }, body: requestBody })
    );
  }

  resendDeposits(bankId: number): Observable<LinkedAccountVerifyResponse> {
    this.savingsService.clearSavingsCache();

    return from(
      API.patch('api-mobile', `/deposit/link/routing/${bankId}`, {
        headers: { 'Content-Type': 'application/json' },
        body: {
          action: 'resendMicroDeposits',
        },
      })
    );
  }

  verifyAccount(request: LinkedAccountVerifyRequestBody): Observable<LinkedAccountVerifyResponse> {
    this.savingsService.clearSavingsCache();

    return from(
      API.patch('api-mobile', `/deposit/link/routing/${request.bankId}`, {
        headers: { 'Content-Type': 'application/json' },
        body: {
          amounts: request.amounts,
          action: 'verifyMicroDeposits',
        },
      })
    );
  }

  unlinkAccount(bankId: number): Observable<ApiResponse> {
    this.savingsService.clearSavingsCache();
    return from(API.del('api-mobile', `/deposit/link/${bankId}`, { headers: { 'Content-Type': 'application/json' } }));
  }
}
